import React, { ReactElement } from 'react'

import { CompanyAccountingIntegrationType } from '../../api/company-accounting-integration-setup'
import { AccountPlan, AccountPlanUpdateConfiguration } from '../../model/accountingIntegration'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Subtitle from '../elements/Subtitle'
import AccountMappingForm, { NO_ACCOUNT } from './AccountMappingForm'

type Props = {
  accountPlan: AccountPlan
  integrationType: CompanyAccountingIntegrationType
  displayName: string
}

export type AccountMappingFields = {
  readonly step: 'MapAccounts'
  accountMapping: AccountPlanUpdateConfiguration[]
}

function AccountMappingStep(
  props: Props & FormComponentProps<AccountMappingFields, AccountMappingFields>
): ReactElement | null {
  return (
    <div>
      <Subtitle>{t('accounting_integration.mapping.title')}</Subtitle>
      <p>{t('accounting_integration.mapping.intro')}</p>
      {props.getFormError()}
      <AccountMappingForm<AccountMappingFields>
        decorateAnyField={props.decorateAnyField}
        getFieldValue={props.getFieldValue}
        setAnyFieldValue={props.setAnyFieldValue}
        getAnyFieldValue={props.getAnyFieldValue}
        getAnyFieldError={props.getAnyFieldError}
        accountPlan={props.accountPlan}
        integrationType={props.integrationType}
      />
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="primary">
            {props.integrationType === 'None'
              ? t('accounting_integration.mapping.next.no_integration')
              : t('accounting_integration.mapping.next', { integration: props.displayName })}
          </Button>
          <Button size="extra-extra-large" onClick={props.goBack}>
            {t('accounting_integration.mapping.back')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, AccountMappingFields, AccountMappingFields>({
  mapPropsToFields: (props) => {
    let mapping: AccountPlanUpdateConfiguration[] = []
    if (props.accountPlan) {
      const accountMapping = props.accountPlan.mapping.filter((v) => v.costCenterID === '')[0]
      mapping = accountMapping.configurations
    }
    const accounts = props.accountPlan ? props.accountPlan.accounts : []
    const accountMapping = mapping.map((mapping) => {
      let accountNumber
      if (accounts) {
        accounts.forEach((account) => {
          if (account.externalID === mapping.externalID) {
            accountNumber = account.accountNumber
          }
        })
      }
      return {
        accountNumber,
        accountType: mapping.accountType,
        externalID: mapping.externalID,
        text: mapping.text,
      }
    })

    return {
      step: 'MapAccounts',
      accountMapping,
    }
  },
  onSubmit: (values) => {
    values.accountMapping = values.accountMapping.map((mapping) => {
      if (mapping.externalID === NO_ACCOUNT) {
        mapping.externalID = undefined
        mapping.accountNumber = undefined
      }
      return mapping
    })
    return values
  },
})(AccountMappingStep)
