import Company, { CompanyCreationFields, CompanyMutableFields } from '../model/company'
import CompanySetting from '../model/companySetting'
import NetsMessage from '../model/netsMessage'
import SupplementType from '../model/supplementType'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function lookupCVR(term: string): Promise<RequestResponse<Company[]>> {
  return secureRequest('GET', url('/v2/cvrSearch', { term }))
}

export function fetchCompanies(): Promise<RequestResponse<Company[]>> {
  return secureRequest('GET', url('/v2/companies'))
}

export function fetchCompany(companyID: string): Promise<RequestResponse<Company>> {
  return secureRequest('GET', url('/v2/companies/' + companyID))
}

export function postCompany(company: CompanyCreationFields): Promise<RequestResponse<Company>> {
  return secureRequest('POST', url('/v2/companies'), {
    body: {
      name: company.name,
      nationalID: company.nationalID,
      address: company.address,
      postalCode: company.postalCode,
      city: company.city,
      rulePayRollRunApproval: company.rulePayRollRunApproval,
      productionUnits: company.productionUnits,
      dkSpecific: company.dkSpecific,
    },
  })
}

export function putCompany(company: CompanyMutableFields): Promise<RequestResponse<Company>> {
  return secureRequest('PUT', url('/v2/companies/' + company.id), {
    body: {
      name: company.name,
      nationalID: company.nationalID,
      address: company.address,
      postalCode: company.postalCode,
      city: company.city,
      rulePayRollRunApproval: company.rulePayRollRunApproval,
      productionUnits: company.productionUnits,
      dkSpecific: company.dkSpecific,
      defaultHourlyMonthlyCycleID: company.defaultHourlyMonthlyCycleID,
      accountingManualPaymentAlwaysAsset: company.accountingManualPaymentAlwaysAsset,
      accountingVacationLiability: company.accountingVacationLiability,
      offsetDispositionBiWeekly: company.offsetDispositionBiWeekly,
      offsetDispositionWeekly: company.offsetDispositionWeekly,
      offsetDispositionMonthly: company.offsetDispositionMonthly,
      defaultBiweeklyCycleID: company.defaultBiweeklyCycleID,
      limitICalendarToDepartments: company.limitICalendarToDepartments,
      paySlipQuestionsVisible: company.paySlipQuestionsVisible,
      paySlipQuestionsLink: company.paySlipQuestionsLink,
      vacationExcessLimit: company.vacationExcessLimit,
      automaticVacationTransferLimit: company.automaticVacationTransferLimit,
      automaticOptionalVacationTransferLimit: company.automaticOptionalVacationTransferLimit,
      enableNemKontoTransfers: company.enableNemKontoTransfers,
      enableReimbursementVouchers: company.enableReimbursementVouchers,
      enableImmediatePay: company.enableImmediatePay,
      enableSwipe: company.enableSwipe,
      enableEarlyPayments: company.enableEarlyPayments,
      swipeEmployeeTypes: company.swipeEmployeeTypes,
      swipeNotificationAt: company.swipeNotificationAt,
      enableAlwaysCurrentPayRollRun: company.enableAlwaysCurrentPayRollRun,
      periodicLimitForImmediatePayOut: company.periodicLimitForImmediatePayOut,
      reimbursementVoucherAutoApprovalLimit: company.reimbursementVoucherAutoApprovalLimit,
      familyLeaveFund: company.familyLeaveFund,
      numberOfPayRollApprovers: company.numberOfPayRollApprovers,
      numberOfPayRollReviewers: company.numberOfPayRollReviewers,
      allowEmptyTaxCardMail: company.allowEmptyTaxCardMail,
      DAUnionCode: company.DAUnionCode,
      enableYearlySalaryStatistics: company.enableYearlySalaryStatistics,
      enableQuarterlySalaryStatistics: company.enableQuarterlySalaryStatistics,
      enableAbsenceStatistics: company.enableAbsenceStatistics,
      invoiceEmail: company.invoiceEmail,
      departmentApproverNotificationDaysBefore1: company.departmentApproverNotificationDaysBefore1,
      departmentApproverNotificationDaysBefore2: company.departmentApproverNotificationDaysBefore2,
      workHourLunchLimit: company.workHourLunchLimit,
      swipeInstantCostPayer: company.swipeInstantCostPayer,
      swipeFeeCost: company.swipeFeeCost,
      swipeFeeCostType: company.swipeFeeCostType,
      swipeFeeCostCap: company.swipeFeeCostCap,
      swipeFeeCostFrequency: company.swipeFeeCostFrequency,
      payrollApprovalCode: company.payrollApprovalCode,
      allowCustomPDFPasswords: company.allowCustomPDFPasswords,
    },
  })
}

export function fetchSupplementTypes(companyID: string): Promise<RequestResponse<SupplementType[]>> {
  return secureRequest('GET', url('/v2/supplementTypes', { companyID }))
}

export function fetchNetsMessages(
  companyID: string,
  limit: number,
  offset: number
): Promise<RequestResponse<NetsMessage[]>> {
  return secureRequest('GET', url('/v2/netsMessages', { companyID, limit, offset }))
}

export function postCompanyNemKonto(companyID: string, enable: boolean): Promise<RequestResponse> {
  return secureRequest('POST', url('/v2/companies/' + companyID + '/nemKonto'), { body: { enable } })
}

export function requestCompanyDeletion(
  companyID: string,
  deleteReason: string,
  comment?: string
): Promise<RequestResponse> {
  return secureRequest('POST', url('/v2/companies/' + companyID + '/deleteRequest'), {
    body: { deleteReason, comment },
  })
}

export function requestCompanyImport(
  companyID: string,
  payRollSystem: string,
  dateRequest: string,
  username?: string,
  password?: string
): Promise<RequestResponse> {
  return secureRequest('POST', url('/v2/companies/' + companyID + '/importRequest'), {
    body: { payRollSystem, dateRequest, username, password },
  })
}

export function patchCompanySettings(companyID: string, settings: CompanySetting[]): Promise<RequestResponse<Company>> {
  return secureRequest('PATCH', url('/v2/companies/' + companyID + '/settings'), {
    body: { flags: settings },
  })
}

export function deleteCompanySettings(
  companyID: string,
  settings: CompanySetting[]
): Promise<RequestResponse<Company>> {
  return secureRequest('DELETE', url('/v2/companies/' + companyID + '/settings'), {
    body: { flags: settings },
  })
}

export function postApiClientConnect(
  companyID: string,
  apiClientID: string,
  ref: string,
  needFeature?: string
): Promise<RequestResponse> {
  return secureRequest('POST', url('/v2/apiClientConnect'), {
    body: { companyID, apiClientID, ref, needFeature },
  })
}

export function postApiClientUserConnect(apiClientID: string, ref: string): Promise<RequestResponse> {
  return secureRequest('POST', url('/v2/apiClientUserConnect'), {
    body: { apiClientID, ref },
  })
}

export function deleteCompanyDeletion(companyID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/companies/' + companyID + '/deletion'))
}
