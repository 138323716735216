import React, { ReactElement, useState } from 'react'
import { useEffectOnce } from 'react-use'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import { getSlackIntegration, putSlackIntegration, SlackConfiguration } from '../../api/slack-integrations'
import Company from '../../model/company'
import { AlertReducer } from '../../reducers/alerts'
import { formatError, isRequestError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import Alerts from '../widgets/Alerts'
import FeatureLock from '../widgets/FeatureLock'
import LoadingOverlay from '../widgets/LoadingOverlay'
import SlackIntegrationForm, { SlackIntegrationResult } from './SlackIntegrationForm'

type Props = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  company: Company

  alerts: AlertReducer
}

export default function SlackIntegration(props: Props): ReactElement | null {
  const [configuration, setConfiguration] = useState<Partial<SlackConfiguration>>({})
  const [connectURL, setConnectURL] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const { company } = props

  useEffectOnce(() => {
    setLoading(true)
    getSlackIntegration(company.id)
      .then((res) => {
        if (!res) {
          return
        }
        setConfiguration(res.data)
        setConnectURL(res.data.slackConnectURL)
      })
      .catch(() => {
        return // do nothing
      })
      .finally(() => setLoading(false))
  })

  const handleSubmit = (values: SlackIntegrationResult) => {
    putSlackIntegration(company.id, values)
      .then((res) => {
        if (!res) {
          setError(new Error(t('slack_integration.error.unknown')))
        }
        setConfiguration(res.data)
        props.addAlert('success', t('slack_integration.alert.saved'), { timeout: 5 })
      })
      .catch((e) => {
        if (isRequestError(e)) {
          setError(e)
        }
      })
  }

  const deleteConfiguration = () => {
    putSlackIntegration(company.id, null)
      .then(() => {
        setConfiguration({})
        props.addAlert('success', t('slack_integration.alert.saved'), { timeout: 5 })
      })
      .catch((e) => {
        if (isRequestError(e)) {
          setError(e)
        }
      })
  }

  if (loading) {
    return <LoadingOverlay />
  }

  return (
    <Card>
      {error && <Alert type="error" message={formatError(error)} />}
      <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />
      {!configuration.slackToken && connectURL && (
        <TitleMenu>
          <FeatureLock featureType="Slack Integration" description={t('slack_integration.new_integration.lock')}>
            <Button
              onClick={() => {
                document.location = connectURL
              }}
            >
              {t('slack_integration.new_integration')}
            </Button>
          </FeatureLock>
        </TitleMenu>
      )}
      <Title>{t('slack_integration.title')}</Title>
      {!!configuration.slackToken && (
        <>
          <SlackIntegrationForm configuration={configuration} onSubmit={handleSubmit} />
          <Button danger onClick={deleteConfiguration}>
            {t('slack_integration.button.delete')}
          </Button>
        </>
      )}
    </Card>
  )
}
