import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Department from '../../model/department'
import Employee from '../../model/employee'
import { DateFormat } from '../../model/types'
import { formatAPIDate, getDate } from '../../utils/date-utils'
import { validateEmail } from '../../utils/email-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import DatePicker from '../elements/date-picker'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'
import Select from '../elements/select'
import Subtitle from '../elements/Subtitle'

type Props = {
  employees: List<Employee>
  departments: List<Department>

  employeeNumber?: string | number
  email?: string
  employmentStartDate: DateFormat
  departmentID?: string
}

type Fields = {
  employeeNumber?: string
  email?: string
  employmentStartDate: Date
  departmentID?: string
}

export type InviteResult = {
  readonly step: 'Invite'
  employeeNumber: string
  email: string
  employmentStartDate: DateFormat
  departmentID?: string
}

function InviteForm(props: Props & FormComponentProps<Fields, InviteResult>): ReactElement {
  const { decorateField } = props

  return (
    <div>
      <Subtitle>{t('employee.add.invite.title')}</Subtitle>
      <p>&nbsp;</p>
      {props.getFormError()}
      <Row>
        <Col span={12}>
          {decorateField('employeeNumber', {
            placeholder: t('employee.add.invite.employee_number'),
            validate: (val) => {
              if (!val) {
                return t('employee.add.invite.employee_number.required')
              }
              if (!val.match(/^[a-zæøå0-9 -]{1,15}$/i)) {
                return t('employee.add.invite.employee_number.invalid')
              }
              if (
                props.employees.some(
                  (employee) => !!employee.activeEmployment && employee.activeEmployment.employeeNumber === val
                )
              ) {
                return t('employee.add.invite.employee_number.in_use')
              }
              return null
            },
          })(<Input tabIndex={1} />)}
        </Col>
        <Col span={12}>
          {decorateField('email', {
            placeholder: t('employee.add.invite.email'),
            validate: (val) => {
              if (!val) {
                return t('employee.add.invite.email.required')
              }
              if (!validateEmail(val)) {
                return t('employee.add.invite.email.invalid')
              }
              return null
            },
          })(<Input tabIndex={7} />)}
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          {decorateField('employmentStartDate', {
            placeholder: t('employee.add.invite.employment_start_date'),
            validate: (val) => {
              if (!val) {
                return t('employee.add.invite.employment_start_date.required')
              }
              return null
            },
          })(<DatePicker allowClear={false} tabIndex={9} style={{ width: '100%' }} />)}
        </Col>
        <Col span={12}>
          {decorateField('departmentID', {
            title: t('employee.add.invite.department_id'),
            placeholder: t('employee.add.invite.department_id.placeholder'),
          })(
            <Select mode="combobox" dropdownMatchSelectWidth={false} optionLabelProp="title">
              {props.departments
                .filter((department) => department.active)
                .map((department) => {
                  return (
                    <Select.Option key={department.id} value={department.id} title={department.name}>
                      {department.name}
                    </Select.Option>
                  )
                })}
            </Select>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button
            htmlType="submit"
            size="extra-extra-large"
            type="primary"
            className="gtm-invite-employee"
            tabIndex={11}
          >
            {t('employee.add.invite.submit')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, Fields, InviteResult>({
  mapPropsToFields: (props) => ({
    employeeNumber: props.employeeNumber?.toString(),
    email: props.email,
    employmentStartDate: getDate(props.employmentStartDate),
    departmentID: props.departmentID,
  }),
  onSubmit: (values) => ({
    ...values,
    step: 'Invite',
    employeeNumber: values.employeeNumber!,
    email: values.email!,
    employmentStartDate: formatAPIDate(values.employmentStartDate),
  }),
})(InviteForm)
