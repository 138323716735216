export function hasDocumentSigning(): boolean {
  if (process.env.REACT_APP_FEATURE_DOCUMENT_SIGNING) {
    return true
  }
  return false
}

export function hasSwipeFee(): boolean {
  if (process.env.REACT_APP_FEATURE_SWIPE_FEE) {
    return true
  }
  return false
}

export function hasLeftMenuFeature(): boolean {
  if (process.env.REACT_APP_FEATURE_LEFT_MENU) {
    return true
  }
  return false
}
