import React, { ReactElement, useState } from 'react'
import { useEffectOnce } from 'react-use'

import { CarAllowanceRate, fetchCarAllowanceRates } from '../../../api/car-allowances'
import Contract from '../../../model/contract'
import { ContractReducer } from '../../../reducers/contracts'
import { findRelevantCarAllowance } from '../../../utils/car-allowance-rates-utils'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { forceParseInputNumber, formatCurrency, formatInputNumber, parseInputNumber } from '../../../utils/number-utils'
import { t } from '../../../utils/translation-utils'
import Button from '../../elements/button'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import Input from '../../elements/input'
import LoadingOverlay from '../../widgets/LoadingOverlay'

type Props = {
  contract: Contract
  contracts: ContractReducer
}

type Fields = {
  carAllowanceRate?: string
}

export type RateResult = {
  carAllowanceRate?: number
}

function RateEditForm(props: Props & FormComponentProps<Fields, RateResult>): ReactElement | null {
  const [rates, setRates] = useState<CarAllowanceRate[]>()
  const [loading, setLoading] = useState(true)
  const { decorateField } = props

  useEffectOnce(() => {
    fetchCarAllowanceRates()
      .then((res) => {
        if (!res) {
          return
        }
        setRates(res.data)
      })
      .finally(() => setLoading(false))
  })

  if (loading) {
    return <LoadingOverlay />
  }
  if (!rates) {
    return <div>{t('errors.unable_to_load_car_allowance_rates')}</div>
  }
  // it's OK if validTo is undefined, then we will just use today's date
  const carAllowanceRateLimit = findRelevantCarAllowance(rates, props.contract.validTo)

  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={24}>
          {decorateField('carAllowanceRate', {
            placeholder: t('car_allowance_rate.edit.form.car_allowance_rate'),
            validate: (val) => {
              if (!val) {
                return null
              }
              if (parseInputNumber(val) <= 0 || !val.toString().match(/^[0-9,.]+$/)) {
                return t('car_allowance_rate.edit.form.car_allowance_rate.invalid')
              }
              if (parseInputNumber(val) > carAllowanceRateLimit) {
                return t('car_allowance_rate.edit.form.car_allowance_rate.above_limit', {
                  limit: formatCurrency(carAllowanceRateLimit, 2),
                })
              }
              return null
            },
            suffix: 'kr./km',
          })(<Input />)}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="primary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      {props.contracts.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, RateResult>({
  mapPropsToFields: (props) => ({
    carAllowanceRate: props.contract.carAllowanceRate ? formatInputNumber(props.contract.carAllowanceRate) : undefined,
  }),
  onSubmit: (values) => ({
    carAllowanceRate: values.carAllowanceRate ? forceParseInputNumber(values.carAllowanceRate) : undefined,
  }),
})(RateEditForm)
