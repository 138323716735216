import { List } from 'immutable'
import React, { ReactElement, ReactNode, useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import {
  ReimbursementVoucherCreate,
  ReimbursementVoucherFieldsUpdate,
  ReimbursementVoucherUpdate,
} from '../../api/reimbursement-vouchers'
import paths from '../../constants/paths'
import Company from '../../model/company'
import CompanyUser from '../../model/companyUser'
import CostCenter from '../../model/costCenter'
import Department from '../../model/department'
import Employee from '../../model/employee'
import ReimbursementVoucher, {
  ReimbursementVoucherApprovalState,
  ReimbursementVoucherBookingState,
} from '../../model/reimbursementVoucher'
import { AlertReducer } from '../../reducers/alerts'
import { ExpenseCategoryReducer } from '../../reducers/expenseCategories'
import { ReimbursementVoucherReducer } from '../../reducers/reimbursementVouchers'
import { UserReducer } from '../../reducers/user'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { formatDate, getDate } from '../../utils/date-utils'
import { convertStoredErrorToError, formatError } from '../../utils/error-utils'
import { formatCurrency } from '../../utils/number-utils'
import { hasDepartmentPermission, isDepartmentRestricted } from '../../utils/permissions-utils'
import { secureUrl } from '../../utils/request-utils'
import { getPage, setPage } from '../../utils/route-utils'
import { t } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Button from '../elements/button'
import ContextMenu from '../elements/ContextMenu'
import EmployeeFilter, { FilterContainer, filterEmployee } from '../elements/EmployeeFilter'
import { Col, Row } from '../elements/grid'
import Icon from '../elements/icon'
import Modal from '../elements/modal'
import Switch from '../elements/switch'
import Table from '../elements/table'
import { TableChange } from '../elements/table/Table'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import Tooltip from '../elements/tooltip'
import Alerts from '../widgets/Alerts'
import DumbLink from '../widgets/DumbLink'
import FeatureLock from '../widgets/FeatureLock'
import ReimbursementVoucherEdit from './ReimbursementVoucherEdit'
import ReimbursementVoucherNew from './ReimbursementVoucherNew'
import ReimbursementVoucherReport from './ReimbursementVoucherReport'

import './ReimbursementVouchers.css'

type Props = {
  subsection?: string
  alerts: AlertReducer
  companyUser?: CompanyUser
  companyUsers: List<CompanyUser>
  departments: List<Department>
  user: UserReducer
  company: Company
  employees: List<Employee>
  expenseCategories: ExpenseCategoryReducer
  reimbursementVouchers: ReimbursementVoucherReducer
  costCenters: List<CostCenter>

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  updateCompany: (_c: Company) => void
  addReimbursementVoucher: (o: ReimbursementVoucherCreate) => Promise<ReimbursementVoucher | void>
  updateReimbursementVoucher: (o: ReimbursementVoucherUpdate) => Promise<ReimbursementVoucher | void>
  updateReimbursementVoucherFields: (o: ReimbursementVoucherFieldsUpdate) => Promise<ReimbursementVoucher | void>
  approveReimbursementVouchers: (ids: string[]) => Promise<ReimbursementVoucher[] | void>
  unapproveReimbursementVouchers: (ids: string[]) => Promise<ReimbursementVoucher[] | void>
  rebookReimbursementVouchers: (ids: string[]) => Promise<ReimbursementVoucher[] | void>
  draftReimbursementVouchers: (ids: string[]) => Promise<ReimbursementVoucher[] | void>
  deleteReimbursementVoucher: (id: string) => void
}

export default function ReimbursementVouchers(props: Props): ReactElement | null {
  const [approving, setApproving] = useState<string[]>([])
  const [unapproving, setUnapproving] = useState<string[]>([])
  const [rebooking, setRebooking] = useState<string[]>([])
  const [drafting, setDrafting] = useState<string[]>([])
  const [deleting, setDeleting] = useState<string[]>([])
  const [modalKey, setModalKey] = useState<number>(1)
  const [editing, setEditing] = useState<boolean | string>(false)
  const [error, setError] = useState<Error | null>(null)
  const [creating, setCreating] = useState<boolean>(false)
  const [showReport, setShowReport] = useState(false)
  const [filter, setFilter] = useState<FilterContainer>({ searchQuery: '' })
  type SortOn = 'employee' | 'amount' | 'date' | 'state' | 'expenseCategory'
  type Sort = {
    sortOn?: SortOn
    sortOrder?: 'ascend' | 'descend'
  }
  const [sort, setSort] = useState<Sort>({})

  const setEditVisibility = useCallback(
    (id: boolean | string) => {
      setModalKey((prev) => prev + 1)
      setEditing(id)
    },
    [setModalKey, setEditing]
  )

  const setNewVisibility = (visible: boolean) => {
    setCreating(visible)
  }

  const setReportVisibility = (visible: boolean) => {
    setShowReport(visible)
  }

  const createVisibility = (reimbursementVoucherID: string) => {
    setEditVisibility(reimbursementVoucherID)
  }

  const reimbursementVoucherSaving = props.reimbursementVouchers.saving
  const wasSaving = usePrevious(reimbursementVoucherSaving)
  const reimbursementVouchersError = props.reimbursementVouchers.error

  useEffect(() => {
    if (wasSaving && !reimbursementVoucherSaving) {
      if (!reimbursementVouchersError) {
        setEditVisibility(false)
      }
    }

    regularComponentDidUpdate(reimbursementVouchersError, error, setError)
  }, [wasSaving, reimbursementVoucherSaving, reimbursementVouchersError, setEditVisibility, error])

  const getReimbursementVoucherIdsForApproval = (): string[] => {
    return props.reimbursementVouchers.reimbursementVouchers
      .filter(
        (reimbursementVoucher) =>
          reimbursementVoucher.approvalState === 'Ready' && !!reimbursementVoucher.expenseCategory
      )
      .map((reimbursementVoucher) => reimbursementVoucher.id)
      .toArray()
  }

  const approveAll = (e: React.MouseEvent) => {
    e.preventDefault()
    const ids = getReimbursementVoucherIdsForApproval()
    setApproving((prev) => [...prev, ...ids])
    props.approveReimbursementVouchers(ids).then(() => {
      const list = approving.filter((v) => ids.indexOf(v) === -1)
      setApproving(list)
    })
  }

  const approve = (reimbursementVoucher: ReimbursementVoucher) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      setApproving((prev) => [...prev, reimbursementVoucher.id])
      props.approveReimbursementVouchers([reimbursementVoucher.id]).finally(() => {
        const list = approving.filter((v) => v !== reimbursementVoucher.id)
        setApproving(list)
      })
    }
  }

  const unapprove = (reimbursementVoucher: ReimbursementVoucher) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      setUnapproving((prev) => [...prev, reimbursementVoucher.id])
      props.unapproveReimbursementVouchers([reimbursementVoucher.id]).finally(() => {
        const list = unapproving.filter((v) => v !== reimbursementVoucher.id)
        setUnapproving(list)
      })
    }
  }

  const rebook = (reimbursementVoucher: ReimbursementVoucher) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      setRebooking((prev) => [...prev, reimbursementVoucher.id])
      props.rebookReimbursementVouchers([reimbursementVoucher.id]).finally(() => {
        const list = rebooking.filter((v) => v !== reimbursementVoucher.id)
        setRebooking(list)
      })
    }
  }

  const draft = (reimbursementVoucher: ReimbursementVoucher) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      setDrafting((prev) => [...prev, reimbursementVoucher.id])
      props.draftReimbursementVouchers([reimbursementVoucher.id]).finally(() => {
        const list = drafting.filter((v) => v !== reimbursementVoucher.id)
        setDrafting(list)
      })
    }
  }

  const remove = (reimbursementVoucher: ReimbursementVoucher) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      if (window.confirm(t('common.are_you_sure'))) {
        setDeleting((prev) => [...prev, reimbursementVoucher.id])
        props.deleteReimbursementVoucher(reimbursementVoucher.id)
      }
    }
  }

  const showHistory = props.subsection === 'history'

  type ReimbursementVoucherRow = {
    id: string
    key: string
    statusText: string
    statusClass: string
    approvalState: ReimbursementVoucherApprovalState
    bookingState: ReimbursementVoucherBookingState
    error?: ReactNode
    expenseCategory?: string
    amount: string
    amountNo: number
    disburseable: boolean
    immediatePay: boolean
    employeeName: string
    department?: string // employee department
    costCenter?: string // voucher cost center
    note: string
    date: string
    receiptDate: string
    canApproveObjects: boolean
    canEditObjects: boolean
    canDraft: boolean
    original: ReimbursementVoucher
  }

  const columns = [
    {
      title: '',
      dataIndex: '',
      key: 'xThumbnail',
      className: 'ant-table-col-thumbnail',
      render: (reimbursementVoucher: ReimbursementVoucherRow) => {
        return (
          <div className="voucher-image" onClick={() => setEditVisibility(reimbursementVoucher.id)}>
            <img src={secureUrl(`v2/reimbursementVouchers/${reimbursementVoucher.id}/thumbnail`)} alt="" />
          </div>
        )
      },
    },
    {
      title: t('reimbursement_vouchers.table.header.state'),
      dataIndex: '',
      key: 'xStatusText',
      width: '20%',
      sorter: 'state',
      render: (reimbursementVoucher: ReimbursementVoucherRow) => {
        return (
          <span>
            {reimbursementVoucher.bookingState === 'Failed' && reimbursementVoucher.error ? (
              <Tooltip title={reimbursementVoucher.error}>{reimbursementVoucher.statusText}</Tooltip>
            ) : (
              reimbursementVoucher.statusText
            )}
            {reimbursementVoucher.canApproveObjects && reimbursementVoucher.approvalState !== 'Approved' && (
              <DumbLink onClick={approve(reimbursementVoucher.original)}>
                {t('reimbursement_vouchers.table.book.approve')}
              </DumbLink>
            )}
            {(reimbursementVoucher.bookingState === 'Booked' ||
              reimbursementVoucher.bookingState === 'Daybooked' ||
              reimbursementVoucher.bookingState === 'Failed') && (
              <DumbLink onClick={rebook(reimbursementVoucher.original)}>
                {t('reimbursement_vouchers.table.book.book_again')}
              </DumbLink>
            )}
          </span>
        )
      },
    },
    {
      title: t('reimbursement_vouchers.table.header.date'),
      dataIndex: '',
      key: 'xDate',
      width: '15%',
      sorter: 'date',
      render: (reimbursementVoucher: ReimbursementVoucherRow) => {
        return (
          <span onClick={() => setEditVisibility(reimbursementVoucher.id)} style={{ cursor: 'pointer' }}>
            {reimbursementVoucher.receiptDate}
            {reimbursementVoucher.receiptDate !== reimbursementVoucher.date && (
              <small>
                {t('reimbursement_vouchers.table.date.received')}
                <br />
                {reimbursementVoucher.date}
              </small>
            )}
          </span>
        )
      },
    },
    {
      title: t('reimbursement_vouchers.table.header.employee'),
      dataIndex: '',
      key: 'xEmployeeName',
      sorter: 'employee',
      render: (reimbursementVoucher: ReimbursementVoucherRow) => {
        return (
          <div onClick={() => setEditVisibility(reimbursementVoucher.id)} style={{ cursor: 'pointer' }}>
            {reimbursementVoucher.employeeName}
            {reimbursementVoucher.department && (
              <span>
                <br />
                {reimbursementVoucher.department}
              </span>
            )}
          </div>
        )
      },
    },
    {
      title: t('reimbursement_vouchers.table.header.expense_category'),
      dataIndex: '',
      key: 'xExpenseCategory',
      width: '14%',
      sorter: 'expenseCategory',
      render: (reimbursementVoucher: ReimbursementVoucherRow) => {
        return (
          <span onClick={() => setEditVisibility(reimbursementVoucher.id)} style={{ cursor: 'pointer' }}>
            {reimbursementVoucher.expenseCategory ? (
              reimbursementVoucher.expenseCategory
            ) : (
              <em>{t('reimbursement_vouchers.table.category.missing')}</em>
            )}
          </span>
        )
      },
    },
    {
      title: t('reimbursement_vouchers.table.header.amount'),
      dataIndex: '',
      key: 'xAmount',
      width: '20%',
      sorter: 'amount',
      render: (reimbursementVoucher: ReimbursementVoucherRow) => {
        return (
          <div onClick={() => setEditVisibility(reimbursementVoucher.id)} style={{ cursor: 'pointer' }}>
            {reimbursementVoucher.amount}
            <span className="normal">
              {' '}
              (
              {reimbursementVoucher.disburseable
                ? t('reimbursement_vouchers.table.amount.disburseable')
                : t('reimbursement_vouchers.table.amount.not_disburseable')}
              )
            </span>
            {reimbursementVoucher.immediatePay && (
              <>
                <br />
                {t('reimbursement_vouchers.table.amount.immediate_pay')}
              </>
            )}
            {reimbursementVoucher.costCenter && (
              <>
                <br />
                <span className="normal">{t('reimbursement_vouchers.table.amount.cost_center_note')}</span>{' '}
                {reimbursementVoucher.costCenter}
              </>
            )}
          </div>
        )
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'xActions',
      className: 'ant-table-col-context',
      render: (reimbursementVoucher: ReimbursementVoucherRow) => {
        if (!reimbursementVoucher.canEditObjects) {
          return null
        }
        if (reimbursementVoucher.bookingState === 'Booked' || reimbursementVoucher.bookingState === 'Daybooked') {
          return null
        }
        if (approving.indexOf(reimbursementVoucher.id) !== -1) {
          return null
        }
        if (unapproving.indexOf(reimbursementVoucher.id) !== -1) {
          return null
        }
        if (rebooking.indexOf(reimbursementVoucher.id) !== -1) {
          return null
        }
        if (drafting.indexOf(reimbursementVoucher.id) !== -1) {
          return null
        }
        if (deleting.indexOf(reimbursementVoucher.id) !== -1) {
          return null
        }
        return (
          <ContextMenu placeholder={<Icon type="paperWithPencil" />}>
            {reimbursementVoucher.approvalState === 'Approved' && reimbursementVoucher.bookingState === 'Pending' && (
              <DumbLink onClick={unapprove(reimbursementVoucher.original)}>
                <Icon type="document" /> {t('reimbursement_vouchers.table.actions.remove_approval')}
              </DumbLink>
            )}
            {reimbursementVoucher.approvalState === 'Ready' &&
              reimbursementVoucher.bookingState !== 'Failed' &&
              !!reimbursementVoucher.expenseCategory && (
                <DumbLink onClick={approve(reimbursementVoucher.original)}>
                  <Icon type="document" /> {t('reimbursement_vouchers.table.actions.approve')}
                </DumbLink>
              )}
            {reimbursementVoucher.bookingState === 'Failed' && (
              <DumbLink onClick={rebook(reimbursementVoucher.original)}>
                <Icon type="document" /> {t('reimbursement_vouchers.table.actions.book_again')}
              </DumbLink>
            )}
            {reimbursementVoucher.canDraft &&
              reimbursementVoucher.approvalState === 'Ready' &&
              reimbursementVoucher.bookingState !== 'Failed' && (
                <DumbLink onClick={draft(reimbursementVoucher.original)}>
                  <Icon type="paperWithPencil" /> {t('reimbursement_vouchers.table.actions.send_back')}
                </DumbLink>
              )}
            <DumbLink onClick={() => setEditVisibility(reimbursementVoucher.id)}>
              <Icon type="paperWithPencil" /> {t('reimbursement_vouchers.table.actions.edit')}
            </DumbLink>
            {reimbursementVoucher.approvalState !== 'Approved' && reimbursementVoucher.bookingState !== 'Failed' && (
              <DumbLink onClick={remove(reimbursementVoucher.original)}>
                <Icon type="xSign" /> {t('reimbursement_vouchers.table.actions.delete')}
              </DumbLink>
            )}
          </ContextMenu>
        )
      },
    },
  ]

  const filteredEmployeeIDs = props.employees
    .filter((employee) => filterEmployee(employee, filter))
    .map((employee) => employee.id)

  const getReimbursementVouchers = (
    filterFunc: (voucher: ReimbursementVoucher) => boolean
  ): ReimbursementVoucherRow[] => {
    return props.reimbursementVouchers.reimbursementVouchers
      .filter(
        (reimbursementVoucher) =>
          reimbursementVoucher.approvalState !== 'Draft' &&
          filteredEmployeeIDs.indexOf(reimbursementVoucher.employeeID) !== -1 &&
          filterFunc(reimbursementVoucher)
      )
      .toArray()
      .map((reimbursementVoucher: ReimbursementVoucher): ReimbursementVoucherRow => {
        let statusText = t('reimbursement_vouchers.state.default')
        let className = 'reimbursement-vouchers-table-row-warning'
        switch (reimbursementVoucher.approvalState) {
          case 'Ready':
            statusText = t('reimbursement_vouchers.state.ready')
            break
          case 'Approved':
            switch (reimbursementVoucher.bookingState) {
              case 'Ready':
                statusText = t('reimbursement_vouchers.state.booking.ready')
                break
              case 'Booked':
                statusText = t('reimbursement_vouchers.state.booking.booked')
                className = 'reimbursement-vouchers-table-row-success'
                break
              case 'Daybooked':
                statusText = t('reimbursement_vouchers.state.booking.daybooked')
                className = 'reimbursement-vouchers-table-row-success'
                break
              case 'Failed':
                statusText = t('reimbursement_vouchers.state.booking.failed')
                className = 'reimbursement-vouchers-table-row-error'
                break
              default:
                statusText = t('reimbursement_vouchers.state.approved')
                break
            }
            break
          default:
            break
        }
        const employee = props.employees.find((employee) => employee.id === reimbursementVoucher.employeeID)
        const employeeName = employee?.name || employee?.email || t('common.unknown')
        const department = props.departments.find((department) => !!employee && department.id === employee.departmentID)
        const costCenter = props.costCenters.find((costCenter) => costCenter.id === reimbursementVoucher.costCenterID)
        const error = convertStoredErrorToError(reimbursementVoucher.error)
        return {
          id: reimbursementVoucher.id,
          key: reimbursementVoucher.id,
          statusText,
          statusClass: className,
          approvalState: reimbursementVoucher.approvalState,
          bookingState: reimbursementVoucher.bookingState,
          error: error ? formatError(error) : undefined,
          expenseCategory: reimbursementVoucher.expenseCategory ? reimbursementVoucher.expenseCategory.name : undefined,
          amount: formatCurrency(reimbursementVoucher.disburseAmount, 2),
          amountNo: reimbursementVoucher.disburseAmount ?? 0,
          disburseable: reimbursementVoucher.disburseable,
          immediatePay: reimbursementVoucher.immediatePay,
          employeeName,
          department: department ? department.name : undefined,
          costCenter: costCenter ? costCenter.name : undefined,
          note: reimbursementVoucher.note,
          date: formatDate(reimbursementVoucher.createdAt),
          receiptDate: formatDate(reimbursementVoucher.receiptDate || getDate()),
          canApproveObjects: hasDepartmentPermission(
            props.companyUser,
            employee && employee.departmentID,
            'ApproveObjects'
          ),
          canEditObjects: hasDepartmentPermission(props.companyUser, employee && employee.departmentID, 'EditObjects'),
          canDraft: !props.companyUsers.some((user) => user.userID === reimbursementVoucher.userID),
          original: reimbursementVoucher,
        }
      })
      .sort((a, b) => {
        let i = a
        let j = b
        if (sort.sortOrder === 'descend') {
          j = a
          i = b
        }
        const iDate = i.original.receiptDate ?? i.original.createdAt
        const jDate = j.original.receiptDate ?? j.original.createdAt
        switch (sort.sortOn) {
          case 'employee':
            if (i.employeeName === j.employeeName) {
              return iDate.localeCompare(jDate)
            }
            return i.employeeName.localeCompare(j.employeeName)
          case 'amount':
            if (j.amountNo === i.amountNo) {
              if (i.employeeName === j.employeeName) {
                return iDate.localeCompare(jDate)
              }
              return i.employeeName.localeCompare(j.employeeName)
            }
            return j.amountNo - i.amountNo
          case 'date':
            if (iDate === jDate) {
              return i.employeeName.localeCompare(j.employeeName)
            }
            return iDate.localeCompare(jDate)
          case 'expenseCategory': {
            const iExpenseCategory = i.expenseCategory ?? ''
            const jExpenseCategory = j.expenseCategory ?? ''
            if (iExpenseCategory === jExpenseCategory) {
              if (i.employeeName === j.employeeName) {
                return iDate.localeCompare(jDate)
              }
              return i.employeeName.localeCompare(j.employeeName)
            }
            return iExpenseCategory.localeCompare(jExpenseCategory)
          }
          case 'state':
            return i.statusText.localeCompare(j.statusText)
          default:
            if (iDate === jDate) {
              return i.employeeName.localeCompare(j.employeeName)
            }
            return iDate.localeCompare(jDate)
        }
      })
  }

  const getPendingReimbursementVouchers = () =>
    getReimbursementVouchers((voucher) => voucher.bookingState === 'Pending' || voucher.bookingState === 'Ready')

  const getProcessedReimbursementVouchers = () =>
    getReimbursementVouchers(
      (voucher) =>
        voucher.bookingState === 'Manual' ||
        voucher.bookingState === 'Booked' ||
        voucher.bookingState === 'Daybooked' ||
        voucher.bookingState === 'Failed'
    )

  const getRowClassName = (reimbursementVoucher: ReimbursementVoucherRow): string => {
    const classNames = []
    classNames.push(reimbursementVoucher.statusClass)
    return classNames.join(' ')
  }

  const toggleReimbursementVoucherFeature = (enable: boolean) => {
    const company = { ...props.company, enableReimbursementVouchers: enable }

    props.updateCompany(company)
  }

  const reimbursementVouchersEnabled = props.company.enableReimbursementVouchers

  const handleNewReimbursementVoucher = (id: string) => {
    setNewVisibility(false)
    createVisibility(id)
  }

  const tableChange = ({ sorter }: TableChange<ReimbursementVoucherRow>) => {
    if (!sorter.column) {
      return
    }
    switch (sorter.column.sorter) {
      case 'employee':
      case 'amount':
      case 'expenseCategory':
      case 'state':
      case 'date':
        setSort({ sortOn: sorter.column.sorter, sortOrder: sorter.order })
        break
    }
  }

  return (
    <div className="reimbursement-vouchers">
      <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />
      {error && <Alert message={formatError(error)} type="error" showIcon />}

      <TitleMenu
        style={{
          minWidth: '500px',
          textAlign: 'right',
        }}
      >
        <Row style={{ marginRight: 0, marginLeft: 0 }}>
          {props.company.enableReimbursementVouchers && !!props.companyUser && (
            <Button onClick={() => setNewVisibility(true)} type="secondary" className="gtm-reimbursement-upload">
              {t('reimbursement_vouchers.header.new_voucher')}
            </Button>
          )}
          {(!props.companyUser ||
            props.companyUser.permissions.some((permission) => permission.permission === 'Admin')) && (
            <FeatureLock
              featureType="Reimbursement Vouchers"
              featurePackage="Premium"
              description={t('reimbursement_vouchers.header.enable_feature.lock')}
            >
              <Switch
                checked={reimbursementVouchersEnabled}
                id="feature-toggle"
                onChange={toggleReimbursementVoucherFeature}
              />
              <label htmlFor="feature-toggle">
                {reimbursementVouchersEnabled
                  ? t('reimbursement_vouchers.header.disable_feature')
                  : t('reimbursement_vouchers.header.enable_feature')}
              </label>
            </FeatureLock>
          )}
          {!showHistory &&
            getReimbursementVoucherIdsForApproval().length > 0 &&
            hasDepartmentPermission(props.companyUser, undefined, 'ApproveObjects') && (
              <Button onClick={approveAll} className="gtm-approve-all-reimbursement-vouchers">
                {t('reimbursement_vouchers.header.approve_all')}
              </Button>
            )}
          {!isDepartmentRestricted(props.companyUser) && (
            <Link to={'/' + paths.COMPANIES + '/' + props.company.id + '/expense-categories'}>
              <Button className="gtm-show-expense-categories">
                {t('reimbursement_vouchers.header.expense_categories')}
              </Button>
            </Link>
          )}
          {!isDepartmentRestricted(props.companyUser) && (
            <Link to={'/' + paths.COMPANIES + '/' + props.company.id + '/reimbursement-settings'}>
              <Button className="gtm-show-reimbursement-settings">{t('reimbursement_vouchers.header.settings')}</Button>
            </Link>
          )}
          <Button onClick={() => setReportVisibility(true)}>{t('reimbursement_vouchers.header.report')}</Button>
          {showHistory ? (
            <Link to={'/' + paths.REIMBURSEMENT_VOUCHERS}>
              <Button className="gtm-hide-reimbursement-vouchers-history">
                {t('reimbursement_vouchers.header.history.back')}
              </Button>
            </Link>
          ) : (
            <Link to={'/' + paths.REIMBURSEMENT_VOUCHERS + '/history'}>
              <Button className="gtm-show-reimbursement-vouchers-history">
                {t('reimbursement_vouchers.header.history.show')}
              </Button>
            </Link>
          )}
        </Row>
      </TitleMenu>

      <Title>
        {showHistory ? t('reimbursement_vouchers.title.history') : t('reimbursement_vouchers.title.active')}
      </Title>
      <Row>
        <Col span={24}>
          <EmployeeFilter
            departments={props.departments}
            companyUser={props.companyUser}
            onFilterChange={(filter) => setFilter(filter)}
          />
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={showHistory ? getProcessedReimbursementVouchers() : getPendingReimbursementVouchers()}
        onChange={tableChange}
        pagination={{
          defaultCurrent: getPage(),
          defaultPageSize: 25,
          onChange: setPage,
        }}
        className="reimbursement-vouchers-table"
        rowClassName={getRowClassName}
      />

      <Modal
        key={`edit-${modalKey}`}
        visible={editing !== false}
        onOk={() => setEditVisibility(false)}
        onCancel={() => setEditVisibility(false)}
        width={980}
        footer={null}
      >
        <ReimbursementVoucherEdit
          visible={editing !== false}
          reimbursementVoucherID={typeof editing === 'boolean' ? undefined : editing}
          company={props.company}
          companyUsers={props.companyUsers}
          companyUser={props.companyUser}
          employees={props.employees}
          expenseCategories={props.expenseCategories.expenseCategories}
          reimbursementVouchers={props.reimbursementVouchers}
          costCenterAccounting={props.company.costCenterAccounting || 'Off'}
          costCenters={props.costCenters}
          updateReimbursementVoucher={props.updateReimbursementVoucher}
          updateReimbursementVoucherFields={props.updateReimbursementVoucherFields}
          approveReimbursementVouchers={props.approveReimbursementVouchers}
          unapproveReimbursementVouchers={props.unapproveReimbursementVouchers}
          draftReimbursementVouchers={props.draftReimbursementVouchers}
          closeModal={() => setEditVisibility(false)}
        />
      </Modal>

      <Modal
        key={`new-${modalKey}`}
        visible={creating}
        onOk={() => setNewVisibility(false)}
        onCancel={() => setNewVisibility(false)}
        width={460}
        footer={null}
      >
        <ReimbursementVoucherNew
          visible={creating}
          company={props.company}
          employees={props.employees}
          reimbursementVouchers={props.reimbursementVouchers}
          addReimbursementVoucher={props.addReimbursementVoucher}
          newReimbursementVoucher={handleNewReimbursementVoucher}
        />
      </Modal>

      <Modal
        key={`report-${modalKey}`}
        visible={showReport}
        onOk={() => setReportVisibility(false)}
        onCancel={() => setReportVisibility(false)}
        width={700}
        footer={null}
      >
        <ReimbursementVoucherReport companyID={props.company.id} addAlert={props.addAlert} />
      </Modal>
    </div>
  )
}
