import CompanySetting from '../model/companySetting'
import Contract from '../model/contract'
import Employee, { AffiliationType, NationalIDType } from '../model/employee'
import { OneTimePayCategory, OneTimePayType } from '../model/oneTimePay'
import { EmploymentType } from '../model/types'
import CountryCode from '../types/country-code'
import RemunerationType from '../types/remuneration-type'

export function getNationalIDTypes(
  settingsEnabled: CompanySetting[],
  affiliationType: AffiliationType
): NationalIDType[] {
  const nationalIDTypes: NationalIDType[] = ['DK CPR']
  if (affiliationType === 'Freelancer' && settingsEnabled.some((setting) => setting === 'AllowFreelancerCVR')) {
    nationalIDTypes.push('DK CVR')
  }
  nationalIDTypes.push('DK Foreign')
  if (settingsEnabled.some((setting) => setting === 'AllowNoCPR')) {
    nationalIDTypes.push('DK No CPR')
  }
  return nationalIDTypes
}
export function hasNationalID(nationalIDType: NationalIDType): boolean {
  switch (nationalIDType) {
    case 'DK CPR':
    case 'DK CVR':
    case 'DK Foreign':
      return true
    default:
      return false
  }
}
export function hasCountry(nationalIDType: NationalIDType): boolean {
  switch (nationalIDType) {
    case 'DK CPR':
    case 'DK CVR':
      return false
    default:
      return true
  }
}
export function hasBirthDate(nationalIDType: NationalIDType): boolean {
  switch (nationalIDType) {
    case 'DK CPR':
    case 'DK CVR':
      return false
    default:
      return true
  }
}
export function hasGender(nationalIDType: NationalIDType): boolean {
  switch (nationalIDType) {
    case 'DK CPR':
    case 'DK CVR':
      return false
    default:
      return true
  }
}

const tinCountries: CountryCode[] = [
  CountryCode.AT,
  CountryCode.BE,
  CountryCode.BG,
  CountryCode.CY,
  CountryCode.CZ,
  CountryCode.DE,
  CountryCode.EE,
  CountryCode.GR,
  CountryCode.ES,
  CountryCode.FI,
  CountryCode.FR,
  CountryCode.HR,
  CountryCode.HU,
  CountryCode.IE,
  CountryCode.IT,
  CountryCode.LT,
  CountryCode.LU,
  CountryCode.LV,
  CountryCode.MT,
  CountryCode.NL,
  CountryCode.PL,
  CountryCode.PT,
  CountryCode.RO,
  CountryCode.SE,
  CountryCode.SI,
  CountryCode.SK,
  CountryCode.NO,
  CountryCode.FO,
  CountryCode.GL,
  CountryCode.IS,
]

export function hasTin(country: CountryCode): boolean {
  return tinCountries.indexOf(country) !== -1
}

// Basic bonus types for without advanced settings
export const bonusTypes: OneTimePayType[] = [
  'Bonus',
  'Bonus No Vacation',
  'Bonus No Pension',
  'Bonus No Vacation and Pension',
  'G-Dage',
]

// Advanced bonus types
export const additionalBonusTypes: OneTimePayType[] = [
  'Anniversary Bonus',
  'Honorary Gift',
  'Compensation No AM',
  'Compensation',
  'Car Allowance',
  'Free Phone',
  'Car Simple',
  'ATP',
  'Benefit In Kind',
  'Tax Free Income',
  'Tax Free Income Abroad',
  'Vacation Fund Payment',
  'Tax Deduction Modification Increase',
  'Tax Deduction Modification Decrease',
  'Work Hours',
  'Vacation Money Amount',
  'Vacation Money Days',
]

// Bonus types related to SH/Fritvalg
export const shFritvalgTypes: OneTimePayType[] = [
  'Fritvalg',
  'Fritvalg Increase',
  'Fritvalg Increase No Vacation',
  'Fritvalg Increase No Pension',
  'Fritvalg Increase No Vacation and Pension',
  'SH-Dage',
  'SH-Dage Increase',
  'SH-Dage Increase No Vacation',
  'SH-Dage Increase No Pension',
  'SH-Dage Increase No Vacation and Pension',
]

// A combination of common bonus types without advanced settings, for the purpose of display and combining
export const regularBonusTypes: OneTimePayType[] = [
  'Bonus',
  'Bonus No Vacation',
  'Bonus No Pension',
  'Bonus No Vacation and Pension',
  'G-Dage',
  'Fritvalg',
  'SH-Dage',
  'Paid Vacation',
  'Gross Vacation Money',
  'Overtime Payout',
]

export function isBonus(type: OneTimePayType): boolean {
  return (
    bonusTypes.indexOf(type) !== -1 ||
    additionalBonusTypes.indexOf(type) !== -1 ||
    shFritvalgTypes.indexOf(type) !== -1 ||
    regularBonusTypes.indexOf(type) !== -1 ||
    type === 'Honorary Gift Benefit In Kind'
  )
}

export function oneTimePayCategoriesByType(type: OneTimePayType): OneTimePayCategory[] {
  let categories: OneTimePayCategory[] = []
  switch (type) {
    case 'Compensation':
      categories = ['DKArt-0079', 'DKArt-0050', 'DKArt-0051', 'DKArt-0061', 'DKArt-0082']
      break
    case 'Compensation No AM':
      categories = ['DKArt-0097', 'DKArt-0099', 'DKArt-0100']
      break
    case 'G-Dage':
      categories = ['DKArt-0103', 'DKArt-0104']
      break
    default:
      break
  }
  return categories
}

type hasRemunerationType = {
  remunerationType?: RemunerationType
}

export type EmployeePayType = 'Salaried' | 'Hourly Paid' | 'Commissioned'

export function getEmployeePayType(contract?: hasRemunerationType): EmployeePayType {
  if (!contract) {
    return 'Commissioned'
  }
  switch (contract.remunerationType) {
    case RemunerationType.SALARIED:
      return 'Salaried'
    case RemunerationType.HOURLY:
      return 'Hourly Paid'
    case RemunerationType.COMMISSIONED:
      return 'Commissioned'
    default:
      return 'Commissioned'
  }
}

export const employmentTypes: EmploymentType[] = ['Hourly', 'Commissioned', 'Fixed', 'Freelancer']

export function getAverageHours(contract: Contract): number {
  return contract.workCycleHours.reduce((n, t) => n + t, 0) / contract.workCycleHours.length
}

export function isEmployeeType(employee: Employee, employmentType: EmploymentType): boolean {
  const contract = employee.earliestMutableContract
  if (!contract) {
    return false
  }

  const payType = getEmployeePayType(contract)
  switch (employmentType) {
    case 'FullTime':
      return payType === 'Salaried' && getAverageHours(contract) > 30
    case 'PartTime':
      return payType === 'Salaried' && getAverageHours(contract) <= 30
    case 'Fixed':
      return payType === 'Salaried'
    case 'Hourly':
      return payType === 'Hourly Paid'
    case 'Freelancer':
      return employee.affiliationType === 'Freelancer'
    case 'Commissioned':
      return payType === 'Commissioned'
    default:
      return false
  }
}

export function translatePayTypeToEmploymentType(payType: EmployeePayType): EmploymentType {
  switch (payType) {
    case 'Salaried':
      return 'Fixed'
    case 'Hourly Paid':
      return 'Hourly'
    case 'Commissioned':
      return 'Commissioned'
    default:
      return 'Commissioned'
  }
}

export function translateEmploymentTypeToPayType(employmentType: EmploymentType): EmployeePayType {
  switch (employmentType) {
    case 'Fixed':
    case 'FullTime':
    case 'PartTime':
      return 'Salaried'
    case 'Hourly':
      return 'Hourly Paid'
    case 'Commissioned':
      return 'Commissioned'
    default:
      // yes freelancer is translated to commissioned
      return 'Commissioned'
  }
}

export function translatePayTypeToRemunerationType(payType: EmployeePayType): RemunerationType {
  switch (payType) {
    case 'Salaried':
      return RemunerationType.SALARIED
    case 'Hourly Paid':
      return RemunerationType.HOURLY
    case 'Commissioned':
      return RemunerationType.COMMISSIONED
  }
}

export function compareEmployees(a: Employee, b: Employee) {
  return (a.name || a.email || '').localeCompare(b.name || b.email || '', 'da-dk')
}
