import { ReportType } from '../model/report'
import { logWarning } from './log-utils'
import { t } from './translation-utils'

export function formatReportName(report: ReportType): string {
  switch (report) {
    case 'SalaryAccountsMonthlyFiltered1':
      return t('report_name.salary_accounts_monthly_filtered_1')
    case 'PeriodEmployees':
      return t('report_name.period_employees')
    case 'PeriodIncomeTaxReport':
      return t('report_name.period_income_tax_report')
    case 'PeriodEmployeesSalaryTypes':
      return t('report_name.period_employees_salary_types')
    case 'PeriodEmployeesVacationTransition':
      return t('report_name.period_employees_vacation_transition')
    case 'Employees':
      return t('report_name.employees')
    case 'PayRollEmployees':
      return t('report_name.pay_roll_employees')
    case 'CarAllowance':
      return t('report_name.car_allowance')
    case 'TimeRegistrations':
      return t('report_name.time_registrations')
    case 'ProjectRegistrations':
      return t('report_name.project_registrations')
    case 'FlexRegistrations':
      return t('report_name.flex_registrations')
    case 'OvertimeRegistrations':
      return t('report_name.overtime_registrations')
    case 'LeaveRegistrations':
      return t('report_name.leave_registrations')
    case 'Reimbursements':
      return t('report_name.reimbursements')
    case 'ReimbursementVouchersAccounting':
      return t('report_name.reimbursement_vouchers_accounting')
    case 'LeaveBalances':
      return t('report_name.leave_balances')
    case 'OneTimePays':
      return t('report_name.one_time_pays')
    case 'SalaryReceipt':
      return t('report_name.salary_receipts')
    case 'Assets':
      return t('report_name.assets')
    case 'PayRollTransfers':
      return t('report_name.pay_roll_transfers')
    case 'LeaveDifferences':
      return t('report_name.leave_differences')
    case 'StartBalances':
      return t('report_name.start_balances')
    case 'Employments':
      return t('report_name.employments')
    case 'PeriodPayslipCountReport':
      return t('report_name.period_payslip_count_report')
    case 'WorkHoursRegistrations':
      return t('report_name.work_hours_registrations')
    case 'SalaryRegistrations':
      return t('report_name.salary_registrations')
    case 'PayRollAccounting':
      return t('report_name.pay_roll_accounting')
    default: {
      logWarning('Encountered unknown report: `' + report + '`')
      return t('common.unknown')
    }
  }
}

export function getReports(): ReportType[] {
  return [
    'SalaryAccountsMonthlyFiltered1',
    'PeriodEmployees',
    'LeaveBalances',
    'PeriodIncomeTaxReport',
    'PeriodEmployeesVacationTransition',
    'Employees',
    'PayRollEmployees',
    'PayRollAccounting',
    'CarAllowance',
    'TimeRegistrations',
    'ProjectRegistrations',
    'FlexRegistrations',
    'OvertimeRegistrations',
    'LeaveRegistrations',
    'WorkHoursRegistrations',
    'SalaryRegistrations',
    'Reimbursements',
    'ReimbursementVouchersAccounting',
    'PeriodEmployeesSalaryTypes',
    'OneTimePays',
    'SalaryReceipt',
    'Assets',
    'PayRollTransfers',
    'LeaveDifferences',
    'StartBalances',
    'Employments',
  ]
}

export function getGroupReports(): ReportType[] {
  return [
    'SalaryAccountsMonthlyFiltered1',
    'PeriodEmployees',
    'PeriodIncomeTaxReport',
    'OneTimePays',
    'PayRollTransfers',
    'PeriodPayslipCountReport',
  ]
}
