import { addYears, startOfYear, subYears } from 'date-fns'
import React, { ReactElement, useEffect } from 'react'

import { addAlert, addAlertSignature, removeAlert, removeAlertSignature } from '../actions/alerts'
import { getCoarseTimeRegistrations, updateCoarseTimeRegistrationBulk } from '../actions/coarse-time-registrations'
import { addContract, getContracts, getRemuneration, updateContract } from '../actions/contracts'
import { getSalaryCycles } from '../actions/salary-cycles'
import {
  approveTimeRegistrations,
  createTimeRegistration,
  deleteTimeRegistration,
  deleteTimeRegistrationBulk,
  getTimeRegistrations,
  updateTimeRegistration,
} from '../actions/time-registrations'
import { getVacationCalendarYear } from '../actions/vacation-calendars'
import TimeRegistrationsComponent from '../components/time-registration/TimeRegistrations'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import { CoarseTimeRegistrationMutableFields } from '../model/coarseTimeRegistration'
import { ContractCreationFields, ContractMutableFields } from '../model/contract'
import {
  TimeRegistrationClass,
  TimeRegistrationCreationFields,
  TimeRegistrationMutableFields,
} from '../model/timeRegistration'
import { DateFormat } from '../model/types'
import { AlertReducer } from '../reducers/alerts'
import { CoarseTimeRegistrationReducer } from '../reducers/coarseTimeRegistrations'
import { CompanyReducer } from '../reducers/companies'
import { CompanyUserReducer } from '../reducers/companyUsers'
import { ContractReducer } from '../reducers/contracts'
import { CostCenterReducer } from '../reducers/costCenters'
import { DepartmentReducer } from '../reducers/departments'
import { EmployeeReducer } from '../reducers/employees'
import { ProjectReducer } from '../reducers/projects'
import { SalaryCycleReducer } from '../reducers/salaryCycles'
import { SalaryTypeReducer } from '../reducers/salaryTypes'
import { TimeRegistrationReducer } from '../reducers/timeRegistrations'
import { UserReducer } from '../reducers/user'
import { VacationCalendarReducer } from '../reducers/vacationCalendars'
import { formatAPIDate, getDate } from '../utils/date-utils'
import { formatLoadingText } from '../utils/loading-utils'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'
import { t } from '../utils/translation-utils'
import RegistrationLayout from './layouts/RegistrationLayout'

type Reducers = {
  alerts: AlertReducer
  user: UserReducer
  companies: CompanyReducer
  companyUsers: CompanyUserReducer
  employees: EmployeeReducer
  contracts: ContractReducer
  salaryCycles: SalaryCycleReducer
  coarseTimeRegistrations: CoarseTimeRegistrationReducer
  timeRegistrations: TimeRegistrationReducer
  salaryTypes: SalaryTypeReducer
  costCenters: CostCenterReducer
  departments: DepartmentReducer
  projects: ProjectReducer
  vacationCalendars: VacationCalendarReducer
}

type Actions = {
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  getSalaryCycles: () => void
  getCoarseTimeRegistrations: () => void
  updateCoarseTimeRegistrationBulk: (employeeID: string, registrations: CoarseTimeRegistrationMutableFields[]) => void
  getTimeRegistrations: (
    companyID: string | undefined,
    employeeID: string | undefined,
    payRollID: string | undefined,
    from: DateFormat,
    to: DateFormat
  ) => void
  approveTimeRegistrations: (registrationIDs: string[]) => void
  getContracts: () => void
  addContract: (contract: ContractCreationFields) => void
  updateContract: (contract: ContractMutableFields) => void
  deleteTimeRegistrationBulk: (
    companyID: string | undefined,
    employeeID: string | undefined,
    timeRegistrationClass: TimeRegistrationClass
  ) => Promise<boolean | void>
  getVacationCalendarYear: (companyID: string, year: number) => void
  getRemuneration: (id: string) => void
  createTimeRegistration: (reg: TimeRegistrationCreationFields) => void
  updateTimeRegistration: (reg: TimeRegistrationMutableFields) => void
  deleteTimeRegistration: (id: string) => void
}

function TimeRegistration(props: Reducers & Actions & RouteProps): ReactElement | null {
  const company = props.companies.company
  const { salaryCycles, getSalaryCycles, timeRegistrations, getTimeRegistrations } = props
  useEffect(() => {
    if (!salaryCycles.loaded && !salaryCycles.loading) {
      getSalaryCycles()
    }
    const companyID = company?.id
    if (
      companyID &&
      (timeRegistrations.companyID !== companyID ||
        !!timeRegistrations.employeeID ||
        (!timeRegistrations.loading && !timeRegistrations.loaded))
    ) {
      getTimeRegistrations(
        companyID,
        undefined,
        undefined,
        formatAPIDate(startOfYear(subYears(getDate(), 2))),
        formatAPIDate(addYears(getDate(), 10))
      )
    }
  })

  if (!company) {
    jsBrowserHistory.push('/')
    return null
  }

  const loading = !salaryCycles.loaded || (props.timeRegistrations.loading && !props.timeRegistrations.loaded)
  if (loading) {
    return (
      <div
        style={{
          position: 'relative',
          minHeight: '300px',
          marginTop: '96px',
        }}
      >
        <LoadingOverlay
          text={formatLoadingText([
            { loading: !props.salaryCycles.loaded, text: t('loading.reducer.salary_cycles') },
            {
              loading: props.timeRegistrations.loading && !props.timeRegistrations.loaded,
              text: t('loading.reducer.time_registrations'),
            },
          ])}
        />
      </div>
    )
  }

  const getShowCalendar = (): boolean => {
    const s = props.location.pathname.split('/')
    return s[s.length - 1] === 'calendar'
  }

  return (
    <RegistrationLayout location={props.location}>
      <TimeRegistrationsComponent
        alerts={props.alerts}
        showCalendar={getShowCalendar()}
        user={props.user}
        company={company}
        companyUsers={props.companyUsers}
        employees={props.employees.employees}
        contracts={props.contracts}
        coarseTimeRegistrations={props.coarseTimeRegistrations}
        timeRegistrations={props.timeRegistrations}
        costCenters={props.costCenters.costCenters}
        departments={props.departments.departments}
        salaryCycles={props.salaryCycles.salaryCycles}
        salaryTypes={props.salaryTypes.salaryTypes}
        projects={props.projects.projects}
        vacationCalendars={props.vacationCalendars}
        getCoarseTimeRegistrations={props.getCoarseTimeRegistrations}
        updateCoarseTimeRegistrationBulk={props.updateCoarseTimeRegistrationBulk}
        approveTimeRegistrations={props.approveTimeRegistrations}
        getContracts={props.getContracts}
        addContract={props.addContract}
        updateContract={props.updateContract}
        deleteTimeRegistrationBulk={props.deleteTimeRegistrationBulk}
        addAlert={props.addAlert}
        removeAlert={props.removeAlert}
        getVacationCalendarYear={props.getVacationCalendarYear}
        getRemuneration={props.getRemuneration}
        createTimeRegistration={props.createTimeRegistration}
        updateTimeRegistration={props.updateTimeRegistration}
        deleteTimeRegistration={props.deleteTimeRegistration}
      />
    </RegistrationLayout>
  )
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    alerts: state.alerts,
    user: state.user,
    companies: state.companies,
    companyUsers: state.companyUsers,
    employees: state.employees,
    contracts: state.contracts,
    salaryCycles: state.salaryCycles,
    coarseTimeRegistrations: state.coarseTimeRegistrations,
    timeRegistrations: state.timeRegistrations,
    salaryTypes: state.salaryTypes,
    costCenters: state.costCenters,
    departments: state.departments,
    projects: state.projects,
    vacationCalendars: state.vacationCalendars,
  }),
  {
    addAlert: addAlert,
    removeAlert: removeAlert,
    getSalaryCycles: getSalaryCycles,
    getCoarseTimeRegistrations: getCoarseTimeRegistrations,
    getTimeRegistrations: getTimeRegistrations,
    updateCoarseTimeRegistrationBulk: updateCoarseTimeRegistrationBulk,
    approveTimeRegistrations: approveTimeRegistrations,
    getContracts: getContracts,
    addContract: addContract,
    updateContract: updateContract,
    deleteTimeRegistrationBulk: deleteTimeRegistrationBulk,
    getVacationCalendarYear: getVacationCalendarYear,
    getRemuneration: getRemuneration,
    createTimeRegistration: createTimeRegistration,
    updateTimeRegistration: updateTimeRegistration,
    deleteTimeRegistration: deleteTimeRegistration,
  }
)(TimeRegistration)
