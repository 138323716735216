import { List } from 'immutable'
import React, { ReactElement, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

import paths from '../../constants/paths'
import Company from '../../model/company'
import CompanyUser from '../../model/companyUser'
import CostCenter from '../../model/costCenter'
import Department from '../../model/department'
import Employee from '../../model/employee'
import Project from '../../model/project'
import SalaryType from '../../model/salaryType'
import { TimeRegistrationCreationFields, TimeRegistrationMutableFields } from '../../model/timeRegistration'
import { TimeRegistrationReducer } from '../../reducers/timeRegistrations'
import { VacationCalendarReducer } from '../../reducers/vacationCalendars'
import { buildDate, formatDate } from '../../utils/date-utils'
import { formatMinutesAsTime } from '../../utils/number-utils'
import { t, translateGroupTitle } from '../../utils/translation-utils'
import Calendar from '../elements/calendar'
import Modal from '../elements/modal'
import Title from '../elements/Title'
import DetailedTimeRegistrationEdit from '../employees-single/time-registration/DetailedTimeRegistrationEdit'

type Props = {
  company: Company
  companyUser?: CompanyUser
  employees: List<Employee>
  costCenters: List<CostCenter>
  departments: List<Department>
  timeRegistrations: TimeRegistrationReducer
  vacationCalendars: VacationCalendarReducer
  salaryTypes: List<SalaryType>
  projects: List<Project>

  getVacationCalendarYear: (companyID: string, year: number) => void
  getRemuneration: (id: string) => void
  createTimeRegistration: (reg: TimeRegistrationCreationFields) => void
  updateTimeRegistration: (reg: TimeRegistrationMutableFields) => void
  approveTimeRegistrations: (ids: string[]) => void
  deleteTimeRegistration: (id: string) => void
}

export default function TimeRegistrationCalendar(props: Props): ReactElement | null {
  const [modalKey, setModalKey] = useState(1)
  const [timeRegEditEmployeeID, setTimeRegEditEmployeeID] = useState<string | boolean>(false)
  const [timeRegEditTimeRegID, setTimeRegEditTimeRegID] = useState<string | undefined>(undefined)
  const [suggestedDate, setSuggestedDate] = useState<Date>()

  const setTimeRegEditVisibility = (employeeID: string, timeRegID: string) => {
    setModalKey((prev) => prev + 1)
    setTimeRegEditTimeRegID(timeRegID)
    setTimeRegEditEmployeeID(employeeID)
  }
  const setCreateTimeRegEdit = (employeeID: string, date: Date) => {
    setModalKey((prev) => prev + 1)
    setTimeRegEditEmployeeID(employeeID)
    setSuggestedDate(date)
  }
  const closeTimeRegEdit = () => {
    setTimeRegEditEmployeeID(false)
    setTimeRegEditTimeRegID(undefined)
  }

  const previousTimeRegistrations = usePrevious(props.timeRegistrations)

  useEffect(() => {
    if (previousTimeRegistrations?.saving && !props.timeRegistrations.saving) {
      if (!props.timeRegistrations.error) {
        closeTimeRegEdit()
      }
    }
  }, [previousTimeRegistrations, props.timeRegistrations])

  const employee = props.employees.find((employee) => employee.id === timeRegEditEmployeeID)

  return (
    <>
      <Title>{t('time_registrations.calendar.title')}</Title>
      <Calendar
        timeRegistrationFilter={(reg) => reg.class === 'Hours'}
        employeeFilter={(employee) =>
          employee.activeContract?.remunerationType === 'Hourly' &&
          employee.activeContract?.timeRegistrationMethodType === 'Detailed'
        }
        formatRegistrationCell={(timeRegs, employee, date) => {
          if (timeRegs.length > 1) {
            return {
              title: t('time_registrations.calendar.tooltip_format_hours', {
                name: employee.name,
                date: formatDate(date),
              }),
              additionalClass: 'employee-time',
              allowGradient: false,
              percentage: 100,
              amount: timeRegs.reduce((amount, timeReg) => amount + (timeReg.hours ?? 0), 0),
              alwaysDisplayAmount: true,
            }
          }
          const timeReg = timeRegs[0]
          const salaryLine = employee.activeContract?.remuneration?.salary.find(
            (row) => row.salaryTypeID === timeReg.salaryTypeID
          )
          let title = t('common.unknown')
          if (salaryLine) {
            title = translateGroupTitle(salaryLine)
          }
          return {
            title: t('time_registrations.calendar.tooltip_format_named', {
              salary: title,
              name: employee.name,
              date: formatDate(date),
            }),
            additionalClass: 'employee-time',
            allowGradient: false,
            percentage: 100,
            amount: timeReg.hours || 0,
            alwaysDisplayAmount: true,
          }
        }}
        formatRegistrationContextMenuItem={(timeReg) => {
          if (!!timeReg.start && !!timeReg.end) {
            return t(
              timeReg.note
                ? 'time_registrations.calendar.context_menu.time_format.with_note'
                : 'time_registrations.calendar.context_menu.time_format.no_note',
              {
                start: formatMinutesAsTime(timeReg.start),
                end: formatMinutesAsTime(timeReg.end),
                note: timeReg.note,
              }
            )
          } else {
            return t(
              timeReg.note
                ? 'time_registrations.calendar.context_menu.hours_format.with_note'
                : 'time_registrations.calendar.context_menu.hours_format.no_note',
              {
                hours: t('unit.hour_count', { count: timeReg.hours }),
                note: timeReg.note,
              }
            )
          }
        }}
        employeeLink={(employeeID) => '/' + paths.EMPLOYEES + '/' + employeeID + '/time-registration'}
        instructionText={<p>{t('time_registrations.calendar.instruction_text')}</p>}
        company={props.company}
        employees={props.employees}
        departments={props.departments}
        timeRegistrations={props.timeRegistrations.timeRegistrations}
        vacationCalendars={props.vacationCalendars}
        getVacationCalendarYear={props.getVacationCalendarYear}
        approveAllConfirmText={(needApproval, currentYear, currentMonth) =>
          t('time_registrations.calendar.approve_all_text', {
            count: needApproval,
            month: formatDate(buildDate(currentYear, currentMonth), t('date.month_of_year')),
          })
        }
        legends={[{ className: 'employee-time', title: t('time_registrations.calendar.legend_hours') }]}
        newTypeText={t('time_registrations.calendar.new_type')}
        onEditRegistration={setTimeRegEditVisibility}
        onApproveTimeRegistrations={props.approveTimeRegistrations}
        onCellClick={setCreateTimeRegEdit}
        allowMoreRegistrationsPerDay={true}
        allowRegistrationsOnHoliday
      />

      <Modal
        key={modalKey}
        visible={!!timeRegEditEmployeeID}
        onOk={() => closeTimeRegEdit()}
        onCancel={() => closeTimeRegEdit()}
        width={582}
        footer={null}
      >
        <DetailedTimeRegistrationEdit
          visible={!!timeRegEditEmployeeID}
          mode={'Hours'}
          employee={employee}
          suggestedDate={suggestedDate}
          company={props.company}
          costCenters={props.costCenters}
          departments={props.departments}
          timeRegistrations={props.timeRegistrations}
          timeRegistrationID={timeRegEditTimeRegID}
          salaryTypes={props.salaryTypes}
          projects={props.projects}
          createTimeRegistration={props.createTimeRegistration}
          updateTimeRegistration={props.updateTimeRegistration}
          deleteTimeRegistration={props.deleteTimeRegistration}
          approveTimeRegistrations={props.approveTimeRegistrations}
        />
      </Modal>
    </>
  )
}
