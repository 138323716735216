import React, { ReactElement } from 'react'
import { useEffectOnce } from 'react-use'

import { addAlert, addAlertSignature } from '../actions/alerts'
import { postSlackIntegration } from '../api/slack-integrations'
import jsBrowserHistory from '../components/widgets/jsBrowserHistory'
import LoadingOverlay from '../components/widgets/LoadingOverlay'
import paths from '../constants/paths'
import { CompanyReducer } from '../reducers/companies'
import { formatError, isRequestError } from '../utils/error-utils'
import { logWarning } from '../utils/log-utils'
import { connectToReducer } from '../utils/reducer-utils'
import { RouteProps } from '../utils/route-utils'

type Reducers = {
  companies: CompanyReducer
}

type Actions = {
  addAlert: addAlertSignature
}

function InSlack(props: Reducers & Actions & RouteProps): ReactElement | null {
  const state = props.location.query.state
  const code = props.location.query.code

  const { companies, addAlert } = props

  useEffectOnce(() => {
    if (!code) {
      logWarning('Attempted to in/slack without code')
      jsBrowserHistory.push('/' + paths.INTEGRATIONS + '/' + paths.SLACK)
      return
    }

    if (!companies.company) {
      logWarning('Attemptedto in/slack but could not locate company')
      jsBrowserHistory.push('/' + paths.INTEGRATIONS + '/' + paths.SLACK)
      return
    }

    postSlackIntegration(companies.company.id, code, state)
      .catch((e) => {
        if (isRequestError(e)) {
          addAlert('error', formatError(e), { timeout: 5 })
        }
      })
      .finally(() => {
        jsBrowserHistory.push('/' + paths.INTEGRATIONS + '/' + paths.SLACK)
      })
  })

  return <LoadingOverlay />
}

export default connectToReducer<Reducers, Actions, RouteProps>(
  (state) => ({
    companies: state.companies,
  }),
  {
    addAlert: addAlert,
  }
)(InSlack)
