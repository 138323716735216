import React, { ReactElement } from 'react'

import Company from '../../model/company'
import CompanyUser from '../../model/companyUser'
import { UserCompanyReducer } from '../../reducers/userCompanies'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Headline from '../elements/Headline'
import SwitchWrapper from '../form-elements/SwitchWrapper'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  company: Company
  userCompany: CompanyUser
  userCompanies: UserCompanyReducer
}

export type ResultFields = {
  notificationPayRollAutomaticallyStarted: boolean
  notificationPayRollDone: boolean
  notificationPayRollDeadlineUpcoming: boolean
  notificationPayRollApprovalUpcoming: boolean
  notificationSwipeAwaitingApproval: boolean
  notificationTransferInfo: boolean
  notificationEmployeeUpdatedBanking: boolean
  notificationAutomaticZeroTaxReportDone: boolean
}

function UserNotificationTabForm(props: Props & FormComponentProps<ResultFields, ResultFields>): ReactElement | null {
  const { decorateField } = props

  return (
    <div className="companies-single-form">
      <Row>&nbsp;</Row>
      <Row>
        <Col span={24}>
          <Headline>{t('user_notifications.form.section.title')}</Headline>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <SwitchWrapper<ResultFields> id={'notificationPayRollAutomaticallyStarted'} decorateField={decorateField}>
            {t('user_notifications.form.notification_pay_roll_automatically_started')}
          </SwitchWrapper>
        </Col>
        <Col span={12}>
          <SwitchWrapper<ResultFields> id={'notificationPayRollDone'} decorateField={decorateField}>
            {t('user_notifications.form.notification_pay_roll_done')}
          </SwitchWrapper>
        </Col>
        <Col span={12}>
          <SwitchWrapper<ResultFields> id={'notificationPayRollDeadlineUpcoming'} decorateField={decorateField}>
            {t('user_notifications.form.notification_pay_roll_deadline_upcoming')}
          </SwitchWrapper>
        </Col>
        {props.company.dkSpecific?.allowAutomaticZeroTaxReport && (
          <Col span={12}>
            <SwitchWrapper<ResultFields> id={'notificationAutomaticZeroTaxReportDone'} decorateField={decorateField}>
              {t('user_notifications.form.notification_automatic_zero_tax_report_done')}
            </SwitchWrapper>
          </Col>
        )}
        <Col span={12}>
          <SwitchWrapper<ResultFields> id={'notificationTransferInfo'} decorateField={decorateField}>
            {t('user_notifications.form.notification_transfer_info')}
          </SwitchWrapper>
        </Col>
        <Col span={24}>
          <SwitchWrapper<ResultFields> id={'notificationPayRollApprovalUpcoming'} decorateField={decorateField}>
            {t('user_notifications.form.notification_pay_roll_approval_upcoming')}
          </SwitchWrapper>
        </Col>
        {props.company.settingsEnabled.some((setting) => setting === 'AllowEmployeeUpdateBanking') && (
          <Col span={24}>
            <SwitchWrapper<ResultFields> id={'notificationEmployeeUpdatedBanking'} decorateField={decorateField}>
              {t('user_notifications.form.notification_employee_updated_banking')}
            </SwitchWrapper>
          </Col>
        )}
        {props.company.enableSwipe && (
          <Col span={12}>
            <SwitchWrapper<ResultFields> id={'notificationSwipeAwaitingApproval'} decorateField={decorateField}>
              {t('user_notifications.form.notification_swipe_awaiting_approval')}
            </SwitchWrapper>
          </Col>
        )}
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="primary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      {props.userCompanies.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, ResultFields, ResultFields>({
  mapPropsToFields: (props) => ({
    notificationPayRollAutomaticallyStarted: props.userCompany.notificationPayRollAutomaticallyStarted,
    notificationPayRollDone: props.userCompany.notificationPayRollDone,
    notificationPayRollDeadlineUpcoming: props.userCompany.notificationPayRollDeadlineUpcoming,
    notificationPayRollApprovalUpcoming: props.userCompany.notificationPayRollApprovalUpcoming,
    notificationSwipeAwaitingApproval: props.userCompany.notificationSwipeAwaitingApproval,
    notificationTransferInfo: props.userCompany.notificationTransferInfo,
    notificationEmployeeUpdatedBanking: props.userCompany.notificationEmployeeUpdatedBanking,
    notificationAutomaticZeroTaxReportDone: props.userCompany.notificationAutomaticZeroTaxReportDone,
  }),
  onSubmit: (values) => values,
})(UserNotificationTabForm)
