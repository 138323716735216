import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Company from '../../model/company'
import Department from '../../model/department'
import Employee from '../../model/employee'
import SalaryCycle from '../../model/salaryCycle'
import { EmploymentType } from '../../model/types'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Subtitle from '../elements/Subtitle'
import SearchEmployeeForm from '../search-employees/SearchEmployeeForm'

type Fields = {
  employeeIDs: string[]
  searchQuery?: string
  employmentType?: EmploymentType
  departmentID?: string
  salaryCycleID?: string
  onlyMutable: boolean
  createdBefore?: Date
  onlyDraft: boolean
}

export type BatchEmployeeSearchResult = {
  employeeIDs: string[]
}

type Props = {
  company: Company
  employees: List<Employee>
  employeeIDs: string[]
  departments: List<Department>
  salaryCycles: List<SalaryCycle>
}

function EmployeeSearchForm(props: Props & FormComponentProps<Fields, BatchEmployeeSearchResult>): ReactElement | null {
  const getValidEmployees = () => {
    return props.employees.filter((employee) => employee.employmentStatus !== 'Terminated').toArray()
  }

  return (
    <div>
      <Subtitle>{t('employee_batch.search_form.title')}</Subtitle>
      <p>&nbsp;</p>
      <SearchEmployeeForm<Fields>
        company={props.company}
        employees={getValidEmployees()}
        departments={props.departments}
        salaryCycles={props.salaryCycles}
        getFieldValue={props.getFieldValue}
        setFieldValue={props.setFieldValue}
        decorateField={props.decorateField}
        allowOnlyDraft
      />
      <Row>
        <Col span={24}>
          <Button
            htmlType="submit"
            size="extra-extra-large"
            type="primary"
            className="gtm-employee-search-continue"
            tabIndex={5}
            disabled={props.getFieldValue('employeeIDs').length === 0}
          >
            {t('employee_batch.search_form.continue')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, Fields, BatchEmployeeSearchResult>({
  mapPropsToFields: (props) => ({
    employeeIDs: props.employeeIDs,
    onlyMutable: false,
    onlyDraft: false,
  }),
  onSubmit: (values) => ({
    employeeIDs: values.employeeIDs,
  }),
})(EmployeeSearchForm)
