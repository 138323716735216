import { List } from 'immutable'
import React, { ReactElement } from 'react'

import { addAlertSignature, removeAlertSignature } from '../../../actions/alerts'
import Company from '../../../model/company'
import CompanyFeature from '../../../model/companyFeature'
import CostCenter from '../../../model/costCenter'
import Department from '../../../model/department'
import Employee from '../../../model/employee'
import LeaveType from '../../../model/leaveType'
import Project from '../../../model/project'
import { TimeRegistrationCreationFields, TimeRegistrationMutableFields } from '../../../model/timeRegistration'
import TimeRegistrationTemplate, {
  TimeRegistrationTemplateCreationFields,
} from '../../../model/timeRegistrationTemplate'
import { AlertReducer } from '../../../reducers/alerts'
import { EmployeeContractDeltaReducer } from '../../../reducers/employeeContractDeltas'
import { TimeRegistrationReducer } from '../../../reducers/timeRegistrations'
import { TimeRegistrationTemplateReducer } from '../../../reducers/timeRegistrationTemplates'
import WorkHoursForm from './WorkHoursForm'

import './WorkHours.css'

type Props = {
  alerts: AlertReducer
  company: Company
  companyFeatures: List<CompanyFeature>
  employee: Employee
  employeeContractDeltas: EmployeeContractDeltaReducer
  timeRegistrations: TimeRegistrationReducer
  timeRegistrationTemplates: TimeRegistrationTemplateReducer
  projects: List<Project>
  costCenters: List<CostCenter>
  departments: List<Department>
  leaveTypes: List<LeaveType>
  canEditObjects: boolean

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  createTimeRegistration: (registration: TimeRegistrationCreationFields) => void
  updateTimeRegistration: (registration: TimeRegistrationMutableFields) => void
  deleteTimeRegistration: (id: string) => void
  getTimeRegistrationTemplates: (companyID: string) => void
  saveTimeRegistrationTemplates: (
    template: TimeRegistrationTemplateCreationFields
  ) => Promise<TimeRegistrationTemplate | void>
  deleteTimeRegistrationTemplate: (
    companyID: string | undefined,
    employeeID: string | undefined
  ) => Promise<boolean | void>
}

export default function WorkHoursRegistrationTab(props: Props): ReactElement | null {
  return (
    <WorkHoursForm
      templateMode={false}
      alerts={props.alerts}
      company={props.company}
      companyFeatures={props.companyFeatures}
      employee={props.employee}
      employeeContractDeltas={props.employeeContractDeltas}
      timeRegistrations={props.timeRegistrations}
      timeRegistrationTemplates={props.timeRegistrationTemplates}
      projects={props.projects}
      costCenters={props.costCenters}
      departments={props.departments}
      leaveTypes={props.leaveTypes}
      canEditObjects={props.canEditObjects}
      addAlert={props.addAlert}
      removeAlert={props.removeAlert}
      createTimeRegistration={props.createTimeRegistration}
      updateTimeRegistration={props.updateTimeRegistration}
      deleteTimeRegistration={props.deleteTimeRegistration}
      getTimeRegistrationTemplates={props.getTimeRegistrationTemplates}
      saveTimeRegistrationTemplates={props.saveTimeRegistrationTemplates}
      deleteTimeRegistrationTemplate={props.deleteTimeRegistrationTemplate}
    />
  )
}
