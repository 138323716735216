import React, { ReactElement } from 'react'

import { DocumentCategoryReducer } from '../../reducers/documentCategories'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  documentCategoryID?: string
  documentCategories: DocumentCategoryReducer
}

type Fields = {
  name?: string
}

export type DocumentCategoryResult = {
  name: string
}

function DocumentCategoryEditForm(
  props: Props & FormComponentProps<Fields, DocumentCategoryResult>
): ReactElement | null {
  const { decorateField } = props

  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={24}>
          {decorateField('name', {
            placeholder: t('document_category.edit.form.name'),
            validate: (val) => (!val ? t('document_category.edit.form.name.required') : null),
          })(<Input style={{ width: '100%' }} />)}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="primary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      {props.documentCategories.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, DocumentCategoryResult>({
  mapPropsToFields: (props) => {
    const fields: Fields = {}
    const documentCategory = props.documentCategories.documentCategories.find(
      (documentCategory) => documentCategory.id === props.documentCategoryID
    )
    if (documentCategory) {
      fields.name = documentCategory.name
    }
    return fields
  },
  onSubmit: (values) => {
    return {
      name: values.name!,
    }
  },
})(DocumentCategoryEditForm)
