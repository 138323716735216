import React, { ReactElement, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { usePrevious } from 'react-use'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import { fetchCompanyAccountingIntegrationDaybooks } from '../../api/accounting-integration'
import { ExternalDaybook } from '../../api/company-accounting-integration-setup'
import paths from '../../constants/paths'
import Company from '../../model/company'
import { AlertReducer } from '../../reducers/alerts'
import { CompanyAccountingIntegrationReducer } from '../../reducers/companyAccountingIntegration'
import { formatError, isRequestError } from '../../utils/error-utils'
import { t } from '../../utils/translation-utils'
import DaybookForm, { DaybookFields } from '../company-accounting-integration/DaybookForm'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import Title from '../elements/Title'
import TitleMenu from '../elements/TitleMenu'
import Alerts from '../widgets/Alerts'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  alerts: AlertReducer
  company: Company
  companyAccountingIntegration: CompanyAccountingIntegrationReducer

  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
  selectCompanyAccountingDaybook: (daybookID?: string) => void
}

export default function AccountingDaybooks(props: Props): ReactElement | null {
  const [daybooks, setDaybooks] = useState<ExternalDaybook[]>([])
  const [error, setError] = useState<Error | null>(null)
  const [loading, setLoading] = useState(true)

  const { company } = props

  useEffect(() => {
    if (daybooks.length === 0) {
      fetchCompanyAccountingIntegrationDaybooks(company.id)
        .then((res) => {
          setDaybooks(res.data)
          setLoading(false)
        })
        .catch((e) => {
          if (isRequestError(e)) {
            setError(e)
          }
          setLoading(false)
        })
    }
  }, [daybooks, company])

  const { companyAccountingIntegration, addAlert } = props
  const previousCompanyAccountingIntegration = usePrevious(companyAccountingIntegration)

  useEffect(() => {
    if (
      previousCompanyAccountingIntegration &&
      previousCompanyAccountingIntegration.saving &&
      !companyAccountingIntegration.saving
    ) {
      if (!companyAccountingIntegration.error) {
        addAlert('success', t('accounting_tab.daybooks.alert.success'), { timeout: 5 })
        jsBrowserHistory.push('/' + paths.INTEGRATIONS + '/' + paths.ACCOUNTING)
      }
    }
  }, [previousCompanyAccountingIntegration, companyAccountingIntegration, addAlert])

  const handleSubmit = (values: DaybookFields) => {
    props.selectCompanyAccountingDaybook(values.daybookID)
  }

  if (loading || companyAccountingIntegration.saving) {
    return <LoadingOverlay />
  }

  const accountingIntegration = companyAccountingIntegration.accountingIntegration

  return (
    <Card>
      <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />
      <TitleMenu>
        <Link to={'/' + paths.INTEGRATIONS + '/' + paths.ACCOUNTING}>
          <Button>{t('accounting_tab.daybooks.back')}</Button>
        </Link>
      </TitleMenu>
      <Title>{t('accounting_tab.daybooks.title')}</Title>
      {error && <Alert message={formatError(error)} type="error" showIcon />}
      {!accountingIntegration && <p>{t('accounting_tab.daybooks.no_integration')}</p>}
      {accountingIntegration && !accountingIntegration.hasDaybooks && <p>{t('accounting_tab.daybooks.no_daybooks')}</p>}
      {accountingIntegration && accountingIntegration.hasDaybooks && daybooks.length > 0 && (
        <div>
          <DaybookForm
            editing={true}
            integrationType={accountingIntegration.integrationType}
            daybooks={daybooks}
            daybookID={accountingIntegration.daybookID}
            allowNoDaybook={accountingIntegration.allowNoDaybook}
            onSubmit={handleSubmit}
          />
        </div>
      )}
    </Card>
  )
}
