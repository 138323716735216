import { List } from 'immutable'
import React, { ReactElement } from 'react'

import Company from '../../model/company'
import Department from '../../model/department'
import { DepartmentPermission, DepartmentUserMutableFields } from '../../model/departmentUser'
import { UserInviteReducer } from '../../reducers/userInvites'
import UserTypes from '../../types/user-company-type'
import UserCompanyType from '../../types/user-company-type'
import { validateEmail } from '../../utils/email-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { formatUserPermission, formatUserType } from '../../utils/format-utils'
import { setByPath } from '../../utils/object-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Checkbox from '../elements/checkbox'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'
import Select from '../elements/select'
import Subcard from '../elements/Subcard'
import Subtitle from '../elements/Subtitle'
import Switch from '../elements/switch'
import PhoneNumberForm from '../form-elements/PhoneNumberForm'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  company: Company
  departments: List<Department>
  userInvites: UserInviteReducer
}

type DepartmentRow = {
  departmentID: string
  hasAccess: boolean
  seeEmployment: boolean
  canWrite: boolean
}

type Fields = {
  userType?: UserCompanyType
  email?: string
  permissionAdmin: boolean
  permissionApprovePayRoll: boolean
  permissionReviewPayRoll: boolean
  departmentPermissionAdmin: boolean
  departments: DepartmentRow[]
  name?: string
  phoneNumberCountryCode?: string
  phoneNumber?: string
}

export type ResultFields = {
  userType: UserCompanyType
  email: string
  permissionAdmin: boolean
  permissionApprovePayRoll: boolean
  permissionReviewPayRoll: boolean
  departments: DepartmentUserMutableFields[]
  name?: string
  phoneNumberCountryCode?: string
  phoneNumber?: string
}

function CompanyUsersAddForm(props: Props & FormComponentProps<Fields, ResultFields>): ReactElement | null {
  const { decorateField, decorateAnyField, getFieldValue, getAnyFieldValue, getFieldError } = props

  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={12}>
          {decorateField('userType', {
            title: t('company_users.add.form.user_type'),
            placeholder: t('company_users.add.form.user_type.placeholder'),
            validate: (val) => (!val ? t('company_users.add.form.user_type.required') : null),
          })(
            <Select dropdownMatchSelectWidth={false} tabIndex={1}>
              {/* <Select.Option value={UserTypes.BUSINESS}>
                    {formatUserType(UserTypes.BUSINESS)}
                  </Select.Option> */}
              <Select.Option value={UserTypes.ACCOUNTANT}>{formatUserType(UserTypes.ACCOUNTANT)}</Select.Option>
              <Select.Option value={UserTypes.BOOK_KEEPER}>{formatUserType(UserTypes.BOOK_KEEPER)}</Select.Option>
              <Select.Option value={UserTypes.INTERNAL_SALARY_ADMINISTRATOR}>
                {formatUserType(UserTypes.INTERNAL_SALARY_ADMINISTRATOR)}
              </Select.Option>
              <Select.Option value={UserTypes.EXTERNAL_SALARY_ADMINISTRATOR}>
                {formatUserType(UserTypes.EXTERNAL_SALARY_ADMINISTRATOR)}
              </Select.Option>
              {props.departments.size > 0 && (
                <Select.Option value={UserTypes.DEPARTMENT_MANAGER}>
                  {formatUserType(UserTypes.DEPARTMENT_MANAGER)}
                </Select.Option>
              )}
              {props.company.settingsEnabled.some((setting) => setting === 'AllowApproveOnlyUser') && (
                <Select.Option value={UserTypes.APPROVE_ONLY}>{formatUserType(UserTypes.APPROVE_ONLY)}</Select.Option>
              )}
              <Select.Option value={UserTypes.READ_ONLY}>{formatUserType(UserTypes.READ_ONLY)}</Select.Option>
            </Select>
          )}
        </Col>
        <Col span={12}>
          {decorateField('email', {
            placeholder: t('company_users.add.form.email'),
            validate: (val) => {
              if (!val) {
                return t('company_users.add.form.email.required')
              }
              if (!validateEmail(val)) {
                return t('company_users.add.form.email.invalid')
              }
              return null
            },
          })(<Input tabIndex={2} />)}
        </Col>
      </Row>
      {getFieldValue('userType') !== UserTypes.DEPARTMENT_MANAGER &&
        getFieldValue('userType') !== UserTypes.APPROVE_ONLY &&
        getFieldValue('userType') !== UserTypes.READ_ONLY && (
          <Subcard>
            <Subtitle>{t('company_users.add.form.permissions.title')}</Subtitle>
            <p>{t('company_users.add.form.permissions.intro')}</p>
            <Row>
              <Col span={24}>
                <div className="ant-switch-wrapper">
                  {decorateField('permissionAdmin', {
                    skipWrapper: true,
                    skipLabel: true,
                    valueOnChecked: true,
                    noBlur: true,
                  })(<Switch />)}
                  <span className="ant-switch-text">{formatUserPermission('Admin')}</span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className="ant-switch-wrapper">
                  {decorateField('permissionApprovePayRoll', {
                    skipWrapper: true,
                    skipLabel: true,
                    valueOnChecked: true,
                    noBlur: true,
                  })(<Switch />)}
                  <span className="ant-switch-text">{formatUserPermission('ApprovePayRoll')}</span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className="ant-switch-wrapper">
                  {decorateField('permissionReviewPayRoll', {
                    skipWrapper: true,
                    skipLabel: true,
                    valueOnChecked: true,
                    noBlur: true,
                  })(<Switch />)}
                  <span className="ant-switch-text">{formatUserPermission('ReviewPayRoll')}</span>
                </div>
              </Col>
            </Row>
          </Subcard>
        )}
      {getFieldValue('userType') === UserTypes.DEPARTMENT_MANAGER && (
        <Subcard>
          <Subtitle>{t('company_users.add.form.departments.title')}</Subtitle>
          <p>{t('company_users.add.form.departments.intro')}</p>
          <Row>
            {props.departments
              .filter((department) => department.active)
              .map((department, i) => {
                return (
                  <Col key={department.id} span={12} className="ant-department-col">
                    <div>
                      <Row className="ant-department-col-department">
                        <Col span={24}>
                          {decorateAnyField(`departments.${i}.hasAccess`, {
                            skipWrapper: true,
                            skipLabel: true,
                            valueOnChecked: true,
                          })(
                            <Checkbox>
                              <strong>{department.name}</strong>
                              <br />
                              <span>{t('company_users.edit_departments.form.has_access')}</span>
                            </Checkbox>
                          )}
                          <Row className="ant-department-col-permissions">
                            <Col span={24}>
                              {decorateAnyField(`departments.${i}.seeEmployment`, {
                                skipWrapper: true,
                                skipLabel: true,
                                valueOnChecked: true,
                              })(
                                <Checkbox disabled={!getAnyFieldValue(`departments.${i}.hasAccess`)}>
                                  {t('company_users.edit_departments.form.see_employment')}
                                </Checkbox>
                              )}
                            </Col>
                            <Col span={24}>
                              {decorateAnyField(`departments.${i}.canWrite`, {
                                skipWrapper: true,
                                skipLabel: true,
                                valueOnChecked: true,
                              })(
                                <Checkbox disabled={!getAnyFieldValue(`departments.${i}.hasAccess`)}>
                                  {getAnyFieldValue(`departments.${i}.seeEmployment`)
                                    ? t('company_users.edit_departments.form.can_write.with_see_employment')
                                    : t('company_users.edit_departments.form.can_write.without_see_employment')}
                                </Checkbox>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                )
              })}
          </Row>
        </Subcard>
      )}
      {getFieldValue('userType') === UserTypes.APPROVE_ONLY && (
        <Row>
          <Col span={12}>
            {decorateField('name', {
              placeholder: t('company_users.add.form.name'),
              validate: (val) => {
                if (!val) {
                  return t('company_users.add.form.name.required')
                }
                return null
              },
            })(<Input tabIndex={3} />)}
          </Col>
          <Col span={12}>
            <PhoneNumberForm
              decorateField={decorateField}
              getFieldValue={getFieldValue}
              getFieldError={getFieldError}
              tabIndex={4}
              restrictPhonePrefixes
              requirePhoneNumber
            />
          </Col>
        </Row>
      )}
      {getFieldValue('userType') === UserCompanyType.READ_ONLY && (
        <Row>
          <Col span={24}>
            <p>{t('company_users.add.form.read_only_note')}</p>
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="primary" tabIndex={10}>
            {getFieldValue('userType') === UserTypes.APPROVE_ONLY
              ? t('company_users.add.form.submit.approve_only')
              : t('company_users.add.form.submit.regular')}
          </Button>
        </Col>
      </Row>
      {props.userInvites.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, ResultFields>({
  mapPropsToFields: (props) => {
    return {
      permissionAdmin: false,
      permissionApprovePayRoll: false,
      permissionReviewPayRoll: false,
      departmentPermissionAdmin: true,
      departments: props.departments
        .filter((department) => department.active)
        .toArray()
        .map((department) => ({
          departmentID: department.id,
          hasAccess: false,
          seeEmployment: false,
          canWrite: false,
        })),
      phoneNumberCountryCode: '45',
    }
  },
  onChange: (key, val) => {
    const values = {}
    if (val && key.match(/.+\.seeEmployment$/)) {
      const i = key.replace(/departments.([0-9]).seeEmployment/, '$1')
      setByPath(values, `departments.${i}.canWrite`, false)
    }
    if (!val && key.match(/.+\.hasAccess$/)) {
      const i = key.replace(/departments.([0-9]).hasAccess/, '$1')
      setByPath(values, `departments.${i}.seeEmployment`, false)
      setByPath(values, `departments.${i}.canWrite`, false)
    }
    setByPath(values, key, val)
    return values
  },
  onSubmit: (values) => {
    const departments: DepartmentUserMutableFields[] = []
    values.departments.forEach((department) => {
      if (department.hasAccess) {
        const permissions: DepartmentPermission[] = ['ApproveObjects']
        if (department.seeEmployment) {
          permissions.push('SeeSalaryRates')
        }
        if (department.canWrite) {
          permissions.push('EditObjects')
        }
        if (department.seeEmployment && department.canWrite) {
          permissions.push('HireFire')
        }
        departments.push({ departmentID: department.departmentID, permissions })
      }
    })
    return {
      userType: values.userType!,
      email: values.email!,
      permissionAdmin: values.permissionAdmin,
      permissionApprovePayRoll: values.permissionApprovePayRoll,
      permissionReviewPayRoll: values.permissionReviewPayRoll,
      departments,
      name: values.userType === UserTypes.APPROVE_ONLY ? values.name : undefined,
      phoneNumberCountryCode: values.userType === UserTypes.APPROVE_ONLY ? values.phoneNumberCountryCode : undefined,
      phoneNumber: values.userType === UserTypes.APPROVE_ONLY ? values.phoneNumber : undefined,
    }
  },
})(CompanyUsersAddForm)
