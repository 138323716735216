import React, { ReactElement } from 'react'

import { UserReducer } from '../../reducers/user'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { t } from '../../utils/translation-utils'
import Form from '../antd/form'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  user: UserReducer
}

type Fields = {
  oldPassword?: string
  newPassword?: string
  repeatNewPassword?: string
}

export type PasswordResult = {
  oldPassword: string
  newPassword: string
}

function PasswordForm(props: Props & FormComponentProps<Fields, PasswordResult>): ReactElement | null {
  const { decorateField, getFieldValue, getFormError, getFieldError } = props

  return (
    <div>
      {getFormError()}
      <Row>
        <Col span={12}>
          {decorateField('oldPassword', {
            placeholder: t('form.field.current_password'),
            validate: (val) => {
              if (!val) {
                return t('form.validation.current_password_is_required')
              }
              if (val.length < 8) {
                return t('form.validation.current_password_is_invalid')
              }
              return null
            },
          })(<Input type="password" />)}
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item
            validateStatus={getFieldError('newPassword') || getFieldError('repeatNewPassword') ? 'error' : 'success'}
          >
            <label htmlFor="newPassword" title={t('form.field.new_password')}>
              {getFieldError('newPassword') || getFieldError('repeatNewPassword') || t('form.field.new_password')}
            </label>
            {decorateField('newPassword', {
              placeholder: t('form.field.new_password'),
              validate: (val) => {
                if (!val) {
                  return t('form.validation.new_password_is_required')
                }
                if (val.length < 8) {
                  return t('form.validation.new_password_is_invalid')
                }
                if (getFieldValue('repeatNewPassword') && getFieldValue('repeatNewPassword') !== val) {
                  return t('form.validation.new_passwords_doesnt_match')
                }
                return null
              },
              skipWrapper: true,
              skipLabel: true,
            })(<Input type="password" />)}
          </Form.Item>
        </Col>
        <Col span={12} style={{ clear: 'left' }}>
          <Form.Item
            validateStatus={getFieldError('newPassword') || getFieldError('repeatNewPassword') ? 'error' : 'success'}
          >
            {decorateField('repeatNewPassword', {
              placeholder: t('form.field.repeat_password'),
              validate: (val) => {
                if (!val) {
                  return t('form.validation.repeated_password_is_required')
                }
                if (getFieldValue('newPassword') && getFieldValue('newPassword') !== val) {
                  return t('form.validation.new_passwords_doesnt_match')
                }
                return null
              },
              skipWrapper: true,
              skipLabel: true,
            })(<Input type="password" />)}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="primary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      {props.user.changingPassword && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, PasswordResult>({
  onSubmit: (values) => ({
    oldPassword: values.oldPassword!,
    newPassword: values.newPassword!,
  }),
})(PasswordForm)
