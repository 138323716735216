import { isSameDay } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement, useState } from 'react'

import Company from '../../../model/company'
import Employee from '../../../model/employee'
import LeaveType, { LeaveSubTypeName } from '../../../model/leaveType'
import { TimeRegistrationClass } from '../../../model/timeRegistration'
import { DateFormat, DateTimeFormat } from '../../../model/types'
import { TimeRegistrationReducer } from '../../../reducers/timeRegistrations'
import LeaveDuration from '../../../types/leave-duration'
import RemunerationType from '../../../types/remuneration-type'
import { formatAPIDate, formatDateTime, getDate } from '../../../utils/date-utils'
import { FormComponentProps, withValidations } from '../../../utils/form-utils'
import { formatLeaveDuration, formatLeaveSubType, formatLeaveTypeName } from '../../../utils/format-utils'
import { forceParseInputNumber, formatInputNumber } from '../../../utils/number-utils'
import { t, tx } from '../../../utils/translation-utils'
import Button from '../../elements/button'
import DatePicker from '../../elements/date-picker'
import Col from '../../elements/grid/col'
import Row from '../../elements/grid/row'
import HelpModal from '../../elements/HelpModal'
import Input from '../../elements/input'
import Select from '../../elements/select'
import SwitchWrapper from '../../form-elements/SwitchWrapper'
import LoadingOverlay from '../../widgets/LoadingOverlay'

type Props = {
  employee: Employee
  timeRegistrationID?: string
  period?: DateFormat[]
  timeRegistrations: TimeRegistrationReducer
  company: Company
  leaveTypes: List<LeaveType>
  remunerationType?: RemunerationType
}

type Fields = {
  class: TimeRegistrationClass
  date: Date
  period: [Date, Date | null]
  leaveDuration: LeaveDuration
  days?: string
  hours?: string
  leaveTypeID?: string
  leaveSubTypeID?: '' | LeaveSubTypeName
  note?: string
  createdAt?: DateTimeFormat
  noRateAdjustment: boolean
}

export type Result = {
  class: TimeRegistrationClass
  date: DateFormat
  startDate: DateFormat
  endDate: DateFormat
  days: number
  leaveTypeID: string
  leaveSubTypeID?: LeaveSubTypeName
  note?: string
  noRateAdjustment: boolean
}

function LeaveEditForm(props: Props & FormComponentProps<Fields, Result>): ReactElement | null {
  const { getFieldValue, decorateField } = props

  const [pickerOpen, setPickerOpen] = useState(false)

  const isEditing = !!props.timeRegistrationID
  const contract = props.employee.activeContract

  if (!contract) {
    return null
  }

  let dayOffID = null
  let sickDayID = null
  props.leaveTypes.forEach((leaveType) => {
    if (leaveType.name === 'DenmarkDayOff') {
      dayOffID = leaveType.id
    }
    if (leaveType.name === 'DenmarkSickDayPaid') {
      sickDayID = leaveType.id
    }
  })

  const leaveTypeID = getFieldValue('leaveTypeID')
  const leaveType = props.leaveTypes.find((type) => type.id === leaveTypeID)
  const leaveSubTypes = leaveType?.leaveSubTypes.filter((subType) => subType.employeeSelectable) || []

  const canEditLeaveDuration =
    isEditing ||
    (!pickerOpen && isSameDay(getFieldValue('period')[0], getFieldValue('period')[1] || getFieldValue('period')[0]))

  const leaveTypesWithAutomaticRateAdjustment = (
    ['DenmarkVacationAccrual', 'DenmarkVacationFund', 'DenmarkVacationFundWithPension'] as const
  ).map((n) => props.leaveTypes.find((lt) => lt.name === n)?.id)

  let leaveTypeFound = !leaveTypeID
  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={24}>
          {decorateField('leaveTypeID', {
            placeholder: t('leave.tab.edit.form.leave_type_id'),
            validate: (val) => (!val ? t('leave.tab.edit.form.leave_type_id.required') : null),
          })(
            <Select dropdownMatchSelectWidth={false} tabIndex={1}>
              {contract.remuneration &&
                contract.remuneration.leave
                  .filter((leave) => leave.type?.class !== 'Flex' && leave.type?.class !== 'Overtime')
                  .map((leave) => {
                    const leaveType = leave.type!
                    if (leaveType.id === leaveTypeID) {
                      leaveTypeFound = true
                    }
                    return (
                      <Select.Option key={leaveType.id} value={leaveType.id}>
                        {formatLeaveTypeName(leaveType.name, false, props.remunerationType)}
                      </Select.Option>
                    )
                  })}
              {props.leaveTypes
                .filter(
                  (leave) => !leave.assignable && leave.name !== 'DenmarkFlexTime' && leave.name !== 'DenmarkOvertime'
                )
                .map((leave) => {
                  if (leave.id === leaveTypeID) {
                    leaveTypeFound = true
                  }
                  return (
                    <Select.Option key={leave.id} value={leave.id}>
                      {formatLeaveTypeName(leave.name, false, props.remunerationType)}
                    </Select.Option>
                  )
                })}
              {!leaveTypeFound &&
                props.leaveTypes
                  .filter(
                    (leave) =>
                      leave.id === leaveTypeID && leave.name !== 'DenmarkFlexTime' && leave.name !== 'DenmarkOvertime'
                  )
                  .map((leave) => {
                    return (
                      <Select.Option key={leave.id} value={leave.id}>
                        {formatLeaveTypeName(leave.name, false, props.remunerationType)}
                      </Select.Option>
                    )
                  })}
            </Select>
          )}
        </Col>
      </Row>
      {leaveSubTypes.length > 0 && (
        <Row>
          <Col span={24}>
            {decorateField('leaveSubTypeID', {
              placeholder:
                leaveTypeID === dayOffID
                  ? t('leave.tab.edit.form.leave_sub_type_id.day_off')
                  : leaveTypeID === sickDayID
                  ? t('leave.tab.edit.form.leave_sub_type_id.sick_day')
                  : t('leave.tab.edit.form.leave_sub_type_id.other'),
            })(
              <Select dropdownMatchSelectWidth={false} tabIndex={2}>
                <Select.Option key={''} value={''}>
                  {t('leave.tab.edit.form.leave_sub_type_id.none')}
                </Select.Option>
                {leaveSubTypes.map((leaveSubType) => {
                  return (
                    <Select.Option key={leaveSubType.id} value={leaveSubType.id}>
                      {formatLeaveSubType(leaveSubType.name)}
                    </Select.Option>
                  )
                })}
              </Select>
            )}
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          {isEditing
            ? decorateField('date', {
                placeholder: t('leave.tab.edit.form.date'),
                validate: (val) => {
                  if (!val) {
                    return t('leave.tab.edit.form.date.required')
                  }
                  return null
                },
              })(<DatePicker allowClear={false} tabIndex={3} style={{ width: '100%' }} />)
            : decorateField('period', {
                title: t('leave.tab.edit.form.period'),
                validate: (val) => {
                  if (!val || val.length < 2 || val[1] === null) {
                    return t('leave.tab.edit.form.period.required')
                  }
                  return null
                },
              })(
                <DatePicker.RangePicker
                  allowClear={false}
                  tabIndex={3}
                  style={{ width: '100%' }}
                  showTwoMonths
                  onStateChange={(open) => setPickerOpen(open)}
                />
              )}
        </Col>
      </Row>
      {canEditLeaveDuration && (
        <Row>
          <Col span={24}>
            {decorateField('leaveDuration', {
              placeholder: t('leave.tab.edit.form.leave_duration'),
              validate: (val) => (!val ? t('leave.tab.edit.form.leave_duration.required') : null),
            })(
              <Select dropdownMatchSelectWidth={false} tabIndex={4}>
                <Select.Option key={LeaveDuration.FULL_DAY} value={LeaveDuration.FULL_DAY}>
                  {formatLeaveDuration(LeaveDuration.FULL_DAY)}
                </Select.Option>
                <Select.Option key={LeaveDuration.THREE_QUARTERS_DAY} value={LeaveDuration.THREE_QUARTERS_DAY}>
                  {formatLeaveDuration(LeaveDuration.THREE_QUARTERS_DAY)}
                </Select.Option>
                <Select.Option key={LeaveDuration.HALF_DAY} value={LeaveDuration.HALF_DAY}>
                  {formatLeaveDuration(LeaveDuration.HALF_DAY)}
                </Select.Option>
                <Select.Option key={LeaveDuration.QUARTER_DAY} value={LeaveDuration.QUARTER_DAY}>
                  {formatLeaveDuration(LeaveDuration.QUARTER_DAY)}
                </Select.Option>
                <Select.Option key={LeaveDuration.OTHER} value={LeaveDuration.OTHER}>
                  {formatLeaveDuration(LeaveDuration.OTHER)}
                </Select.Option>
              </Select>
            )}
          </Col>
          {getFieldValue('leaveDuration') === LeaveDuration.OTHER && (
            <>
              <Col span={24}>
                {decorateField('days', {
                  placeholder: t('leave.tab.edit.form.days'),
                  validate: (val) => {
                    if (!val) {
                      return t('leave.tab.edit.form.days.required')
                    }
                    if (!val.match(/^[0-9,.]+$/)) {
                      return t('leave.tab.edit.form.days.invalid')
                    }
                    if (forceParseInputNumber(val) > 5) {
                      return t('leave.tab.edit.form.days.max_5')
                    }
                    return null
                  },
                })(<Input />)}
                {forceParseInputNumber(getFieldValue('days')) > 1 && (
                  <p>{t('leave.tab.edit.form.days.above_1_note')}</p>
                )}
              </Col>
              {props.company.settingsEnabled.includes('UseAdjustedWorkWeekLeaveRate') &&
                leaveTypesWithAutomaticRateAdjustment.includes(getFieldValue('leaveTypeID')) && (
                  <SwitchWrapper id="noRateAdjustment" decorateField={decorateField}>
                    {tx('leave.tab.edit.form.no_rate_adjustment', {
                      not: <strong>{t('leave.tab.edit.form.no_rate_adjustment.not')}</strong>,
                    })}
                    <HelpModal>
                      <p>{t('leave.tab.edit.form.no_rate_adjustment.help.line_1')}</p>
                      <p>{t('leave.tab.edit.form.no_rate_adjustment.help.line_2')}</p>
                    </HelpModal>
                  </SwitchWrapper>
                )}
            </>
          )}
        </Row>
      )}
      <Row>
        <Col span={24}>
          {decorateField('note', {
            placeholder: t('leave.tab.edit.form.note'),
          })(<Input />)}
        </Col>
      </Row>
      {props.timeRegistrationID && (
        <Row>
          <Col span={24} className="created-at-note">
            {t('leave.tab.edit.form.created_at', {
              createdAt: formatDateTime(getDate(props.getFieldValue('createdAt'))),
            })}
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="primary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      {props.timeRegistrations.saving && <LoadingOverlay />}
    </div>
  )
}

export default withValidations<Props, Fields, Result>({
  mapPropsToFields: (props) => {
    const fields: Fields = {
      class: 'Leave',
      date: getDate(),
      period: [getDate(), getDate()],
      leaveDuration: LeaveDuration.FULL_DAY,
      hours: undefined,
      noRateAdjustment: false,
    }
    if (props.period && props.period.length === 2) {
      fields.period = [getDate(props.period[0]), getDate(props.period[1])]
    }
    props.timeRegistrations.timeRegistrations.forEach((timeRegistration) => {
      if (props.timeRegistrationID === timeRegistration.id) {
        fields.class = timeRegistration.class
        fields.date = getDate(timeRegistration.date)
        fields.leaveTypeID = timeRegistration.leaveTypeID
        fields.leaveSubTypeID = timeRegistration.leaveSubTypeID
        fields.note = timeRegistration.note
        fields.createdAt = timeRegistration.createdAt
        switch (timeRegistration.days) {
          case 1:
            fields.leaveDuration = LeaveDuration.FULL_DAY
            break
          case 0.75:
            fields.leaveDuration = LeaveDuration.THREE_QUARTERS_DAY
            break
          case 0.5:
            fields.leaveDuration = LeaveDuration.HALF_DAY
            break
          case 0.25:
            fields.leaveDuration = LeaveDuration.QUARTER_DAY
            break
          default:
            fields.leaveDuration = LeaveDuration.OTHER
            fields.days = formatInputNumber(timeRegistration.days)
            break
        }
      }
    })
    return fields
  },
  onChange: (key, val) => {
    const values: Partial<Fields> = { [key]: val } as Partial<Fields>
    if (key === 'leaveDuration' && val !== LeaveDuration.OTHER) {
      values['noRateAdjustment'] = false
    }
    return values
  },
  onSubmit: (values) => {
    const result: Result = {
      class: values.class,
      date: formatAPIDate(values.date),
      days: 1,
      leaveTypeID: values.leaveTypeID!,
      leaveSubTypeID: values.leaveSubTypeID ? values.leaveSubTypeID : undefined,
      note: values.note,
      startDate: formatAPIDate(values.period[0]),
      endDate: formatAPIDate(values.period[1]!),
      noRateAdjustment: values.noRateAdjustment,
    }
    switch (values.leaveDuration) {
      case LeaveDuration.FULL_DAY:
        result.days = 1
        break
      case LeaveDuration.THREE_QUARTERS_DAY:
        result.days = 0.75
        break
      case LeaveDuration.HALF_DAY:
        result.days = 0.5
        break
      case LeaveDuration.QUARTER_DAY:
        result.days = 0.25
        break
      default:
        result.days = forceParseInputNumber(values.days)
        break
    }
    return result
  },
})(LeaveEditForm)
