import { List } from 'immutable'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router'
import { useEffectOnce, usePrevious } from 'react-use'

import { addAlertSignature, removeAlertSignature } from '../../actions/alerts'
import {
  AuthParameters,
  CompanyAccountingIntegrationSetup,
  CompanyAccountingIntegrationSetupStatus,
  CompanyAccountingIntegrationStep,
  CompanyAccountingIntegrationType,
  patchCompanyAccountingIntegrationSetup,
  postCompanyAccountingIntegrationSetup,
} from '../../api/company-accounting-integration-setup'
import paths from '../../constants/paths'
import AccountingIntegration from '../../model/accountingIntegration'
import AvailableAccountingIntegration from '../../model/availableAccountingIntegration'
import Company from '../../model/company'
import { AlertReducer } from '../../reducers/alerts'
import { CompanyAccountingIntegrationReducer } from '../../reducers/companyAccountingIntegration'
import { regularComponentDidUpdate } from '../../utils/component-utils'
import { formatError, isRequestError } from '../../utils/error-utils'
import { formatAccountingIntegration } from '../../utils/format-utils'
import { companySelected } from '../../utils/hooks-utils'
import { t, tx } from '../../utils/translation-utils'
import Alert from '../elements/alert'
import Button from '../elements/button'
import Card from '../elements/card'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Steps from '../elements/steps'
import Subtitle from '../elements/Subtitle'
import Alerts from '../widgets/Alerts'
import jsBrowserHistory from '../widgets/jsBrowserHistory'
import LoadingOverlay from '../widgets/LoadingOverlay'
import AccountMappingStep, { AccountMappingFields } from './AccountMappingStep'
import ConnectionForm, { ConnectionResult } from './ConnectionForm'
import DaybookForm, { DaybookFields } from './DaybookForm'
import IntegrationTypeForm, { IntegrationTypeFields } from './IntegrationTypeForm'
import OrganizationForm, { OrganizationFields } from './OrganizationForm'

import './CompanyAccountingIntegration.css'

type IntegrationFormStep = CompanyAccountingIntegrationStep | 'SelectIntegration'

function resolvePreviousStep(
  availableSteps: IntegrationFormStep[],
  currentStep: IntegrationFormStep
): IntegrationFormStep {
  for (let i = 0; i < availableSteps.length; i++) {
    if (availableSteps[i] === currentStep) {
      if (i > 0) {
        return availableSteps[i - 1]
      } else {
        return availableSteps[0]
      }
    }
  }
  return availableSteps[0]
}

function resolveStep(availableSteps: IntegrationFormStep[], currentStep: IntegrationFormStep): number {
  for (let i = 0; i < availableSteps.length; i++) {
    if (availableSteps[i] === currentStep) {
      return i
    }
  }
  return 0
}

type Props = {
  query?: Record<string, string>
  alerts: AlertReducer
  company: Company
  availableAccountingIntegrations: List<AvailableAccountingIntegration>
  companyAccountingIntegration: CompanyAccountingIntegrationReducer

  updateCompanyAccountingIntegration: (state: string) => Promise<AccountingIntegration | void>
  getCompanyAccountPlans: (companyID: string) => void
  addAlert: addAlertSignature
  removeAlert: removeAlertSignature
}

export default function CompanyAccountingIntegration(props: Props): ReactElement | null {
  type State = {
    state?: string
    integrationType: CompanyAccountingIntegrationType
    saving: boolean
    current: CompanyAccountingIntegrationStep | 'SelectIntegration'
    availableSteps: (CompanyAccountingIntegrationStep | 'SelectIntegration')[]
    displayName?: string
    organizationID?: string
    daybookID?: string
    authParameters?: AuthParameters
  } & Omit<CompanyAccountingIntegrationSetupStatus, 'next' | 'steps' | 'state' | 'type' | 'authURL'>
  const [state, setState] = useState<State>(() => {
    let queryState = undefined
    if (props.query) {
      queryState = props.query.state
    }
    return {
      state: queryState,
      integrationType: 'None',
      saving: !!queryState,
      current: queryState ? 'NeedAuth' : 'SelectIntegration',
      // per default, we show all the steps, so when they are about to start their integration, they can see all the
      // potential steps, but it becomes limited to the ones that are available, once they've chosen the integration
      availableSteps: ['SelectIntegration', 'NeedKey', 'ChooseOrganization', 'ChooseDaybook', 'MapAccounts'],
    }
  })
  const [error, setError] = useState<Error | null>(null)
  const [apiError, setAPIError] = useState<Error | null>(null)

  const { companyAccountingIntegration, addAlert } = props
  const previousCompanyAccountingIntegration = usePrevious(companyAccountingIntegration)

  useEffect(() => {
    if (
      previousCompanyAccountingIntegration &&
      previousCompanyAccountingIntegration.saving &&
      !companyAccountingIntegration.saving
    ) {
      if (!companyAccountingIntegration.error) {
        addAlert('success', t('accounting_integration.alert.success'), { timeout: 5 })

        jsBrowserHistory.push('/' + paths.INTEGRATIONS + '/' + paths.ACCOUNTING)
      }
    }
  }, [previousCompanyAccountingIntegration, companyAccountingIntegration, addAlert])

  useEffect(() => {
    regularComponentDidUpdate(companyAccountingIntegration.error, error, setError)
  }, [companyAccountingIntegration, error])

  const { availableAccountingIntegrations } = props
  const getDisplayName = useCallback(
    (type: CompanyAccountingIntegrationType): string => {
      const integration = availableAccountingIntegrations.find((integration) => integration.type === type)
      if (integration) {
        return integration.displayName
      }
      return formatAccountingIntegration(type)
    },
    [availableAccountingIntegrations]
  )

  const _handleError = (e: Error) => {
    if (isRequestError(e)) {
      setAPIError(e)
      setState((prev) => ({ ...prev, saving: false }))
    }
  }

  const _handlePrevious = (
    values:
      | IntegrationTypeFields
      | NeedAuthFields
      | ConnectionResult
      | OrganizationFields
      | DaybookFields
      | AccountMappingFields
  ) => {
    setState((prev) => {
      const newState = { ...prev, ...values, current: resolvePreviousStep(prev.availableSteps, prev.current) }
      switch (newState.integrationType) {
        case 'None':
          if (newState.current !== 'SelectIntegration') {
            newState.current = 'SelectIntegration'
          }
          break
        default:
          break
      }
      return newState
    })
  }

  const { company, updateCompanyAccountingIntegration, getCompanyAccountPlans } = props
  type NeedAuthFields = {
    readonly step: 'NeedAuth'
    authParameters: AuthParameters
  }
  const _handleNext = useCallback(
    (
      values:
        | IntegrationTypeFields
        | NeedAuthFields
        | ConnectionResult
        | OrganizationFields
        | DaybookFields
        | AccountMappingFields
    ) => {
      setState((prev) => ({ ...prev, saving: true }))
      switch (values.step) {
        case 'SelectIntegration': {
          const integrationType = values.integrationType
          if (!integrationType) {
            return
          }
          postCompanyAccountingIntegrationSetup(company.id, {
            type: integrationType,
          })
            .then((res) => {
              if (res.data.next === 'NeedAuth') {
                companySelected(company.id)
                document.location = res.data.authURL || ''
              } else {
                setState((prev) => ({
                  ...prev,
                  state: res.data.state,
                  integrationType: res.data.type,
                  availableSteps: ['SelectIntegration', ...res.data.steps],
                  displayName: getDisplayName(res.data.type),
                  organizations: res.data.organizations,
                  allowNoDaybook: res.data.allowNoDaybook,
                  daybooks: res.data.daybooks,
                  accountPlan: res.data.accountPlan,
                  saving: false,
                  current: res.data.next,
                }))
              }
            })
            .catch(_handleError)
          break
        }
        default: {
          const stateQuery = state.state
          if (!stateQuery) {
            return
          }
          let fields: CompanyAccountingIntegrationSetup = {}
          switch (values.step) {
            case 'NeedAuth':
            case 'NeedKey':
              fields = {
                authParameters: values.authParameters,
              }
              break
            case 'Organization':
              fields = {
                organizationID: values.organizationID,
              }
              break
            case 'Daybook':
              fields = {
                daybookID: values.daybookID,
              }
              break
            case 'MapAccounts':
              fields = {
                accountMapping: values.accountMapping,
              }
              break
          }
          patchCompanyAccountingIntegrationSetup(stateQuery, fields)
            .then((res) => {
              if (res.data.next === 'Done') {
                updateCompanyAccountingIntegration(stateQuery).then(() => {
                  getCompanyAccountPlans(company.id)
                })
              } else {
                setState((prev) => ({
                  ...prev,
                  availableSteps: ['SelectIntegration', ...res.data.steps],
                  integrationType: res.data.type,
                  displayName: prev.displayName ? prev.displayName : getDisplayName(res.data.type),
                  organizations: res.data.organizations ? res.data.organizations : prev.organizations,
                  allowNoDaybook: res.data.allowNoDaybook !== undefined ? res.data.allowNoDaybook : prev.allowNoDaybook,
                  daybooks: res.data.daybooks ? res.data.daybooks : prev.daybooks,
                  accountPlan: res.data.accountPlan ? res.data.accountPlan : prev.accountPlan,
                  saving: false,
                  current: res.data.next,
                }))
              }
            })
            .catch(_handleError)
          break
        }
      }
    },
    [state, getDisplayName, company, getCompanyAccountPlans, updateCompanyAccountingIntegration]
  )

  const { query } = props

  useEffectOnce(() => {
    if (query?.state) {
      const authParameters: AuthParameters = {}
      Object.keys(query).forEach((key) => {
        if (key === 'state') {
          return
        }
        authParameters[key as string] = query[key]
      })
      _handleNext({ step: 'NeedAuth', authParameters })
    }
  })

  const saving = props.companyAccountingIntegration.saving || state.saving
  return (
    <div className="company-accounting-integration">
      <Row>
        <Col span={16}>
          <Alerts alerts={props.alerts} removeAlert={props.removeAlert} />
          {error && <Alert message={formatError(error)} type="error" showIcon />}
          {apiError && <Alert message={formatError(apiError)} type="error" showIcon />}
          {(error || apiError) && (
            <Link to={'/' + paths.INTEGRATIONS + '/' + paths.ACCOUNTING}>
              <Button>{t('accounting_integration.error_return')}</Button>
            </Link>
          )}
          {saving && (
            <div style={{ position: 'relative', minHeight: '400px' }}>
              <LoadingOverlay />
            </div>
          )}
          {!saving && (
            <div className="company-accounting-integration-overview">
              {state.current === 'SelectIntegration' && (
                <Card>
                  <IntegrationTypeForm
                    availableAccountingIntegrations={props.availableAccountingIntegrations}
                    {...state}
                    onSubmit={_handleNext}
                  />
                </Card>
              )}
              {state.current === 'NeedKey' && (
                <Card>
                  <ConnectionForm
                    availableAccountingIntegrations={props.availableAccountingIntegrations}
                    {...state}
                    onBack={_handlePrevious}
                    onSubmit={_handleNext}
                  />
                </Card>
              )}
              {state.current === 'ChooseOrganization' && (
                <OrganizationForm
                  editing={false}
                  organizations={state.organizations || []}
                  organizationID={state.organizationID}
                  onBack={_handlePrevious}
                  onSubmit={_handleNext}
                />
              )}
              {state.current === 'ChooseDaybook' && (
                <DaybookForm
                  editing={false}
                  integrationType={state.integrationType}
                  daybooks={state.daybooks || []}
                  daybookID={state.daybookID}
                  allowNoDaybook={state.allowNoDaybook || false}
                  onBack={_handlePrevious}
                  onSubmit={_handleNext}
                />
              )}
              {state.current === 'MapAccounts' && (
                <Card>
                  <AccountMappingStep
                    accountPlan={state.accountPlan || { accounts: [], mapping: [], locked: true }}
                    integrationType={state.integrationType}
                    displayName={state.displayName || ''}
                    onBack={_handlePrevious}
                    onSubmit={_handleNext}
                  />
                </Card>
              )}
            </div>
          )}
        </Col>
        <Col span={8}>
          <Card>
            <Subtitle>{t('accounting_integration.steps.title')}</Subtitle>
            <p>{t('accounting_integration.steps.intro')}</p>

            <Steps direction="vertical" current={resolveStep(state.availableSteps, state.current)}>
              <Steps.Step title={t('accounting_integration.steps.select_integration')} />
              {state.availableSteps.some((step) => step === 'NeedKey' || step === 'NeedAuth') && (
                <Steps.Step title={t('accounting_integration.steps.need_key')} />
              )}
              {state.availableSteps.some((step) => step === 'ChooseOrganization') && (
                <Steps.Step title={t('accounting_integration.steps.choose_organization')} />
              )}
              {state.availableSteps.some((step) => step === 'ChooseDaybook') && (
                <Steps.Step title={t('accounting_integration.steps.choose_daybook')} />
              )}
              {state.availableSteps.some((step) => step === 'MapAccounts') && (
                <Steps.Step title={t('accounting_integration.steps.map_accounts')} />
              )}
            </Steps>
          </Card>
          <small style={{ textAlign: 'center' }}>
            {tx('accounting_integration.steps.note', {
              link: <a href="mailto:support@salary.dk">support@salary.dk</a>,
            })}
          </small>
        </Col>
      </Row>
    </div>
  )
}
