import React, { ReactElement } from 'react'

import { SalaryTypeSupplement } from '../../model/salaryType'
import { Day } from '../../model/types'
import { SalaryTypeReducer } from '../../reducers/salaryTypes'
import { formatDay, week } from '../../utils/day-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { addLeadingZeros, forceParseInputNumber, formatInputNumber, parseInputNumber } from '../../utils/number-utils'
import { setByPath } from '../../utils/object-utils'
import { t } from '../../utils/translation-utils'
import Form from '../antd/form'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Icon from '../elements/icon'
import Input from '../elements/input'
import Select from '../elements/select'
import Subcard from '../elements/Subcard'
import Switch from '../elements/switch'
import LoadingOverlay from '../widgets/LoadingOverlay'

type Props = {
  salaryTypeID?: string
  salaryTypes: SalaryTypeReducer
  allowSalaryTypeSupplement: boolean
}

const HOLIDAY = 'Holiday'

type FieldSupplement = {
  title?: string
  startTime?: string
  endTime?: string
  day?: Day | 'Holiday'
  amount?: string
  suffix: 'fixedAmount' | 'percentage'
}

type Fields = {
  title?: string
  includeInPensionBasis: boolean
  includeInShFritvalgBasis: boolean
  supplements: FieldSupplement[]
}

export type SalaryTypeHourlyResult = {
  title: string
  includeInPensionBasis: boolean
  includeInVacationBasis: boolean
  includeInShFritvalgBasis: boolean
  supplements: SalaryTypeSupplement[]
}

function SalaryTypeEditHourlyForm(
  props: Props & FormComponentProps<Fields, SalaryTypeHourlyResult>
): ReactElement | null {
  const add = () => {
    const { getFieldValue, setAnyFieldValue } = props
    const supplements = getFieldValue('supplements')
    const item: FieldSupplement = { suffix: 'fixedAmount' }
    if (supplements.length > 0) {
      const last = supplements[supplements.length - 1]
      item.startTime = last.startTime
      item.endTime = last.endTime
      item.amount = last.amount
      item.suffix = last.suffix
    }
    setAnyFieldValue(`supplements.${supplements.length}`, item)
  }
  const remove = (i: number) => {
    const { getFieldValue, setFieldValue } = props
    const supplements = getFieldValue('supplements')
    supplements.splice(i, 1)
    setFieldValue('supplements', supplements)
  }

  const { decorateField, decorateAnyField, getFieldValue, getAnyFieldError } = props

  type Item = {
    key: string
    title: string
  }

  const days: Item[] = week.map(
    (day): Item => ({
      key: day,
      title: formatDay(day),
    })
  )
  days.push({ key: HOLIDAY, title: t('salary_types.hourly.edit.form.day.holiday') })

  const startTimes: Item[] = []
  const endTimes: Item[] = []
  for (let i = 0; i <= 24 * 60; i += 15) {
    const hours = Math.floor(i / 60)
    const minutes = i % 60
    const item: Item = { key: `${i}`, title: `${addLeadingZeros(hours)}:${addLeadingZeros(minutes)}` }
    if (i > 0) {
      endTimes.push(item)
    }
    if (i < 1440) {
      startTimes.push(item)
    }
  }

  const rows = getFieldValue('supplements')
  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={24}>
          {decorateField('title', {
            placeholder: t('salary_types.hourly.edit.form.title'),
            validate: (val) => (!val ? t('salary_types.hourly.edit.form.title.required') : null),
          })(<Input style={{ width: '100%' }} />)}
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <div className="ant-switch-wrapper">
            {decorateField('includeInPensionBasis', {
              skipWrapper: true,
              skipLabel: true,
              valueOnChecked: true,
              noBlur: true,
            })(<Switch />)}
            <span className="ant-switch-text">{t('salary_types.hourly.edit.form.include_in_pension_basis')}</span>
          </div>
        </Col>
        <Col span={12}>
          <div className="ant-switch-wrapper">
            {decorateField('includeInShFritvalgBasis', {
              skipWrapper: true,
              skipLabel: true,
              valueOnChecked: true,
              noBlur: true,
            })(<Switch />)}
            <span className="ant-switch-text">{t('salary_types.hourly.edit.form.include_in_sh_fritvalg_basis')}</span>
          </div>
        </Col>
      </Row>

      {props.allowSalaryTypeSupplement && (
        <Subcard>
          <Row style={{ marginBottom: '-10px' }}>
            <Col span={5}>
              <label>{t('salary_types.hourly.edit.form.supplement.header.title')}</label>
            </Col>
            <Col span={5}>
              <label>{t('salary_types.hourly.edit.form.supplement.header.day')}</label>
            </Col>
            <Col span={4}>
              <label>{t('salary_types.hourly.edit.form.supplement.header.start_time')}</label>
            </Col>
            <Col span={4}>
              <label>{t('salary_types.hourly.edit.form.supplement.header.end_time')}</label>
            </Col>
            <Col span={6}>
              <label>{t('salary_types.hourly.edit.form.supplement.header.amount')}</label>
            </Col>
          </Row>
          {rows.map((type: FieldSupplement, i: number) => {
            return (
              <Form.Item
                key={i}
                validateStatus={
                  getAnyFieldError(`supplements.${i}.title`) ||
                  getAnyFieldError(`supplements.${i}.day`) ||
                  getAnyFieldError(`supplements.${i}.startTime`) ||
                  getAnyFieldError(`supplements.${i}.endTime`) ||
                  getAnyFieldError(`supplements.${i}.amount`) ||
                  getAnyFieldError(`supplements.${i}.suffix`)
                    ? 'error'
                    : 'success'
                }
              >
                <Row>
                  <Col span={5}>
                    {decorateAnyField(`supplements.${i}.title`, {
                      validate: (val) => (!val ? t('salary_types.hourly.edit.form.supplement.title.required') : null),
                      placeholder: t('salary_types.hourly.edit.form.supplement.title'),
                      skipWrapper: true,
                      skipLabel: true,
                    })(<Input />)}
                  </Col>
                  <Col span={5}>
                    {decorateAnyField(`supplements.${i}.day`, {
                      placeholder: t('salary_types.hourly.edit.form.supplement.day'),
                      validate: (val) => (!val ? t('salary_types.hourly.edit.form.supplement.day.required') : null),
                      skipWrapper: true,
                      skipLabel: true,
                    })(
                      <Select dropdownMatchSelectWidth={false}>
                        {days.map((day) => {
                          return (
                            <Select.Option key={day.key} value={day.key}>
                              {day.title}
                            </Select.Option>
                          )
                        })}
                      </Select>
                    )}
                  </Col>
                  <Col span={4}>
                    {decorateAnyField(`supplements.${i}.startTime`, {
                      placeholder: t('salary_types.hourly.edit.form.supplement.start_time'),
                      validate: (val) =>
                        !val ? t('salary_types.hourly.edit.form.supplement.start_time.required') : null,
                      skipWrapper: true,
                      skipLabel: true,
                    })(
                      <Select dropdownMatchSelectWidth={false}>
                        {startTimes.map((startTime) => {
                          return (
                            <Select.Option key={startTime.key} value={startTime.key}>
                              {startTime.title}
                            </Select.Option>
                          )
                        })}
                      </Select>
                    )}
                  </Col>
                  <Col span={4}>
                    {decorateAnyField(`supplements.${i}.endTime`, {
                      placeholder: t('salary_types.hourly.edit.form.supplement.end_time'),
                      validate: (val) =>
                        !val ? t('salary_types.hourly.edit.form.supplement.end_time.required') : null,
                      skipWrapper: true,
                      skipLabel: true,
                    })(
                      <Select dropdownMatchSelectWidth={false}>
                        {endTimes.map((endTime) => {
                          return (
                            <Select.Option key={endTime.key} value={endTime.key}>
                              {endTime.title}
                            </Select.Option>
                          )
                        })}
                      </Select>
                    )}
                  </Col>
                  <Col span={5}>
                    <Input.Group compact>
                      {decorateAnyField(`supplements.${i}.amount`, {
                        placeholder:
                          type.suffix === 'fixedAmount'
                            ? t('salary_types.hourly.edit.form.supplement.amount.fixed')
                            : t('salary_types.hourly.edit.form.supplement.amount.percentage'),
                        validate: (val) => {
                          if (!val) {
                            return type.suffix === 'fixedAmount'
                              ? t('salary_types.hourly.edit.form.supplement.amount.fixed.required')
                              : t('salary_types.hourly.edit.form.supplement.amount.fixed.percentage')
                          }
                          return null
                        },
                        skipWrapper: true,
                        skipLabel: true,
                      })(<Input style={{ width: '60%' }} />)}
                      {decorateAnyField(`supplements.${i}.suffix`, {
                        skipWrapper: true,
                        skipLabel: true,
                      })(
                        <Select dropdownMatchSelectWidth={false} style={{ width: '40%' }}>
                          <Select.Option value="percentage">%</Select.Option>
                          <Select.Option value="fixedAmount">kr.</Select.Option>
                        </Select>
                      )}
                    </Input.Group>
                  </Col>
                  <Col span={1} className="contracts-remove-row">
                    <span onClick={() => remove(i)}>
                      <Icon type="xSign" />
                    </span>
                  </Col>
                </Row>
              </Form.Item>
            )
          })}
          <span className="contracts-add-row" onClick={add}>
            <Icon type="plusCircle" /> {t('salary_types.hourly.edit.form.supplement.add_row')}
          </span>
        </Subcard>
      )}
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="primary">
            {t('form.button.save_changes')}
          </Button>
        </Col>
      </Row>
      {props.salaryTypes.saving && <LoadingOverlay />}
    </div>
  )
}

const order: Record<Day | 'Holiday', number> = {
  ['Monday']: 0,
  ['Tuesday']: 1,
  ['Wednesday']: 2,
  ['Thursday']: 3,
  ['Friday']: 4,
  ['Saturday']: 5,
  ['Sunday']: 6,
  [HOLIDAY]: 7,
}

export default withValidations<Props, Fields, SalaryTypeHourlyResult>({
  mapPropsToFields: (props) => {
    const fields: Fields = {
      title: undefined,
      includeInShFritvalgBasis: true,
      includeInPensionBasis: true,
      supplements: [],
    }
    if (props.salaryTypeID) {
      props.salaryTypes.salaryTypes.forEach((salaryType) => {
        if (salaryType.id === props.salaryTypeID) {
          fields.title = salaryType.title || undefined
          fields.includeInPensionBasis = salaryType.includeInPensionBasis
          fields.includeInShFritvalgBasis = salaryType.includeInShFritvalgBasis
          fields.supplements = (salaryType.supplements || []).map((supplement) => {
            const item: FieldSupplement = {
              title: supplement.title,
              startTime: `${supplement.start % (24 * 60)}`,
              endTime: `${supplement.end - Math.floor(supplement.start / (24 * 60)) * 24 * 60}`,
              day: HOLIDAY,
              suffix: 'percentage',
            }
            if (supplement.isHoliday) {
              item.day = HOLIDAY
            } else {
              if (supplement.start < 1 * 24 * 60) {
                item.day = 'Monday'
              } else if (supplement.start < 2 * 24 * 60) {
                item.day = 'Tuesday'
              } else if (supplement.start < 3 * 24 * 60) {
                item.day = 'Wednesday'
              } else if (supplement.start < 4 * 24 * 60) {
                item.day = 'Thursday'
              } else if (supplement.start < 5 * 24 * 60) {
                item.day = 'Friday'
              } else if (supplement.start < 6 * 24 * 60) {
                item.day = 'Saturday'
              } else {
                item.day = 'Sunday'
              }
            }
            if (supplement.fixedSupplement) {
              item.amount = formatInputNumber(supplement.fixedSupplement)
              item.suffix = 'fixedAmount'
            } else {
              item.amount = formatInputNumber((supplement.percentageSupplement || 0) * 100)
              item.suffix = 'percentage'
            }
            return item
          })
          fields.supplements.sort((a, b) => {
            const sort = order[a.day || 'Holiday'] - order[b.day || 'Holiday']
            if (sort !== 0) {
              return sort
            }
            return (a.startTime || '').localeCompare(b.startTime || '', 'da-DK')
          })
        }
      })
    }
    return fields
  },
  onChange: (key, val, allValues, options) => {
    const values = {}
    switch (key) {
      case 'amount':
        setByPath(
          values,
          key,
          formatInputNumber(parseInputNumber(val as string, { trim: options.trigger === 'onBlur' }))
        )
        break
      default:
        setByPath(values, key, val)
        break
    }
    return values
  },
  onSubmit: (values) => {
    const supplements = values.supplements.map((supplement): SalaryTypeSupplement => {
      let isHoliday = false
      let start = parseInt(supplement.startTime || '0', 10)
      let end = parseInt(supplement.endTime || '0', 10)
      switch (supplement.day) {
        case 'Monday':
          // Use defaults
          break
        case 'Tuesday':
          start += 1 * 24 * 60
          end += 1 * 24 * 60
          break
        case 'Wednesday':
          start += 2 * 24 * 60
          end += 2 * 24 * 60
          break
        case 'Thursday':
          start += 3 * 24 * 60
          end += 3 * 24 * 60
          break
        case 'Friday':
          start += 4 * 24 * 60
          end += 4 * 24 * 60
          break
        case 'Saturday':
          start += 5 * 24 * 60
          end += 5 * 24 * 60
          break
        case 'Sunday':
          start += 6 * 24 * 60
          end += 6 * 24 * 60
          break
        case HOLIDAY:
          isHoliday = true
          break
        default:
          break
      }
      let fixedSupplement = undefined
      let percentageSupplement = undefined
      if (supplement.suffix === 'fixedAmount') {
        fixedSupplement = forceParseInputNumber(supplement.amount)
      } else {
        percentageSupplement = forceParseInputNumber(supplement.amount) / 100
      }
      return {
        title: supplement.title || '',
        isHoliday,
        start,
        end,
        fixedSupplement,
        percentageSupplement,
      }
    })
    return {
      title: values.title!,
      includeInPensionBasis: values.includeInPensionBasis,
      includeInShFritvalgBasis: values.includeInShFritvalgBasis,
      includeInVacationBasis: true,
      supplements,
    }
  },
})(SalaryTypeEditHourlyForm)
