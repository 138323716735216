import React, { ReactElement } from 'react'

import { SlackConfiguration } from '../../api/slack-integrations'
import Language from '../../types/language'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { formatLanguage } from '../../utils/format-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Checkbox from '../elements/checkbox'
import { Col, Row } from '../elements/grid'
import Input from '../elements/input/Input'
import Radio from '../elements/radio'

type Props = {
  configuration: Partial<SlackConfiguration>
}

type Fields = {
  slackToken?: string
  languageCode?: 'da' | 'en'
  eventGroups: {
    channelID?: string
    eventTypes_birthdays?: boolean
    eventTypes_leave?: boolean
    eventTypes_anniversaries?: boolean
  }[]
}

export type SlackIntegrationResult = SlackConfiguration

function SlackIntegrationForm(props: Props & FormComponentProps<Fields, SlackIntegrationResult>): ReactElement | null {
  const { decorateField, decorateAnyField, getFieldValue, setFieldValue } = props
  return (
    <>
      <Row>
        <Col span={12}>
          {decorateField('slackToken', {
            placeholder: t('slack_integration.form.slack_token'),
            validate: (v) => (!v ? t('slack_integration.form.slack_token.required') : null),
          })(<Input />)}
        </Col>
        <Col span={12}>
          {decorateField('languageCode', {
            placeholder: t('slack_integration.form.language_code'),
            validate: (v) => {
              if (!v) {
                return t('slack_integration.form.language_code.required')
              }
              if (v !== 'da' && v !== 'en') {
                return t('slack_integration.form.language_code.invalid')
              }
              return null
            },
          })(
            <Radio.Group>
              <Radio value="da">{formatLanguage(Language.DANISH)}</Radio>
              <Radio value="en">{formatLanguage(Language.ENGLISH)}</Radio>
            </Radio.Group>
          )}
        </Col>
      </Row>
      {getFieldValue('eventGroups').map((g, i) => {
        return (
          <Row key={i}>
            <Col span={10}>
              {decorateAnyField(`eventGroups.${i}.channelID`, {
                placeholder: t('slack_integration.form.channel_id'),
                validate: (v) => (!v ? t('slack_integration.form.channel_id.required') : null),
              })(<Input />)}
            </Col>
            <Col span={4}>
              {decorateAnyField(`eventGroups.${i}.eventTypes_birthdays`, {
                placeholder: t('slack_integration.form.event_types.birthdays'),
                skipLabel: true,
                valueOnChecked: true,
              })(<Checkbox>{t('slack_integration.form.event_types.birthdays')}</Checkbox>)}
            </Col>
            <Col span={4}>
              {decorateAnyField(`eventGroups.${i}.eventTypes_leave`, {
                placeholder: t('slack_integration.form.event_types.leave'),
                skipLabel: true,
                valueOnChecked: true,
              })(<Checkbox>{t('slack_integration.form.event_types.leave')}</Checkbox>)}
            </Col>
            <Col span={4}>
              {decorateAnyField(`eventGroups.${i}.eventTypes_anniversaries`, {
                placeholder: t('slack_integration.form.event_types.anniversaries'),
                skipLabel: true,
                valueOnChecked: true,
              })(<Checkbox>{t('slack_integration.form.event_types.anniversaries')}</Checkbox>)}
            </Col>
            <Col span={2}>
              <Button.Icon
                type="xSign"
                danger
                onClick={() =>
                  setFieldValue(
                    'eventGroups',
                    getFieldValue('eventGroups').filter((_, j) => j !== i)
                  )
                }
              />
            </Col>
          </Row>
        )
      })}
      <Row>
        <Col span={24}>
          <Button onClick={() => setFieldValue('eventGroups', [...getFieldValue('eventGroups'), {}])}>
            {t('slack_integration.form.add_row')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button htmlType="submit" type="primary">
            {t('slack_integration.form.submit')}
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default withValidations<Props, Fields, SlackIntegrationResult>({
  mapPropsToFields: (props) => ({
    slackToken: props.configuration.slackToken,
    languageCode: props.configuration.languageCode,
    eventGroups: (props.configuration.eventGroups ?? []).map((g) => ({
      channelID: g.channelID,
      eventTypes_birthdays: g.eventTypes.some((t) => t === 'birthdays'),
      eventTypes_leave: g.eventTypes.some((t) => t === 'leave'),
      eventTypes_anniversaries: g.eventTypes.some((t) => t === 'anniversaries'),
    })),
  }),
  onSubmit: (values) => ({
    slackToken: values.slackToken!,
    languageCode: values.languageCode!,
    eventGroups: values.eventGroups.map((g) => ({
      channelID: g.channelID!,
      departments: [],
      eventTypes: (['birthdays', 'leave', 'anniversaries'] as const).filter((e) => g[`eventTypes_${e}`]),
    })),
  }),
})(SlackIntegrationForm)
