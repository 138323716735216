export function validateEmail(email: string): boolean {
  // RFC 1035: domains cannot start with 0-9 and hyphen; domains cannot end with hyphen
  // but apparently no respects the not starting with numbers thing, only the hyphen
  if (
    !email.match(
      /^[a-z0-9_.+-]+@([\da-z0-9æøåẞöäüé]{1,2}|[\da-z0-9æøåẞöäüé][\da-z0-9.æøåẞöäüé-]+[\da-z0-9.æøåẞöäüé])\.[a-z0-9.-]{2,}$/i
    )
  ) {
    return false
  }
  // but the above regex allows double dots in the domain, so let's check for that as well
  return !email.match(/^.+@.+?\.\..+?$/)
}

export function emailDomain(email: string): string {
  return email.split('@')[1]
}
