import { endOfMonth, startOfMonth } from 'date-fns'
import React, { ReactElement, useState } from 'react'
import { Link } from 'react-router'

import { addAlertSignature } from '../../actions/alerts'
import { formatAPIDate, getDate } from '../../utils/date-utils'
import { secureUrl } from '../../utils/request-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button/button'
import Card from '../elements/card'
import DatePicker from '../elements/date-picker/DatePicker'
import { Col, Row } from '../elements/grid'
import Subcard from '../elements/Subcard'
import Subtitle from '../elements/Subtitle'
import Title from '../elements/Title'
import ReportButton from '../widgets/ReportButton'

type Props = {
  companyID: string

  addAlert: addAlertSignature
}

export default function ReimbursementVoucherReport(props: Props): ReactElement | null {
  const [dateRange, setDateRange] = useState<[Date, Date]>([startOfMonth(getDate()), endOfMonth(getDate())])
  return (
    <Card>
      <Title>{t('reimbursement_vouchers.report_modal.title')}</Title>
      <Subcard>
        <ReportButton
          state={1}
          type={'Excel'}
          addAlert={props.addAlert}
          icon={null}
          text={t('reimbursement_vouchers.report_modal.report.download')}
          getFields={() => ({
            type: 'Regular',
            companyID: props.companyID,
            report: 'ReimbursementVouchersAccounting',
            from: '0001-01-01',
            to: '0001-01-01',
          })}
        />
      </Subcard>
      <Subcard>
        <Subtitle>{t('reimbursement_vouchers.report_modal.zip.title')}</Subtitle>
        <p>{t('reimbursement_vouchers.report_modal.zip.intro')}</p>
        <Row>
          <Col span={12}>
            <label>{t('reimbursement_vouchers.report_modal.zip.from')}</label>
            <DatePicker
              value={dateRange[0]}
              onChange={(date) => (date ? setDateRange((range) => [date, range[1]]) : null)}
            />
          </Col>
          <Col span={12}>
            <label>{t('reimbursement_vouchers.report_modal.zip.to')}</label>
            <DatePicker
              value={dateRange[1]}
              onChange={(date) => (date ? setDateRange((range) => [range[0], date]) : null)}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Link
              to={secureUrl('/v2/reimbursementVouchers/zip', {
                companyID: props.companyID,
                from: formatAPIDate(dateRange[0]),
                to: formatAPIDate(dateRange[1]),
              })}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button prefixIcon="arrowDownIntoTray" type="primary">
                {t('reimbursement_vouchers.report_modal.zip.download')}
              </Button>
            </Link>
          </Col>
        </Row>
      </Subcard>
    </Card>
  )
}
