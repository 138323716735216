import TimeRegistration, {
  TimeRegistrationClass,
  TimeRegistrationCreationFields,
  TimeRegistrationMutableFields,
} from '../model/timeRegistration'
import { DateFormat } from '../model/types'
import { RequestResponse, secureRequest, url } from '../utils/request-utils'

export function fetchTimeRegistrations(
  companyID?: string,
  employeeID?: string,
  payRollID?: string,
  fromDate?: DateFormat,
  toDate?: DateFormat,
  limit = 1000,
  offset = 0
): Promise<RequestResponse<TimeRegistration[]>> {
  return secureRequest(
    'GET',
    url('/v2/timeRegistrations', {
      limit,
      offset,
      companyID,
      employeeID,
      payRollID,
      from: fromDate,
      to: toDate,
    })
  )
}

export function postTimeRegistration(
  timeRegistration: TimeRegistrationCreationFields
): Promise<RequestResponse<TimeRegistration>> {
  return secureRequest('POST', url('/v2/timeRegistrations'), {
    body: {
      employeeID: timeRegistration.employeeID,
      class: timeRegistration.class,
      salaryTypeID: timeRegistration.salaryTypeID,
      projectID: timeRegistration.projectID,
      date: timeRegistration.date,
      start: timeRegistration.start,
      end: timeRegistration.end,
      hours: timeRegistration.hours,
      minutes: timeRegistration.minutes,
      days: timeRegistration.days,
      leaveTypeID: timeRegistration.leaveTypeID,
      leaveSubTypeID: timeRegistration.leaveSubTypeID,
      note: timeRegistration.note,
      costCenterID: timeRegistration.costCenterID,
      approved: timeRegistration.approved,
      noRateAdjustment: timeRegistration.noRateAdjustment,
    },
  })
}

export type TimeRegistrationBulk = {
  employeeID: string
  startDate: DateFormat
  endDate: DateFormat
  leaveTypeID: string
  note: string
  leaveSubTypeID?: string
  approved: boolean
}

export function postTimeRegistrationBulk(
  timeRegistration: TimeRegistrationBulk
): Promise<RequestResponse<TimeRegistration[]>> {
  return secureRequest('POST', url('/v2/leaveRegistrations/bulk'), {
    body: {
      employeeID: timeRegistration.employeeID,
      startDate: timeRegistration.startDate,
      endDate: timeRegistration.endDate,
      leaveTypeID: timeRegistration.leaveTypeID,
      leaveSubTypeID: timeRegistration.leaveSubTypeID,
      note: timeRegistration.note,
      approved: timeRegistration.approved,
    },
  })
}

export function patchTimeRegistrations(
  action: 'Approve' | 'Unapprove',
  timeRegistrationIDs: string[]
): Promise<RequestResponse<TimeRegistration[]>> {
  return secureRequest('PATCH', url('/v2/timeRegistrations'), {
    body: {
      action,
      timeRegistrationIDs,
    },
  })
}

export function putTimeRegistration(
  timeRegistration: TimeRegistrationMutableFields
): Promise<RequestResponse<TimeRegistration>> {
  return secureRequest('PUT', url('/v2/timeRegistrations/' + timeRegistration.id), {
    body: {
      id: timeRegistration.id,
      employeeID: timeRegistration.employeeID,
      class: timeRegistration.class,
      salaryTypeID: timeRegistration.salaryTypeID,
      projectID: timeRegistration.projectID,
      date: timeRegistration.date,
      days: timeRegistration.days,
      start: timeRegistration.start,
      end: timeRegistration.end,
      hours: timeRegistration.hours,
      minutes: timeRegistration.minutes,
      leaveTypeID: timeRegistration.leaveTypeID,
      leaveSubTypeID: timeRegistration.leaveSubTypeID,
      note: timeRegistration.note,
      costCenterID: timeRegistration.costCenterID,
    },
  })
}

export function delTimeRegistration(timeRegistrationID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/timeRegistrations/' + timeRegistrationID))
}

export function delLeaveRegistrationBulk(employeeID: string): Promise<RequestResponse> {
  return secureRequest('DELETE', url('/v2/leaveRegistrations/bulk'), {
    body: {
      employeeID,
    },
  })
}

export function delTimeRegistrationHoursBulk(
  companyID?: string,
  employeeID?: string,
  timeClass?: TimeRegistrationClass,
  deleteAll?: boolean
): Promise<RequestResponse> {
  const body = {
    employeeID,
    companyID,
    class: timeClass,
    deleteAll,
  }
  return secureRequest('DELETE', url('/v2.1/timeRegistrations/bulk'), {
    body,
  })
}
