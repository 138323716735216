import { addYears } from 'date-fns'
import { List } from 'immutable'
import React, { ReactElement, useState } from 'react'
import { Link } from 'react-router'

import paths from '../../constants/paths'
import Asset from '../../model/asset'
import AssetCategory from '../../model/assetCategory'
import Company from '../../model/company'
import CompanyUser from '../../model/companyUser'
import Department from '../../model/department'
import Employee, { AffiliationType } from '../../model/employee'
import EmployeeBatchResult, {
  BatchAction,
  BatchFields,
  BatchOperation,
  EmployeeBatchStatus,
} from '../../model/employeeBatchResult'
import EmploymentPosition from '../../model/employmentPosition'
import PensionCompany from '../../model/pensionCompany'
import SalaryCycle from '../../model/salaryCycle'
import SalaryType, { SalaryClass } from '../../model/salaryType'
import SupplementType from '../../model/supplementType'
import { DateFormat } from '../../model/types'
import { UserReducer } from '../../reducers/user'
import Language from '../../types/language'
import PaySlipTransportSetting from '../../types/pay-slip-transport-setting'
import PreferredTaxCardType from '../../types/preferred-tax-card-type'
import { formatAPIDate, formatDate, getDate, isSameOrAfter, isTimeBefore } from '../../utils/date-utils'
import {
  isAmountChoiceSupplement,
  isDirectPayChoiceSupplement,
  isPercentageChoiceVariant,
} from '../../utils/employee-contract-utils'
import { combineSearchOption, FormComponentProps, withValidations } from '../../utils/form-utils'
import {
  formatBiweeklySalaryCycle,
  formatLanguage,
  formatOneTimePayType,
  formatPreferredTaxCardType,
  formatSalaryCycle,
  formatSupplementTypeFullName,
} from '../../utils/format-utils'
import { forceParseInputNumber, formatInputNumber, parseInputNumber } from '../../utils/number-utils'
import { setByPath } from '../../utils/object-utils'
import { isDepartmentRestricted } from '../../utils/permissions-utils'
import { getAvailableCycles } from '../../utils/salary-cycle-utils'
import { formatEmployeeBatchMessage } from '../../utils/staged-import-utils'
import { camelCaseToSnakeCase } from '../../utils/string-utils'
import { t, tx } from '../../utils/translation-utils'
import { isUserSupport } from '../../utils/user-utils'
import Button from '../elements/button'
import Card from '../elements/card'
import Checkbox from '../elements/checkbox'
import DatePicker from '../elements/date-picker'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Headline from '../elements/Headline'
import Input from '../elements/input'
import Radio from '../elements/radio'
import Select from '../elements/select'
import Subcard from '../elements/Subcard'
import Subtitle from '../elements/Subtitle'
import Tooltip from '../elements/tooltip'
import UserImage from '../elements/UserImage'
import ContractPosition from '../form-elements/ContractPosition'
import FeatureLock from '../widgets/FeatureLock'

type Props = {
  assets: List<Asset>
  assetCategories: List<AssetCategory>
  company: Company
  companyUser?: CompanyUser
  user: UserReducer
  departments: List<Department>
  employees: List<Employee>
  supplementTypes: List<SupplementType>
  salaryCycles: List<SalaryCycle>
  pensionCompanies: List<PensionCompany>
  employmentPositions: List<EmploymentPosition>
  salaryTypes: List<SalaryType>
  employeeIDs: string[]
  batchMessages: EmployeeBatchResult[]
  hasFutureContractsFeature: boolean
  hasAssetsFeature: boolean
  saved: boolean

  reset: () => void
}

type Fields = {
  action?: BatchAction
  operation: boolean
  validFrom: DateFormat | 'Now'
  validFromPicker?: Date
  pensionOperation: boolean
  hasLunchDaily: boolean
  supplementPercentage: boolean
  supplementTypeID?: string
  supplementCompensationRate?: string
  pensionEmployeePercentage: boolean
  pensionEmployerPercentage: boolean
  pensionEmployeeAmount?: string
  pensionEmployerAmount?: string
  paySlipTransportSetting?: PaySlipTransportSetting
  amount?: string
  departmentID?: string
  language?: Language
  preferredTaxCard?: PreferredTaxCardType
  pensionCompanyID?: string
  pensionCustomerID?: string
  pensionUnionAgreementNumber?: string
  pensionHasUnionAgreementNumber: boolean
  pensionUsesNationalID: boolean
  employmentPositionID?: string[]
  position?: string
  productionUnitID?: string
  title?: string
  description?: string
  assetCategoryID?: string
  assetsToReturn: Record<string, boolean>
  //personalDays?: string
  accrual?: boolean
  salaryCycleID?: string
  oneTimePayType?: string
  salaryClass?: SalaryClass
  salaryTypeID?: string
  quantity?: string
}

export type BatchResult = BatchFields & {
  action?: BatchAction
  operation: BatchOperation
}

/*
  Since we cannot have the titles for the assets to be returned as the values of the fields, we condense the unique
  titles to a list, and use their index in that list as their unique identifier.  This will ensure that they are the
  same from each part of the code that calls this function, so that their indices always matches.
 */
type AssetTitle = {
  id: number
  title: string
  categoryIDs: string[]
  employeeIDs: string[]
}

function assetTitleList(assets: List<Asset>): AssetTitle[] {
  return assets
    .reduce((list: AssetTitle[], asset) => {
      const idx = list.findIndex((title) => title.title === asset.title)
      if (idx !== -1) {
        list[idx].employeeIDs.push(asset.employeeID)
        list[idx].categoryIDs.push(asset.categoryID)
        return list
      }
      return [
        ...list,
        {
          title: asset.title,
          employeeIDs: [asset.employeeID],
          categoryIDs: [asset.categoryID],
          id: 0,
        },
      ]
    }, [])
    .sort((a, b) => a.title.localeCompare(b.title))
    .map((title, i) => {
      title.id = i
      return title
    })
}

function BatchUpdateForm(props: Props & FormComponentProps<Fields, BatchResult>): ReactElement | null {
  const { employees, employeeIDs } = props

  const moreOneTimePayOptions = props.company.settingsEnabled.some((setting) => setting === 'MoreBonusOptions')
  const getFilteredEmployees = () => employees.filter((e) => employeeIDs.some((id) => id === e.id))

  const [validFromDates] = useState<DateFormat[]>(() => {
    const now = getDate()
    const twoYearsInFuture = addYears(now, 2)
    const theseEmployees = getFilteredEmployees()
    return props.salaryCycles
      .reduce((list: DateFormat[], cycle) => {
        // filter to cycles only by the employees selected
        if (
          theseEmployees.some(
            (e) => e.activeContract?.salaryCycleID === cycle.id || e.earliestMutableContract?.salaryCycleID === cycle.id
          )
        ) {
          return [...list, ...cycle.salaryPeriods.map((period) => period.start)]
        }
        return list
      }, [])
      .filter(
        (validFrom) => isSameOrAfter(getDate(validFrom), now) && isTimeBefore(getDate(validFrom), twoYearsInFuture)
      )
      .sort((a, b) => a.localeCompare(b))
  })

  const handleActionSelect = (value: BatchAction) => {
    const previous = props.getFieldValue('action')
    if (props.saved) {
      props.setFieldValue('amount', formatInputNumber(0))
      props.reset()
    }
    if (
      props.hasFutureContractsFeature &&
      (value === 'TelephoneBenefit' ||
        value === 'LunchBenefit' ||
        value === 'LunchDailyBenefit' ||
        value === 'HealthBenefit' ||
        value === 'Supplement' ||
        value === 'Pension' ||
        value === 'Position' ||
        value === 'Terminate')
    ) {
      if (value !== 'Terminate' && previous === 'Terminate') {
        props.setFieldValue('validFrom', 'Now')
      } else if (value === 'Terminate' && previous !== 'Terminate') {
        props.setFieldValue('validFrom', formatAPIDate(getDate()))
      }
    }
  }

  const canSubmit = (): boolean => {
    const { getFieldValue } = props
    if (!getFieldValue('action')) {
      return false
    }

    switch (getFieldValue('action')) {
      case 'PaySlipTransport':
        if (!getFieldValue('paySlipTransportSetting')) {
          return false
        }
        break
      case 'LunchBenefit':
      case 'HealthBenefit':
        if (
          getFieldValue('operation') &&
          (!getFieldValue('amount') || forceParseInputNumber(getFieldValue('amount')) <= 0)
        ) {
          return false
        }
        break
      case 'Department':
        if (getFieldValue('operation') && !getFieldValue('departmentID')) {
          return false
        }
        break
      case 'PreferredTaxCard':
        if (!getFieldValue('preferredTaxCard')) {
          return false
        }
        break
      case 'Supplement':
        if (
          !getFieldValue('supplementTypeID') ||
          (getFieldValue('operation') && !getFieldValue('supplementCompensationRate'))
        ) {
          return false
        }
        break
      case 'Pension':
        if (!getFieldValue('pensionOperation')) {
          return true
        }
        if (!getFieldValue('pensionCompanyID')) {
          return false
        }
        if (
          getFieldValue('pensionCompanyID') !== 'Remove' &&
          !getFieldValue('pensionEmployeeAmount') &&
          !getFieldValue('pensionEmployerAmount')
        ) {
          return false
        }
        break
      case 'Position':
        if (!getFieldValue('position')) {
          return false
        }
        break
      case 'ProductionUnit':
        if (!getFieldValue('productionUnitID')) {
          return false
        }
        break
      case 'PersonalDays':
      case 'OptionalDays':
      case 'NetPayReductionBenefit':
      case 'EmployeeAssociationBenefit':
        if (!getFieldValue('amount')) {
          return false
        }
        break
      case 'Salary':
        if (!getFieldValue('salaryClass')) {
          return false
        }
        break
      default:
        break
    }

    return true
  }

  type EmployeeRow = {
    id: string
    profileImageURL?: string
    affiliationType: AffiliationType
    name: string
    state: EmployeeBatchStatus
    message: string
    details: string[]
    position?: string
  }

  const getSelectedEmployees = (): EmployeeRow[] => {
    return getFilteredEmployees()
      .map((employee): EmployeeRow => {
        const row: EmployeeRow = {
          id: employee.id,
          affiliationType: employee.affiliationType,
          profileImageURL: employee.profileImageURL,
          name: employee.name || employee.email || '-',
          state: 'None',
          message: '',
          details: [],
        }
        if (props.saved) {
          const batchMessage = props.batchMessages.find((batchMessage) => batchMessage.employeeID === employee.id)
          if (batchMessage) {
            row.state = batchMessage.state
            row.message = batchMessage.message
            row.details = batchMessage.details || []
          }
        }

        row.position = employee.earliestMutableContract ? employee.earliestMutableContract.position : undefined

        return row
      })
      .toArray()
      .sort((a, b) => {
        if (a.state === b.state) {
          return a.name.localeCompare(b.name, 'da-dk')
        }
        if (a.state !== 'Failure') {
          return 1
        }
        return b.state === 'Failure' ? 1 : -1
      })
  }

  const getDepartments = () => {
    return props.departments.filter((department) => department.active)
  }

  const { decorateField, decorateAnyField, getFieldValue } = props
  const selectedEmployees = getSelectedEmployees()
  const directPay = props.company.settingsEnabled.some((setting) => setting === 'AllowSHFritvalgDirectPay')
  const choiceTypes = props.supplementTypes
    .filter((row) => isAmountChoiceSupplement(row.name) && (directPay || !isDirectPayChoiceSupplement(row.name)))
    .toArray()
    .sort((a, b) =>
      formatSupplementTypeFullName(a.name, { skipAmount: true }).localeCompare(
        formatSupplementTypeFullName(b.name, { skipAmount: true })
      )
    )
  const usesFutureContract = props.hasFutureContractsFeature

  const departmentManager = isDepartmentRestricted(props.companyUser)
  const relevantAssetTitles = assetTitleList(props.assets).filter(
    (asset) =>
      asset.categoryIDs.indexOf(getFieldValue('assetCategoryID') || '') !== -1 &&
      asset.employeeIDs.some((employeeID) => props.employeeIDs.indexOf(employeeID) !== -1)
  )

  const values = {
    action: getFieldValue('action'),
    salaryClass: getFieldValue('salaryClass'),
    salaryClassSC: camelCaseToSnakeCase(getFieldValue('salaryClass')),
  }

  return (
    <div>
      <Card>
        {decorateField('action', {
          title: t('employee_batch.update.form.action'),
          placeholder: t('employee_batch.update.form.action'),
          skipWrapper: true,
          noBlur: true,
        })(
          <Select
            tabIndex={1}
            dropdownMatchSelectWidth={false}
            onChange={(v) => handleActionSelect(v as BatchAction)}
            showSearch={true}
            filterOption={(inputValue: string, option: ReactElement) => {
              return combineSearchOption(option).toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
            }}
          >
            <Select.Option key="AppInvite" value="AppInvite" title={t('employee_batch.update.form.action.app_invite')}>
              {t('employee_batch.update.form.action.app_invite')}
            </Select.Option>
            <Select.Option
              key="PaySlipTransport"
              value="PaySlipTransport"
              title={t('employee_batch.update.form.action.pay_slip_transport')}
            >
              {t('employee_batch.update.form.action.pay_slip_transport')}
            </Select.Option>
            <Select.Option
              key="TelephoneBenefit"
              value="TelephoneBenefit"
              title={t('employee_batch.update.form.action.telephone_benefit')}
            >
              {t('employee_batch.update.form.action.telephone_benefit')}
            </Select.Option>
            <Select.Option
              key="LunchBenefit"
              value="LunchBenefit"
              title={t('employee_batch.update.form.action.lunch_benefit')}
            >
              {t('employee_batch.update.form.action.lunch_benefit')}
            </Select.Option>
            <Select.Option
              key="HealthBenefit"
              value="HealthBenefit"
              title={t('employee_batch.update.form.action.prut')}
            >
              {t('employee_batch.update.form.action.health_benefit')}
            </Select.Option>
            <Select.Option
              key="NetPayReductionBenefit"
              value="NetPayReductionBenefit"
              title={t('employee_batch.update.form.action.prut')}
            >
              {t('employee_batch.update.form.action.net_pay_reduction_benefit')}
            </Select.Option>
            <Select.Option
              key="EmployeeAssociationBenefit"
              value="EmployeeAssociationBenefit"
              title={t('employee_batch.update.form.action.employee_association_benefit')}
            >
              {t('employee_batch.update.form.action.employee_association_benefit')}
            </Select.Option>
            {!departmentManager && getDepartments().size > 0 && (
              <Select.Option
                key="Department"
                value="Department"
                title={t('employee_batch.update.form.action.department')}
              >
                {t('employee_batch.update.form.action.department')}
              </Select.Option>
            )}
            <Select.Option key="Language" value="Language" title={t('employee_batch.update.form.action.language')}>
              {t('employee_batch.update.form.action.language')}
            </Select.Option>
            <Select.Option
              key="PreferredTaxCard"
              value="PreferredTaxCard"
              title={t('employee_batch.update.form.action.preferred_tax_card')}
            >
              {t('employee_batch.update.form.action.preferred_tax_card')}
            </Select.Option>
            <Select.Option
              key="Supplement"
              value="Supplement"
              title={t('employee_batch.update.form.action.supplement')}
            >
              {t('employee_batch.update.form.action.supplement')}
            </Select.Option>
            <Select.Option key="Pension" value="Pension" title={t('employee_batch.update.form.action.pension')}>
              {t('employee_batch.update.form.action.pension')}
            </Select.Option>
            <Select.Option key="Position" value="Position" title={t('employee_batch.update.form.action.position')}>
              {t('employee_batch.update.form.action.position')}
            </Select.Option>
            {props.hasAssetsFeature && (
              <Select.Option key="Asset" value="Asset" title={t('employee_batch.update.form.action.asset')}>
                {t('employee_batch.update.form.action.asset')}
              </Select.Option>
            )}
            <Select.Option
              key="ProductionUnit"
              value="ProductionUnit"
              title={t('employee_batch.update.form.action.production_unit')}
            >
              {t('employee_batch.update.form.action.production_unit')}
            </Select.Option>
            <Select.Option
              key="PersonalDays"
              value="PersonalDays"
              title={t('employee_batch.update.form.action.personal_days')}
            >
              {t('employee_batch.update.form.action.personal_days')}
            </Select.Option>
            <Select.Option
              key="OptionalDays"
              value="OptionalDays"
              title={t('employee_batch.update.form.action.optional_days')}
            >
              {t('employee_batch.update.form.action.optional_days')}
            </Select.Option>
            <Select.Option key="Salary" value="Salary" title={t('employee_batch.update.form.action.salary')}>
              {t('employee_batch.update.form.action.salary')}
            </Select.Option>
            {isUserSupport(props.user) && (
              <Select.Option
                key="SalaryCycle"
                value="SalaryCycle"
                title={t('employee_batch.update.form.action.salary_cycle')}
              >
                {t('employee_batch.update.form.action.salary_cycle')}
              </Select.Option>
            )}
            <Select.Option
              key="GreatPrayerDaySupplement"
              value="GreatPrayerDaySupplement"
              title={t('employee_batch.update.form.action.great_prayer_day_supplement')}
            >
              {t('employee_batch.update.form.action.great_prayer_day_supplement')}
            </Select.Option>
            <Select.Option
              key="OneTimePay"
              value="OneTimePay"
              title={t('employee_batch.update.form.action.one_time_pay')}
            >
              {t('employee_batch.update.form.action.one_time_pay')}
            </Select.Option>
            <Select.Option
              key="PayslipText"
              value="PayslipText"
              title={t('employee_batch.update.form.action.payslip_text')}
            >
              {t('employee_batch.update.form.action.payslip_text')}
            </Select.Option>
            <Select.Option
              key="ConvertEmployment"
              value="ConvertEmployment"
              title={t('employee_batch.update.form.action.convert_employment')}
            >
              {t('employee_batch.update.form.action.convert_employment')}
            </Select.Option>
            <Select.Option key="Terminate" value="Terminate" title={t('employee_batch.update.form.action.terminate')}>
              {t('employee_batch.update.form.action.terminate')}
            </Select.Option>
            <Select.Option key="Delete" value="Delete" title={t('employee_batch.update.form.action.delete')}>
              {t('employee_batch.update.form.action.delete')}
            </Select.Option>
          </Select>
        )}
        {(values.action === 'PaySlipTransport' ||
          values.action === 'TelephoneBenefit' ||
          values.action === 'LunchBenefit' ||
          values.action === 'HealthBenefit' ||
          values.action === 'Department' ||
          values.action === 'Supplement' ||
          values.action === 'Asset' ||
          values.action === 'GreatPrayerDaySupplement') && (
          <Subcard>
            {decorateField('operation', {
              title: t('employee_batch.update.form.operation'),
              valueOnChecked: true,
              noBlur: true,
            })(
              <Radio.Group style={{ marginTop: '20px' }}>
                <Radio value={true}>{t('employee_batch.update.form.operation.checked')}</Radio>
                <Radio value={false}>{t('employee_batch.update.form.operation.unchecked')}</Radio>
              </Radio.Group>
            )}
          </Subcard>
        )}
        {values.action === 'PaySlipTransport' && (
          <Subcard>
            {decorateField('paySlipTransportSetting', {
              title: t('employee_batch.update.form.pay_slip_transport_setting'),
              valueOnChecked: true,
              noBlur: true,
            })(
              <Radio.Group>
                <FeatureLock
                  featureType={'DK EBoks'}
                  description={t('employee_batch.update.form.pay_slip_transport_setting.mit_dk.lock')}
                >
                  <Radio value={PaySlipTransportSetting.MitDK}>
                    {t('employee_batch.update.form.pay_slip_transport_setting.mit_dk')}
                  </Radio>
                </FeatureLock>
                <FeatureLock
                  featureType={'DK EBoks'}
                  description={t('employee_batch.update.form.pay_slip_transport_setting.e_boks.lock')}
                >
                  <Radio value={PaySlipTransportSetting.EBoks}>
                    {t('employee_batch.update.form.pay_slip_transport_setting.e_boks')}
                  </Radio>
                </FeatureLock>
                <Radio value={PaySlipTransportSetting.EMail}>
                  {t('employee_batch.update.form.pay_slip_transport_setting.email')}
                </Radio>
                <FeatureLock
                  featureType={'SMS'}
                  description={t('employee_batch.update.form.pay_slip_transport_setting.sms.lock')}
                >
                  <Radio value={PaySlipTransportSetting.SMS}>
                    {t('employee_batch.update.form.pay_slip_transport_setting.sms')}
                  </Radio>
                </FeatureLock>
              </Radio.Group>
            )}
            {getFieldValue('operation') &&
              (getFieldValue('paySlipTransportSetting') === PaySlipTransportSetting.MitDK ||
                getFieldValue('paySlipTransportSetting') === PaySlipTransportSetting.EBoks) && (
                <p>{t('employee_batch.update.form.pay_slip_transport_setting.national_inbox_note')}</p>
              )}
          </Subcard>
        )}
        {(values.action === 'LunchBenefit' || values.action === 'HealthBenefit') && getFieldValue('operation') && (
          <Subcard>
            {decorateField('amount', {
              title:
                values.action === 'LunchBenefit'
                  ? t('employee_batch.update.form.amount.lunch_benefit')
                  : t('employee_batch.update.form.amount.health_benefit'),
              placeholder: t('employee_batch.update.form.amount.placeholder'),
              validate: (val) => (!val ? t('employee_batch.update.form.amount.required') : null),
              suffix:
                values.action === 'LunchBenefit'
                  ? getFieldValue('hasLunchDaily')
                    ? t('employee_batch.update.form.amount.suffix.lunch.daily')
                    : t('employee_batch.update.form.amount.suffix.lunch.standard')
                  : t('employee_batch.update.form.amount.suffix'),
            })(<Input />)}
          </Subcard>
        )}
        {values.action === 'LunchBenefit' && getFieldValue('operation') && (
          <Subcard>
            {decorateField('hasLunchDaily', {
              skipLabel: true,
            })(
              <Radio.Group>
                <Radio value={false}>{t('employee_batch.update.form.has_lunch_daily.false')}</Radio>
                <Radio value={true}>{t('employee_batch.update.form.has_lunch_daily.true')}</Radio>
              </Radio.Group>
            )}
          </Subcard>
        )}
        {(values.action === 'NetPayReductionBenefit' || values.action === 'EmployeeAssociationBenefit') && (
          <Subcard>
            {decorateField('amount', {
              title: t('employee_batch.update.form.amount.net_pay_reduction_benefit'),
              placeholder: t('employee_batch.update.form.amount.placeholder'),
              suffix: t('employee_batch.update.form.amount.suffix'),
              validate: (val) => {
                if (!val) {
                  return t('employee_batch.update.form.amount.required')
                }

                return null
              },
            })(<Input />)}
            {values.action === 'NetPayReductionBenefit' &&
              decorateField('title', {
                placeholder: t('employee_batch.update.form.title.net_pay_reduction_benefit'),
                validate: (val) => (!val ? t('employee_batch.update.form.title.required') : null),
              })(<Input />)}
          </Subcard>
        )}
        {values.action === 'Department' && getFieldValue('operation') && (
          <Subcard>
            {decorateField('departmentID', {
              title: t('employee_batch.update.form.department_id'),
              placeholder: t('employee_batch.update.form.department_id.placeholder'),
              validate: (val) => (!val ? t('employee_batch.update.form.department_id.required') : null),
            })(
              <Select dropdownMatchSelectWidth={false}>
                {getDepartments().map((department) => {
                  return (
                    <Select.Option key={department.id} value={department.id} title={department.name}>
                      {department.name}
                    </Select.Option>
                  )
                })}
              </Select>
            )}
          </Subcard>
        )}
        {values.action === 'Language' && (
          <Subcard>
            {decorateField('language', {
              placeholder: t('employee_batch.update.form.language'),
            })(
              <Radio.Group>
                <Radio value={Language.DANISH}>{formatLanguage(Language.DANISH)}</Radio>
                <Radio value={Language.ENGLISH}>{formatLanguage(Language.ENGLISH)}</Radio>
              </Radio.Group>
            )}
          </Subcard>
        )}
        {values.action === 'PreferredTaxCard' && (
          <Subcard>
            {decorateField('preferredTaxCard', {
              title: t('employee_batch.update.form.preferred_tax_card'),
              placeholder: t('employee_batch.update.form.preferred_tax_card.placeholder'),
            })(
              <Select dropdownMatchSelectWidth={false}>
                <Select.Option value={PreferredTaxCardType.MAIN_CARD}>
                  {formatPreferredTaxCardType(PreferredTaxCardType.MAIN_CARD)}
                </Select.Option>
                <Select.Option value={PreferredTaxCardType.SECONDARY_CARD}>
                  {formatPreferredTaxCardType(PreferredTaxCardType.SECONDARY_CARD)}
                </Select.Option>
              </Select>
            )}
          </Subcard>
        )}
        {values.action === 'Supplement' && (
          <Subcard>
            <Row>
              <Col span={20}>
                {decorateField('supplementTypeID', {
                  title: t('employee_batch.update.form.supplement_type_title'),
                  placeholder: t('employee_batch.update.form.supplement_type_title.placeholder'),
                })(
                  <Select dropdownMatchSelectWidth={false}>
                    {choiceTypes.map((choiceType) => (
                      <Select.Option key={choiceType.id} value={choiceType.id}>
                        {formatSupplementTypeFullName(choiceType.name, { skipAmount: true })}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Col>
            </Row>
            {!!getFieldValue('operation') && (
              <Row>
                <Col span={15}>
                  {decorateField('supplementCompensationRate', {
                    placeholder: t('employee_batch.update.form.supplement_compensation_rate'),
                    suffix: getFieldValue('supplementPercentage')
                      ? t('employee_batch.update.form.supplement_compensation_rate.suffix.percentage')
                      : t('employee_batch.update.form.supplement_compensation_rate.suffix.amount'),
                    validate: (val) => {
                      if (!val) {
                        return getFieldValue('supplementPercentage')
                          ? t('employee_batch.update.form.supplement_compensation_rate.required.percentage')
                          : t('employee_batch.update.form.supplement_compensation_rate.required.amount')
                      }
                      if (getFieldValue('supplementPercentage') && parseInputNumber(val) > 50) {
                        return t('employee_batch.update.form.supplement_compensation_rate.percentage_invalid')
                      }
                      return null
                    },
                  })(<Input />)}
                </Col>
                <Col span={5}>
                  {decorateField(
                    'supplementPercentage',
                    {}
                  )(
                    <Radio.Group style={{ marginTop: '20px' }}>
                      <Radio value={true}>{t('employee_batch.update.form.supplement_percentage.true')}</Radio>
                      <Radio value={false}>{t('employee_batch.update.form.supplement_percentage.false')}</Radio>
                    </Radio.Group>
                  )}
                </Col>
              </Row>
            )}
          </Subcard>
        )}
        {values.action === 'Pension' && (
          <Subcard>
            <Row>
              <Col span={8}>
                {decorateField('pensionOperation', {
                  title: t('employee_batch.update.form.pension_operation'),
                  valueOnChecked: true,
                  noBlur: true,
                })(
                  <Radio.Group style={{ marginTop: '20px' }}>
                    <Radio value={true}>{t('employee_batch.update.form.pension_operation.checked')}</Radio>
                    <Radio value={false}>{t('employee_batch.update.form.pension_operation.unchecked')}</Radio>
                  </Radio.Group>
                )}
              </Col>
            </Row>
            {getFieldValue('pensionOperation') && (
              <Row>
                <Col span={8}>
                  {decorateField(`pensionCompanyID`, {
                    placeholder: t('employee_batch.update.form.pension_company_id'),
                    validate: (val) => (!val ? t('employee_batch.update.form.pension_company_id.required') : null),
                    trigger: 'onChange',
                    noBlur: true,
                  })(
                    <Select
                      dropdownMatchSelectWidth={false}
                      showSearch={true}
                      filterOption={(inputValue: string, option: ReactElement) => {
                        return combineSearchOption(option).toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                      }}
                      onChange={(value) => {
                        let hasUnionAgreementNumber = true
                        let usesNationalID = false
                        props.pensionCompanies.forEach((company) => {
                          if (company.id === value) {
                            hasUnionAgreementNumber =
                              company.fields && company.fields.indexOf('UnionAgreementNumber') !== -1
                            usesNationalID = company.customerIDType === 'NationalID'
                          }
                        })
                        props.setFieldValue('pensionHasUnionAgreementNumber', hasUnionAgreementNumber)
                        props.setFieldValue('pensionUsesNationalID', usesNationalID)
                      }}
                    >
                      {props.pensionCompanies
                        .filter((company) => company.active && company.customerIDType !== 'AccountID')
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((company) => {
                          return (
                            <Select.Option key={company.id} value={company.id}>
                              {company.name} ({company.paymentID})
                            </Select.Option>
                          )
                        })}
                    </Select>
                  )}
                </Col>
                {!getFieldValue('pensionUsesNationalID') && (
                  <Col span={6}>
                    {decorateField('pensionCustomerID', {
                      placeholder: t('employee_batch.update.form.pension_customer_id'),
                      validate: (val) => {
                        if (!getFieldValue('pensionUsesNationalID')) {
                          return null
                        }
                        if (!val) {
                          return t('employee_batch.update.form.pension_customer_id.required')
                        }
                        if (val.length > 15) {
                          return t('employee_batch.update.form.pension_customer_id.max_15_characters')
                        }
                        return null
                      },
                    })(<Input />)}
                  </Col>
                )}
                {getFieldValue('pensionHasUnionAgreementNumber') && (
                  <Col span={6}>
                    <Tooltip title={t('employee_batch.update.form.pension_union_agreement_number.tooltip')}>
                      {decorateField('pensionUnionAgreementNumber', {
                        placeholder: t('employee_batch.update.form.pension_union_agreement_number'),
                        validate: (val) => {
                          if (!val) {
                            return null
                          }
                          if (val.length !== 5) {
                            return t('employee_batch.update.form.pension_union_agreement_number.5_characters')
                          }
                          return null
                        },
                      })(<Input />)}
                    </Tooltip>
                  </Col>
                )}
              </Row>
            )}
            {!!getFieldValue('pensionOperation') && (
              <Row>
                <Col span={20}>
                  <p>{t('employee_batch.update.form.pension_operation.note')}</p>
                </Col>
              </Row>
            )}
            {!!getFieldValue('pensionOperation') && (
              <Row>
                <Col span={15}>
                  {decorateField('pensionEmployeeAmount', {
                    placeholder: t('employee_batch.update.form.pension_employee_amount'),
                    suffix: getFieldValue('pensionEmployeePercentage')
                      ? t('employee_batch.update.form.pension_employee_amount.suffix.percentage')
                      : t('employee_batch.update.form.pension_employee_amount.suffix.amount'),
                    validate: (val) => {
                      if (!val) {
                        return getFieldValue('pensionEmployeePercentage')
                          ? t('employee_batch.update.form.pension_employee_amount.required.percentage')
                          : t('employee_batch.update.form.pension_employee_amount.required.amount')
                      }
                      if (getFieldValue('pensionEmployeePercentage') && parseInputNumber(val) > 50) {
                        return t('employee_batch.update.form.pension_employee_amount.percentage_invalid')
                      }
                      return null
                    },
                  })(<Input />)}
                </Col>
                <Col span={5}>
                  {decorateField(
                    'pensionEmployeePercentage',
                    {}
                  )(
                    <Radio.Group style={{ marginTop: '20px' }}>
                      <Radio value={true}>{t('employee_batch.update.form.pension_employee_percentage.true')}</Radio>
                      <Radio value={false}>{t('employee_batch.update.form.pension_employee_percentage.false')}</Radio>
                    </Radio.Group>
                  )}
                </Col>
              </Row>
            )}
            {!!getFieldValue('pensionOperation') && (
              <Row>
                <Col span={15}>
                  {decorateField('pensionEmployerAmount', {
                    placeholder: t('employee_batch.update.form.pension_employer_amount'),
                    suffix: getFieldValue('pensionEmployerPercentage')
                      ? t('employee_batch.update.form.pension_employer_amount.suffix.percentage')
                      : t('employee_batch.update.form.pension_employer_amount.suffix.amount'),
                    validate: (val) => {
                      if (!val) {
                        return getFieldValue('pensionEmployerPercentage')
                          ? t('employee_batch.update.form.pension_employer_amount.required.percentage')
                          : t('employee_batch.update.form.pension_employer_amount.required.amount')
                      }
                      if (getFieldValue('pensionEmployerPercentage') && parseInputNumber(val) > 50) {
                        return t('employee_batch.update.form.pension_employer_amount.percentage_invalid')
                      }
                      return null
                    },
                  })(<Input />)}
                </Col>
                <Col span={5}>
                  {decorateField(
                    'pensionEmployerPercentage',
                    {}
                  )(
                    <Radio.Group style={{ marginTop: '20px' }}>
                      <Radio value={true}>{t('employee_batch.update.form.pension_employer_percentage.true')}</Radio>
                      <Radio value={false}>{t('employee_batch.update.form.pension_employer_percentage.false')}</Radio>
                    </Radio.Group>
                  )}
                </Col>
              </Row>
            )}
          </Subcard>
        )}
        {values.action === 'Position' && (
          <Subcard>
            <ContractPosition<Fields>
              decorateField={props.decorateField}
              setFieldValue={props.setFieldValue}
              getFieldValue={props.getFieldValue}
              employmentPositions={props.employmentPositions}
            />
          </Subcard>
        )}
        {values.action === 'Asset' && (
          <Subcard>
            {decorateField('assetCategoryID', {
              placeholder: t('employee_batch.update.form.asset_category_id'),
              validate: (val) => (!val ? t('employee_batch.update.form.asset_category_id.required') : null),
            })(
              <Select dropdownMatchSelectWidth={false}>
                {props.assetCategories.map((category) => (
                  <Select.Option key={category.id} value={category.id}>
                    {category.title}
                  </Select.Option>
                ))}
              </Select>
            )}
            {!!getFieldValue('operation') && (
              <div>
                {decorateField('title', {
                  placeholder: t('employee_batch.update.form.title.asset'),
                  validate: (val) => (!val ? t('employee_batch.update.form.title.required') : null),
                })(<Input />)}
                {decorateField('description', {
                  placeholder: t('employee_batch.update.form.description.asset'),
                  validate: (val) => (!val ? t('employee_batch.update.form.description.required') : null),
                })(<Input />)}
              </div>
            )}
            {!getFieldValue('operation') && (
              <div>
                <p>{t('employee_batch.update.form.assets_to_return.intro')}</p>
                {relevantAssetTitles.length === 0 && (
                  <p>
                    <i>{t('employee_batch.update.form.assets_to_return.none')}</i>
                  </p>
                )}
                {relevantAssetTitles.map((asset) => {
                  return decorateAnyField('assetsToReturn.idx-' + asset.id, { valueOnChecked: true })(
                    <Checkbox id={'assetsToReturn.idx-' + asset.id} key={'assetsToReturn.' + asset.id}>
                      {asset.title}
                    </Checkbox>
                  )
                })}
              </div>
            )}
          </Subcard>
        )}
        {values.action === 'SalaryCycle' && (
          <Subcard>
            {decorateField('salaryCycleID', {
              title: t('employee_batch.update.form.salary_cycle_id'),
              placeholder: t('employee_batch.update.form.salary_cycle_id.placeholder'),
              validate: (val) => (!val ? t('employee_batch.update.form.salary_cycle_id.required') : null),
            })(
              <Select dropdownMatchSelectWidth={false}>
                {getAvailableCycles(props.company, props.salaryCycles.toArray(), false).map((salaryCycle) => {
                  return (
                    <Select.Option key={salaryCycle.id} value={salaryCycle.id}>
                      {formatSalaryCycle(salaryCycle.frequency, salaryCycle.offset)}
                      {salaryCycle.frequency === 'BiWeekly' && ` (${formatBiweeklySalaryCycle(salaryCycle, false)})`}
                    </Select.Option>
                  )
                })}
              </Select>
            )}
          </Subcard>
        )}
        {values.action === 'ProductionUnit' && (
          <Subcard>
            {decorateField('productionUnitID', {
              placeholder: t('employee_batch.update.form.production_unit_id'),
              validate: (val) => (!val ? t('employee_batch.update.form.production_unit_id.required') : null),
            })(
              <Select dropdownMatchSelectWidth={false}>
                {props.company.productionUnits.map((productionUnit) => {
                  return (
                    <Select.Option key={productionUnit.id} value={productionUnit.id} title={productionUnit.name}>
                      {productionUnit.name}
                    </Select.Option>
                  )
                })}
              </Select>
            )}
          </Subcard>
        )}
        {values.action === 'PersonalDays' && (
          <Subcard>
            <Row>
              {decorateField('amount', {
                placeholder: t('employee_batch.update.form.amount.personal_days'),
                validate: (val) => {
                  if (!val) {
                    return t('employee_batch.update.form.amount.required.personal_days')
                  }

                  return null
                },
              })(<Input />)}
            </Row>
            <Row>
              {decorateField('accrual', {
                placeholder: t('employee_batch.update.form.accrual'),
              })(
                <Radio.Group style={{ marginTop: '20px' }}>
                  <Radio value={true}>{t('employee_batch.update.form.accrual.true')}</Radio>
                  <Radio value={false}>{t('employee_batch.update.form.accrual.false')}</Radio>
                </Radio.Group>
              )}
            </Row>
          </Subcard>
        )}
        {values.action === 'OptionalDays' && (
          <Subcard>
            <Row>
              {decorateField('amount', {
                placeholder: t('employee_batch.update.form.amount.optional_days'),
                validate: (val) => {
                  if (!val) {
                    return t('employee_batch.update.form.amount.required.optional_days')
                  }

                  return null
                },
              })(<Input />)}
            </Row>
            <Row>
              {decorateField('accrual', {
                placeholder: t('employee_batch.update.form.accrual'),
              })(
                <Radio.Group style={{ marginTop: '20px' }}>
                  <Radio value={true}>{t('employee_batch.update.form.accrual.true')}</Radio>
                  <Radio value={false}>{t('employee_batch.update.form.accrual.false')}</Radio>
                </Radio.Group>
              )}
            </Row>
          </Subcard>
        )}
        {values.action === 'Salary' && (
          <Subcard>
            <Row>
              {decorateField('salaryClass', {
                title: t('employee_batch.update.form.salary_class'),
                validate: (val) => (!val ? t('employee_batch.update.form.salary_class.required') : null),
              })(
                <Radio.Group style={{ marginTop: '20px' }}>
                  <Radio value={'Hourly'}>{t('employee_batch.update.form.salary_class.hourly')}</Radio>
                  <Radio value={'Fixed'}>{t('employee_batch.update.form.salary_class.fixed')}</Radio>
                  <Radio value={'Supplement'}>{t('employee_batch.update.form.salary_class.supplement')}</Radio>
                  <Radio value={'SupplementVaried'}>
                    {t('employee_batch.update.form.salary_class.supplement_varied')}
                  </Radio>
                </Radio.Group>
              )}
            </Row>
            {values.salaryClass && (
              <>
                {values.salaryClass !== 'Fixed' && (
                  <Row>
                    {decorateField('salaryTypeID', {
                      placeholder: t([
                        'employee_batch',
                        'update',
                        'form',
                        'salary_type_id',
                        values.salaryClassSC ?? 'hourly',
                      ]),
                      validate: (val) =>
                        !val
                          ? t([
                              'employee_batch',
                              'update',
                              'form',
                              'salary_type_id',
                              'required',
                              values.salaryClassSC ?? 'hourly',
                            ])
                          : null,
                    })(
                      <Select dropdownMatchSelectWidth={false}>
                        {props.salaryTypes
                          .filter((value) => value.class == values.salaryClass)
                          .map((salaryType) => (
                            <Select.Option key={salaryType.id} value={salaryType.id}>
                              {salaryType.title}
                            </Select.Option>
                          ))}
                      </Select>
                    )}
                  </Row>
                )}
                <Row>
                  {decorateField('title', {
                    placeholder: t([
                      'employee_batch',
                      'update',
                      'form',
                      'title',
                      'salary',
                      values.salaryClassSC ?? 'hourly',
                    ]),
                    validate: (val) => {
                      if (values.salaryClass !== 'Supplement') {
                        return null
                      }
                      if (!val) {
                        return t([
                          'employee_batch',
                          'update',
                          'form',
                          'title',
                          'salary',
                          'required',
                          values.salaryClassSC ?? 'supplement',
                        ])
                      }
                      return null
                    },
                  })(<Input />)}
                </Row>
                <Row>
                  {decorateField('amount', {
                    placeholder: t([
                      'employee_batch',
                      'update',
                      'form',
                      'amount',
                      'salary',
                      values.salaryClassSC ?? 'hourly',
                    ]),
                    suffix: t('employee_batch.update.form.amount.suffix'),
                    validate: (val) => {
                      if (!val) {
                        return t([
                          'employee_batch',
                          'update',
                          'form',
                          'amount',
                          'salary',
                          'required',
                          values.salaryClassSC ?? 'hourly',
                        ])
                      }
                      return null
                    },
                  })(<Input />)}
                  {values.salaryClass === 'Supplement' &&
                    decorateField('quantity', {
                      placeholder: t('employee_batch.update.form.quantity'),
                      validate: (val) => {
                        if (!val) {
                          return t('employee_batch.update.form.quantity.required')
                        }
                        return null
                      },
                    })(<Input />)}
                </Row>
              </>
            )}
          </Subcard>
        )}
        {values.action === 'OneTimePay' && (
          <Subcard>
            <Row>
              {decorateField('title', {
                placeholder: t('employee_batch.update.form.title.one_time_pay'),
                validate: (val) => (!val ? t('employee_batch.update.form.title.required') : null),
              })(<Input />)}
            </Row>
            <Row>
              {decorateField('amount', {
                placeholder: t('employee_batch.update.form.amount.bonus'),
                suffix: t(t('employee_batch.update.form.amount.suffix')),
                validate: (val) => {
                  if (!val) {
                    return t(t('employee_batch.update.form.amount.required'))
                  }

                  return null
                },
              })(<Input />)}
            </Row>
            <Row>
              {decorateField('oneTimePayType', {
                title: t('employee_batch.update.form.one_time_pay_type'),
                validate: (val) => {
                  if (!val) {
                    return t('employee_batch.update.form.one_time_pay_type.required')
                  }

                  return null
                },
              })(
                <Select dropdownMatchSelectWidth={false}>
                  <Select.Option key="Bonus" value="Bonus">
                    {formatOneTimePayType('Bonus')}
                  </Select.Option>
                  <Select.Option key="G-Dage" value="G-Dage">
                    {formatOneTimePayType('G-Dage')}
                  </Select.Option>
                  {moreOneTimePayOptions && (
                    <Select.Option key="Benefit In Kind" value="Benefit In Kind">
                      {formatOneTimePayType('Benefit In Kind')}
                    </Select.Option>
                  )}
                </Select>
              )}
            </Row>
          </Subcard>
        )}
        {values.action === 'PayslipText' && (
          <Subcard>
            <Row>
              {decorateField('title', {
                placeholder: t('employee_batch.update.form.title.payslip_text'),
                validate: (val) => (!val ? t('employee_batch.update.form.title.required.payslip_text') : null),
              })(<Input />)}
            </Row>
          </Subcard>
        )}
        {/* ----- add new subcards here ----- */}
        {((usesFutureContract &&
          (values.action === 'TelephoneBenefit' ||
            values.action === 'LunchBenefit' ||
            values.action === 'LunchDailyBenefit' ||
            values.action === 'HealthBenefit' ||
            values.action === 'Supplement' ||
            values.action === 'Pension' ||
            values.action === 'Position' ||
            values.action === 'PersonalDays' ||
            values.action === 'OptionalDays')) ||
          values.action === 'Asset') && (
          <Subcard>
            {decorateField('validFrom', {
              title: t('employee_batch.update.form.valid_from'),
            })(
              <Select dropdownMatchSelectWidth={false}>
                <Select.Option key={'Now'} value={'Now'}>
                  {t('employee_batch.update.form.valid_from.now')}
                </Select.Option>
                {validFromDates.map((date) => {
                  return (
                    <Select.Option key={date} value={date}>
                      {formatDate(date)}
                    </Select.Option>
                  )
                })}
              </Select>
            )}
          </Subcard>
        )}
        {values.action === 'Terminate' && (
          <Subcard>
            {decorateField('validFromPicker', {
              title: t('employee_batch.update.form.valid_from_picker.terminate'),
              validate: (val) => {
                if (!val) {
                  return t('employee_batch.update.form.valid_from_picker.required.terminate')
                }
                return null
              },
            })(<DatePicker allowClear={false} style={{ width: '100%' }} />)}
          </Subcard>
        )}
        {(values.action === 'Terminate' || values.action === 'Delete') && (
          <Subcard>
            {values.action === 'Terminate' && (
              <div className="ant-form-warning">
                {t('employee_batch.update.form.terminate_note.line_1', { count: selectedEmployees.length })}
                <br />
                {t('employee_batch.update.form.terminate_note.line_2')}
              </div>
            )}
            {values.action === 'Delete' && (
              <div className="ant-form-warning">
                {t('employee_batch.update.form.delete_note.line_1', { count: selectedEmployees.length })}
                <br />
                {tx('employee_batch.update.form.delete_note.line_2', {
                  not: <em>{t('employee_batch.update.form.delete_note.line_2.not')}</em>,
                })}
                <br />
                {t('employee_batch.update.form.delete_note.line_3')}
              </div>
            )}
          </Subcard>
        )}
        {/* ---- do not add more subcards here, add them above the validFrom subcard ---- */}
      </Card>
      <Card style={{ maxHeight: '600px', overflowY: 'scroll' }}>
        <Subtitle>
          {t(
            props.saved
              ? 'employee_batch.update.form.employee_list.note.after'
              : 'employee_batch.update.form.employee_list.note.before',
            { count: selectedEmployees.length }
          )}
        </Subtitle>
        {selectedEmployees.map((employee) => {
          let state: EmployeeBatchStatus = 'None'
          if (props.saved) {
            state = employee.state
          }
          return (
            <Row
              className={
                'employee-batch-employee employees employee-import-result' +
                (state === 'Success' ? ' success' : '') +
                (state === 'NoChange' ? ' no-change' : '') +
                (state === 'Failure' ? ' failure' : '')
              }
              key={employee.id}
            >
              <Headline>
                <UserImage src={employee.profileImageURL} name={employee.name} />
                <Link
                  to={
                    '/' +
                    (employee.affiliationType === 'Freelancer' ? paths.FREELANCERS : paths.EMPLOYEES) +
                    '/' +
                    employee.id
                  }
                >
                  {employee.name}
                </Link>
                {state !== 'None' ? (
                  <div className={'message'}>
                    {formatEmployeeBatchMessage(state, employee.message, employee.details)}
                  </div>
                ) : (
                  <small>{employee.position ? employee.position : '-'}</small>
                )}
              </Headline>
            </Row>
          )
        })}
      </Card>
      <Row>
        <Col span={12}>
          <Button
            htmlType="submit"
            size="extra-extra-large"
            type="primary"
            className="gtm-employee-batch-do-action"
            tabIndex={5}
            disabled={!canSubmit()}
          >
            {t('employee_batch.update.form.submit')}
          </Button>
          <Button size="extra-extra-large" onClick={props.goBack} className="gtm-employee-batch-back">
            {t('employee_batch.update.form.back')}
          </Button>
        </Col>
        <Col span={12} style={{ textAlign: 'right', marginTop: '20px' }}>
          <Link to={'/' + paths.EMPLOYEES}>
            <Button size="extra-extra-large" className="gtm-employee-batch-finish" danger>
              {t('employee_batch.update.form.finish')}
            </Button>
          </Link>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, Fields, BatchResult>({
  mapPropsToFields: (props) => ({
    operation: true,
    hasLunchDaily: false,
    pensionOperation: true,
    supplementPercentage: true,
    pensionEmployeePercentage: true,
    pensionEmployerPercentage: true,
    pensionHasUnionAgreementNumber: true,
    pensionUsesNationalID: false,
    assetsToReturn: assetTitleList(props.assets).reduce((obj: Record<string, boolean>, asset) => {
      obj['idx-' + asset.id] = false
      return obj
    }, {}),
    validFrom: 'Now',
    accrual: true,
    dispositionDate: 'Now',
  }),
  onChange: (key, val, allValues, options) => {
    const values = {}
    switch (key) {
      case 'employmentPositionID':
        // only set when array
        if (Array.isArray(val)) {
          setByPath(values, key, val)
        }
        break
      case 'supplementCompensationRate':
      case 'pensionEmployeeAmount':
      case 'pensionEmployerAmount':
      case 'amount':
        setByPath(
          values,
          key,
          formatInputNumber(parseInputNumber(val as string, { trim: options.trigger === 'onBlur' }))
        )
        break
      case 'salaryClass':
        setByPath(values, key, val)
        setByPath(values, 'salaryTypeID', undefined)
        break
      default:
        setByPath(values, key, val)
        break
    }
    return values
  },
  onSubmit: (values, props) => {
    const result: BatchResult = {
      action: values.action,
      operation: values.operation ? 'Add' : 'Remove',
    }
    switch (result.action) {
      case 'PaySlipTransport':
        result.paySlipTransportSetting = values.paySlipTransportSetting
        break
      //@ts-expect-error fallthrough
      case 'LunchBenefit':
        if (values.hasLunchDaily) {
          result.action = 'LunchDailyBenefit' // overwrite to lunch daily
        }
      //fallthrough
      case 'HealthBenefit':
      case 'EmployeeAssociationBenefit':
        result.amount = forceParseInputNumber(values.amount)
        break
      case 'NetPayReductionBenefit':
        result.amount = forceParseInputNumber(values.amount)
        result.title = values.title
        break
      case 'Department':
        result.departmentID = values.departmentID
        break
      case 'ProductionUnit':
        result.productionUnitID = values.productionUnitID
        break
      case 'PersonalDays':
        result.amount = forceParseInputNumber(values.amount)
        result.accrual = values.accrual
        break
      case 'OptionalDays':
        result.amount = forceParseInputNumber(values.amount)
        result.accrual = values.accrual
        break
      case 'Salary':
        result.title = values.title
        result.amount = forceParseInputNumber(values.amount)
        result.quantity = values.salaryClass === 'Supplement' ? forceParseInputNumber(values.quantity) : undefined
        if (values.salaryClass === 'Fixed') {
          // the others the user are required to pick themselves, but this one we handle for them;
          // it should be unlikely it won't find it, but if it doesn't the backend will just whine
          result.salaryTypeID = props.salaryTypes.find((st) => st.class === 'Fixed')?.id
        } else {
          result.salaryTypeID = values.salaryTypeID
        }
        result.operation = 'Add'
        break
      case 'OneTimePay':
        result.amount = forceParseInputNumber(values.amount)
        result.title = values.title
        result.oneTimePayType = values.oneTimePayType
        break
      case 'PayslipText':
        result.title = values.title
        result.amount = 0
        result.oneTimePayType = 'Free Text'
        result.action = 'OneTimePay'
        break
      case 'Language':
        result.language = values.language
        break
      case 'PreferredTaxCard':
        result.preferredTaxCard = values.preferredTaxCard
        break
      case 'Supplement':
        {
          let supplementType = props.supplementTypes.find((row) => row.id === values.supplementTypeID)
          if (supplementType && values.supplementPercentage) {
            supplementType = props.supplementTypes.find((row) =>
              isPercentageChoiceVariant(row.name, supplementType?.name)
            )
          }
          if (supplementType) {
            result.supplementTypeID = supplementType.id
            result.supplementCompensationRate = forceParseInputNumber(values.supplementCompensationRate)
            if (values.supplementPercentage) {
              result.supplementCompensationRate = result.supplementCompensationRate / 100.0
            }
          } else {
            result.action = undefined
          }
        }
        break
      case 'Pension':
        if (values.pensionOperation) {
          if (values.pensionEmployeeAmount) {
            if (values.pensionEmployeePercentage) {
              result.pensionEmployeePercentage = forceParseInputNumber(values.pensionEmployeeAmount)
            } else {
              result.pensionEmployeeFixedAmount = forceParseInputNumber(values.pensionEmployeeAmount)
            }
          }
          if (values.pensionEmployerAmount) {
            if (values.pensionEmployerPercentage) {
              result.pensionEmployerPercentage = forceParseInputNumber(values.pensionEmployerAmount)
            } else {
              result.pensionEmployerFixedAmount = forceParseInputNumber(values.pensionEmployerAmount)
            }
          }
          result.pensionCompanyID = values.pensionCompanyID
          result.pensionCustomerID = values.pensionCustomerID ? values.pensionCustomerID : undefined
          result.pensionUnionAgreementNumber = values.pensionUnionAgreementNumber
            ? values.pensionUnionAgreementNumber
            : undefined
        } else {
          // else, they must be explicitly set to 0 to remove them
          result.pensionEmployeePercentage = 0
          result.pensionEmployerPercentage = 0
        }
        break
      case 'Position': {
        const employmentPositionID = values.employmentPositionID
        if (employmentPositionID && employmentPositionID.length === 2) {
          result.employmentPositionID = employmentPositionID[1]
        }
        result.position = values.position
        break
      }
      case 'Asset':
        result.assetCategoryID = values.assetCategoryID
        if (result.operation === 'Add') {
          result.title = values.title
          result.description = values.description
        } else {
          const assetTitles = assetTitleList(props.assets)
          const assetsToReturn: string[] = []
          assetTitles.forEach((title) => {
            if (values.assetsToReturn['idx-' + title.id]) {
              assetsToReturn.push(title.title)
            }
          })
          result.assetsToReturn = assetsToReturn
        }
        break
      case 'SalaryCycle':
        result.salaryCycleID = values.salaryCycleID
        break
      case 'ConvertEmployment':
        result.affiliationType = 'Standard'
        break
    }
    if (
      result.action === 'TelephoneBenefit' ||
      result.action === 'LunchBenefit' ||
      result.action === 'LunchDailyBenefit' ||
      result.action === 'HealthBenefit' ||
      result.action === 'Supplement' ||
      result.action === 'Pension' ||
      result.action === 'Position' ||
      result.action === 'OptionalDays' ||
      result.action === 'PersonalDays' ||
      result.action === 'Asset' ||
      result.action === 'Terminate'
    ) {
      if (values.validFrom !== 'Now') {
        result.validFrom = formatAPIDate(values.validFrom)
      }
    }
    if (result.action === 'Terminate') {
      if (values.validFromPicker) {
        result.validFrom = formatAPIDate(values.validFromPicker)
      }
    }
    return result
  },
})(BatchUpdateForm)
