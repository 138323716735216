import { addMonths, startOfMonth } from 'date-fns'
import React, { ReactElement } from 'react'

import Company from '../../model/company'
import { DateFormat } from '../../model/types'
import { formatAPIDate, formatDate, getDate } from '../../utils/date-utils'
import { FormComponentProps, withValidations } from '../../utils/form-utils'
import { capitalise } from '../../utils/string-utils'
import { t } from '../../utils/translation-utils'
import Button from '../elements/button'
import Col from '../elements/grid/col'
import Row from '../elements/grid/row'
import Input from '../elements/input'
import Select from '../elements/select'

type Props = {
  company: Company
}

type Fields = {
  payRollSystem?: string
  payRollSystemText?: string
  dateRequest: DateFormat
  fieldA?: string // actually password, but using a generic name to prevent Chrome from autofilling it
  fieldB?: string // same here
}

export type RequestImportResult = {
  payRollSystem: string
  payRollSystemText?: string
  dateRequest: DateFormat
  username?: string
  password?: string
}

function RequestImportModalForm(props: Props & FormComponentProps<Fields, RequestImportResult>): ReactElement | null {
  const payRollSystems = ['Danløn', 'Dataløn', 'Intect (Gratisal)', 'Letløn', 'Pento', 'Proløn', 'Zenegy', 'other']
  const { decorateField, getFieldValue } = props

  const start = startOfMonth(getDate())
  const months = [...Array(6)].map((value, i) => startOfMonth(addMonths(start, i)))

  return (
    <div>
      {props.getFormError()}
      <Row>
        <Col span={24}>
          {decorateField('payRollSystem', {
            placeholder: t('request_import.form.pay_roll_system'),
            validate: (val) => {
              if (!val) {
                return t('request_import.form.pay_roll_system.required')
              }
              return null
            },
          })(
            <Select>
              {payRollSystems.map((payRollSystem) => (
                <Select.Option key={payRollSystem} value={payRollSystem}>
                  {payRollSystem === 'other' ? t('request_import.form.pay_roll_system.other') : payRollSystem}
                </Select.Option>
              ))}
            </Select>
          )}
          {getFieldValue('payRollSystem') === 'other' &&
            decorateField('payRollSystemText', {
              placeholder: t('request_import.form.pay_roll_system_text'),
              validate: (val) => {
                if (!val) {
                  return t('request_import.form.pay_roll_system_text.required')
                }
                return null
              },
            })(<Input />)}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {decorateField('dateRequest', { placeholder: t('request_import.form.date_request') })(
            <Select>
              {months.map((date) => (
                <Select.Option
                  key={formatAPIDate(date)}
                  value={formatAPIDate(date)}
                  title={formatDate(date, t('date.month_of_year'))}
                >
                  {capitalise(formatDate(date, t('date.month_of_year')))}
                </Select.Option>
              ))}
            </Select>
          )}
          <span className="ant-form-extra">{t('request_import.form.date_request.note')}</span>
        </Col>
      </Row>
      {getFieldValue('payRollSystem') === 'Danløn' && (
        <form autoComplete={'false'}>
          <input type="text" name="username" style={{ position: 'absolute', opacity: 0, zIndex: -9000 }} />
          <input type="password" name="password" style={{ position: 'absolute', opacity: 0, zIndex: -9000 }} />
          <Row>
            <Col span={12}>
              {decorateField('fieldA', {
                placeholder: t('request_import.form.username'),
                validate: (val) => {
                  if (!val) {
                    return t('request_import.form.username.required')
                  }
                  return null
                },
              })(<Input autoComplete={'false'} data-lpignore={true} />)}
            </Col>
            <Col span={12}>
              {decorateField('fieldB', {
                placeholder: t('request_import.form.password'),
                validate: (val) => {
                  if (!val) {
                    return t('request_import.form.password.required')
                  }
                  return null
                },
              })(<Input type="password" autoComplete={'false'} data-lpignore={true} />)}
            </Col>
          </Row>
        </form>
      )}
      <Row>
        <Col span={24}>
          <Button htmlType="submit" size="extra-extra-large" type="primary">
            {t('request_import.form.submit')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default withValidations<Props, Fields, RequestImportResult>({
  mapPropsToFields: () => ({
    dateRequest: formatAPIDate(startOfMonth(getDate())),
  }),
  onSubmit: (values) => ({
    ...values,
    username: values.fieldA,
    password: values.fieldB,
    payRollSystem: values.payRollSystem!,
    dateRequest: values.dateRequest,
  }),
})(RequestImportModalForm)
